import styled from 'styled-components';

export const InfoWrapper = styled.div`
  padding: 25px 30px;
  color: ${({ theme }) => theme.filters.text};
`;

export const Name = styled.h3`
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: ${({ theme }) => theme.filters.text};
  margin: 0 0 25px;
`;

export const Row = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

export const Caption = styled.div`
  min-width: 55%;
  width: 55%;
  margin-right: 10px;
  font-size: 16px;
  line-height: 19px;
`;

export const Value = styled.div`
  font-size: 16px;
  line-height: 19px;
  margin-bottom: 0;
  margin-top: auto;
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 32px;

  & a,
  button {
    margin-bottom: 12px;
  }
`;

export const NoDetails = styled.div`
  font-size: 16px;
  line-height: 19px;
`;
