import { ReportsState, ReportsActionTypes, ReportsActions } from './types';

const initialState: ReportsState = {
  filters: null,
};

const reportsReducer = (state = initialState, action: ReportsActions): ReportsState => {
  switch (action.type) {
    case ReportsActionTypes.setFilters: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default reportsReducer;
