import { ExclamationCircleOutlined } from '@ant-design/icons';
import styled from 'styled-components';

export type PanelProps = {
  background: string;
  selectedPanel?: boolean;
};

export type ColProps = {
  width?: string;
  alignSelf?: string;
};

export const Panel = styled.div<PanelProps>`
  border: ${({ selectedPanel }) => (selectedPanel ? 'solid 3px #007bff' : 'solid 2px rgba(37, 113, 140, 0.3)')};
  display: inline-block;
  border-radius: 5px;
  box-shadow: 0 0 10px rgb(0 0 0 / 50%);
  transition: all 0.3s;
  margin-right: 10px;
  opacity: 0.9;
  margin-bottom: 10px;
  width: 240px;
  font-size: 16px;
  background: ${({ background }) => background};
`;

export const Content = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  transition: all 0.2s;
  color: #fff;
  padding: 10px;
  height: 100%;
`;

export const Name = styled.div`
  font-size: 14px;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
`;

export const RowWithTooltip = styled.span`
  display: flex;
  align-items: center;

  & span {
    display: flex;
    align-items: center;
  }
`;

export const Col = styled.div<ColProps>`
  display: flex;
  flex-direction: column;
  width: ${({ width }) => width};
`;

export const ColWithTooltip = styled.span<ColProps>`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: ${({ width }) => width};
  align-self: ${({ alignSelf }) => alignSelf};
`;

export const Caption = styled.div`
  margin-right: 3px;
  color: rgb(198, 201, 193);
  font-size: 13px;
  line-height: 19px;
  white-space: nowrap;
`;

export const Value = styled.div`
  font-size: 13px;
  line-height: 19px;
  color: #fff;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ChartNPT = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 70%;
`;

export const ChartConstruction = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const ErrorIcon = styled(ExclamationCircleOutlined)`
  color: red;
  margin-right: 10px;
`;
