import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const InputWrapper = styled.div`
  input {
    color: ${({ theme }) => theme.input.color};
    background: ${({ theme }) => theme.input.background};
    border: ${staticColors.borderField};

    &:disabled {
      color: ${({ theme }) => theme.input.color};
      background: ${({ theme }) => theme.input.background};
    }
  }
`;

export const SelectWrapper = styled.div`
  .ant-select {
    color: ${({ theme }) => theme.input.color};

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: ${({ theme }) => theme.input.background};
        border: ${staticColors.borderField};
      }
    }

    &-arrow {
      color: ${({ theme }) => theme.input.color};
    }

    &-dropdown {
      background: ${({ theme }) => theme.input.background};
    }
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${({ theme }) => theme.filters.selection};
    color: ${({ theme }) => theme.input.color};
    border: 0;
    align-items: center;
  }
`;
