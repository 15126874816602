import { IndicatorsKeys, isIndicatorsKey } from 'src/constants/charts';
import i18n from 'src/i18n';

import { ChartOptionsParams, ChartWithGroupedCategoriesOptions } from './Chart.types';

/**
 * Get priority chart options.
 */
export const getChartOptionsGetter =
  (key: string) =>
  ({ chart, isFixed, chartCustomName }: ChartOptionsParams) => {
    const CHART_OPTIONS_GETTERS_MAP: { [key in IndicatorsKeys]: Partial<ChartWithGroupedCategoriesOptions> } = {
      [IndicatorsKeys.factorAnalysis]: {
        title: {
          text: chartCustomName || `${chart.label} ${isFixed ? ` (${i18n.t('widget:pinned')})` : ''}`,
        },
        tooltip: {
          headerFormat: undefined,
          pointFormat: '<span style="color:{point.color}">●</span> {point.category.name}:<br/><b>{point.y}</b>',
        },
        legend: {
          enabled: false,
        },
      },
    };

    return isIndicatorsKey(key) ? CHART_OPTIONS_GETTERS_MAP[key] : null;
  };
