import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { GOHUB_REDIRECT_URL } from 'src/constants';
import { removeToken } from 'src/utils';

import { DropdownUser, AvatarImg, AvatarIcon, Logout, AvatarInfo, UserName, UserPosition } from './UserInfo.styled';
import { UserInfoProps } from './UserInfo.types';

const Avatar: FC<UserInfoProps> = ({ person }) => {
  const { t } = useTranslation('profile');
  const { avatar, position, first_name: firstName, last_name: lastName } = person || {};

  const onClickHandler = (data: { key: string | number }) => {
    if (data.key === 'logout') {
      removeToken();
      window.location.href = GOHUB_REDIRECT_URL;
    }
  };

  const menu = (
    <Menu onClick={onClickHandler}>
      <AvatarInfo>
        <UserName>
          {firstName || ''} {lastName || ''}
        </UserName>
        <UserPosition>{position || ''}</UserPosition>
      </AvatarInfo>
      <Logout key="logout" icon={<LogoutOutlined />}>
        {t('nav.exit')}
      </Logout>
    </Menu>
  );

  return (
    <DropdownUser overlay={menu} trigger={['click']} placement="bottomRight" arrow>
      {avatar ? (
        <AvatarImg src={avatar} alt="avatar" />
      ) : (
        <AvatarIcon>
          <UserOutlined />
        </AvatarIcon>
      )}
    </DropdownUser>
  );
};

export default Avatar;
