import { Form } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InputField, SelectField } from 'src/components';
import { SelectOptions } from 'src/components/Fields/Fields.types';
import { PageTypes } from 'src/services/PageService/PageService.types';
import { getTemplatesState } from 'src/store/pages';
import { getUserCanOpenReports } from 'src/store/user';

import { ModalWrapper } from './CreatePageForm.styled';
import { CreatePageFormProps } from './CreatePageForm.types';

const CreatePageForm: FC<CreatePageFormProps> = ({ isVisible, isLoading, onCreate, onCancel }) => {
  const { t } = useTranslation('dashboard');
  const templates = useSelector(getTemplatesState);
  const userCanOpenReports = useSelector(getUserCanOpenReports);

  const [form] = Form.useForm();
  const [typePage, setTypePage] = useState<PageTypes>(PageTypes.dashboard);
  const [isEmptyDashboard, setIsEmptyDashboard] = useState<boolean>(true);

  const options: SelectOptions[] = [
    {
      title: t('createForm.select.dashboard'),
      value: PageTypes.dashboard,
    },
    {
      title: t('createForm.select.analytics'),
      value: PageTypes.analytics,
    },
  ];

  if (userCanOpenReports) {
    options.push({
      title: t('createForm.select.report'),
      value: PageTypes.report,
    });
  }

  const resetForm = () => {
    form.resetFields();
    setTypePage(PageTypes.dashboard);
    setIsEmptyDashboard(true);
  };

  const onCancelHandler = () => {
    onCancel();
    resetForm();
  };

  const onOkHandler = () => {
    form.validateFields().then((values) => {
      if (isEmptyDashboard) {
        onCreate({ pageType: values.pageType, name: values.name }).then(() => {
          resetForm();
        });
      } else {
        onCreate(values).then(() => {
          resetForm();
        });
      }
    });
  };

  const onChangeType = async (value: string | number) => {
    form.setFieldsValue({
      name: undefined,
    });
    value === PageTypes.dashboard && setTypePage(PageTypes.dashboard);
    value === PageTypes.analytics && setTypePage(PageTypes.analytics);
    value === PageTypes.report && setTypePage(PageTypes.report);
  };

  const onChangeTemplate = async (value: string | number) => {
    form.setFieldsValue({
      name: undefined,
    });
    setIsEmptyDashboard(value === 'empty');
  };

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      confirmLoading={isLoading}
      title={t('createForm.title')}
      okText={t('createForm.createButton')}
      cancelText={t('createForm.cancelButton')}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
    >
      <Form form={form} layout="vertical" initialValues={{ pageType: PageTypes.dashboard, templateId: 'empty' }}>
        <SelectField
          name="pageType"
          placeholder={t('createForm.typePage')}
          rules={[{ required: true, message: t('createForm.errors.typePage') }]}
          options={options}
          onChange={onChangeType}
        />
        {templates.length > 0 && typePage === PageTypes.dashboard && (
          <SelectField
            name="templateId"
            placeholder={t('createForm.templatePage')}
            rules={[{ required: false }]}
            options={templates.map((template) => ({ title: template.name, value: template.id }))}
            emptySelect={{ title: t('createForm.emptyTemplateTitle'), value: 'empty' }}
            onChange={onChangeTemplate}
            showSearch
          />
        )}
        {((typePage === PageTypes.dashboard && isEmptyDashboard) ||
          typePage === PageTypes.analytics ||
          typePage === PageTypes.report) && (
          <InputField
            name="name"
            placeholder={t('createForm.namePage')}
            rules={[
              { required: true, message: t('createForm.errors.namePage') },
              { max: 250, message: t('createForm.errors.maxLength') },
            ]}
          />
        )}
      </Form>
    </ModalWrapper>
  );
};

export default CreatePageForm;
