import MenuOutlined from '@ant-design/icons/lib/icons/MenuOutlined';
import { FC } from 'react';

import { BurgerButton } from './Burger.styled';
import { BurgerProps } from './Burger.types';

const Burger: FC<BurgerProps> = ({ onToggleNav }) => {
  return (
    <BurgerButton type="button" onClick={onToggleNav}>
      <MenuOutlined />
    </BurgerButton>
  );
};

export default Burger;
