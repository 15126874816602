import { OptionData, OptionGroupData } from 'rc-select/lib/interface';

export const DEFAULT_FILTER_OPTION: (input: string, option?: OptionData | OptionGroupData) => boolean = (
  input,
  option
) => {
  if (typeof option?.children === 'string') {
    return option?.children?.toLowerCase().includes(input.toLowerCase()) || false;
  }

  return `${option?.value || ''}`.toLowerCase().includes(input.toLowerCase()) || false;
};
