import { Layout } from 'react-grid-layout';

export const getNewLayout = (layout: Layout[], cols: number): Layout[] => {
  let y = 0;
  let x = 0;

  return layout.map((layoutItem) => {
    const newLayoutItem: Layout = { w: 1, h: 1, i: layoutItem.i, x, y };
    x++;

    if (newLayoutItem.x >= cols) {
      x = 1;
      newLayoutItem.x = 0;
      newLayoutItem.y++;
      y++;
    }

    return newLayoutItem;
  });
};
