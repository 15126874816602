export enum FiltersKeys {
  type = 'd3e426f9-01fd-4d09-819d-46ad9e369d3e',
  class = 'a4897d5a-b614-4c93-aee7-8267fb8db1a1',
  reason = '83b7ae3a-898c-4001-9477-a72d96a7a0ef',
  detailing = 'bc827be8-571e-4440-a975-bd38203dc864',
}

export const isFiltersKey = (filterKey: any): filterKey is FiltersKeys =>
  Object.values(FiltersKeys).includes(filterKey as FiltersKeys);

export const FILTERS_DEPENDENCIES: Partial<{ [key in FiltersKeys]: FiltersKeys[] }> = {
  [FiltersKeys.type]: [FiltersKeys.class, FiltersKeys.reason, FiltersKeys.detailing],
  [FiltersKeys.class]: [FiltersKeys.reason, FiltersKeys.detailing],
  [FiltersKeys.reason]: [FiltersKeys.detailing],
};
