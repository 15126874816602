import { AxiosResponse } from 'axios';

import { agent, agentGoHub } from 'src/agent';

import { ICard, IPersonInfo, ICheckAuthResponse, IRolesResponse } from './AuthService.types';

export const checkUserAuth = (): Promise<AxiosResponse<ICheckAuthResponse>> => {
  return agent.get('api/users/check-auth/');
};

export const getUserInfo = (): Promise<AxiosResponse<IPersonInfo>> => {
  return agentGoHub.get('api/user/');
};

export const getProducts = (): Promise<AxiosResponse<ICard[]>> => {
  return agentGoHub.get('api/products/?is_available=true');
};

export const getRolesOfUsers = (): Promise<AxiosResponse<IRolesResponse[]>> => {
  return agent.get('api/users/roles');
};
