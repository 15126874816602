import { Dropdown, Menu, Tooltip } from 'antd';
import Highcharts from 'highcharts';
import { FC, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { v4 as uuidv4 } from 'uuid';

import { AddToDashboardModal, Chart, DeleteAnalyticsChartModal, OpenAnalyticsModal } from 'src/components';
import { useChartOptions } from 'src/components/Chart/Chart.helpers';
import {
  useDisplayTypeMemo,
  useGraphMemo,
  useHiddenSeriesMemo,
} from 'src/components/DashboardChart/DashboardChart.helpers';
import {
  Container,
  LeftMenu,
  MenuContainer,
  MenuIcon,
  MenuItem,
  QuestionIcon,
  Switcher,
  TooltipText,
} from 'src/components/DashboardChart/DashboardChart.styled';
import { ChartType, DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { getPageById, updatePage } from 'src/services/PageService';
import { ChartData } from 'src/services/PageService/PageService.types';
import { getFiltersState } from 'src/store/analytics';
import { getDashboardsState, getGraphFrontendState, getPageNameState, updatePageAction } from 'src/store/pages';
import { getDynamicPeriodValues, parseDataToFilters, showErrorMessage, showSuccessMessage } from 'src/utils';

import { DEFAULT_CHART_UNITS, isIndicatorsKey } from '../../constants/charts';

import { AnalyticsChartProps } from './AnalyticsChart.types';

const AnalyticsChart: FC<AnalyticsChartProps> = (props) => {
  const {
    containerClassName,
    className,
    pageId,
    chartId = 0,
    setFilterValues,
    filterValues,
    onChangeShowingValuesOnChart,
    onToggleChartSeries,
    ...restProps
  } = props;
  const { t } = useTranslation('widget');

  const dispatch = useDispatch();
  const dashboards = useSelector(getDashboardsState);
  const filters = useSelector(getFiltersState);
  const pageName = useSelector(getPageNameState(pageId));

  const theme = useTheme();

  const graph = useGraphMemo(restProps.graph);

  const { chartType, showingValuesOnChart, ...restFrontend } = useSelector(
    getGraphFrontendState(pageId, graph.graph.key, graph.layout_id)
  );

  const { value: hiddenChartSeries } = useHiddenSeriesMemo(restFrontend.hiddenChartSeries, true);
  const { value: displayType } = useDisplayTypeMemo(restFrontend.displayType, true);

  const [isLoading, setLoading] = useState(false);
  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isAnalyticsModalOpened, setAnalyticsModalOpened] = useState(false);
  const [isDashboardModalOpened, setDashboardModalOpened] = useState(false);
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);

  const chart = graph.data?.charts?.[chartId];

  const newChart: ChartData = useMemo(() => {
    return JSON.parse(JSON.stringify(chart));
  }, [graph, chartId]);

  const openAnalyticsModal = () => {
    setAnalyticsModalOpened(true);
    setMenuOpened(false);
  };
  const openDashboardModal = () => {
    setDashboardModalOpened(true);
    setMenuOpened(false);
  };
  const openDeleteModal = () => {
    setDeleteModalOpened(true);
    setMenuOpened(false);
  };

  const pinGraph = async () => {
    setLoading(true);

    const layoutId = uuidv4();

    getPageById(pageId)
      .then(({ data }) => {
        updatePage(
          pageId,
          {
            ...data,
            page_map: {
              ...data.page_map,
              frontend: {
                ...data.page_map?.frontend,
                chart_types: [
                  ...(data.page_map?.frontend?.chart_types || []),
                  { key: `${layoutId}-${graph.graph.key}`, value: chartType },
                ],
              },
              graphs: [...(data.page_map?.graphs || []), { ...graph, layout_id: layoutId, is_fixed: true }],
            },
          },
          true
        )
          .then(({ data: updatedData }) => {
            dispatch(updatePageAction(updatedData.id, updatedData));
            setFilterValues(parseDataToFilters(updatedData.page_map?.graphs || [], filters));
            showSuccessMessage(t('messages.successSaved', { name: pageName || '' }));
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        showErrorMessage(t('messages.errorSaved', { name: pageName || '', error: error?.message }));
        setLoading(false);
      });
  };

  const unpinGraph = async () => {
    setLoading(true);
    getPageById(pageId)
      .then(({ data }) => {
        const newGraphs = data.page_map?.graphs?.filter((item) => item.layout_id !== graph.layout_id) || [];
        updatePage(
          pageId,
          {
            ...data,
            page_map: {
              ...data.page_map,
              graphs: newGraphs,
            },
          },
          true
        )
          .then(({ data: updatedData }) => {
            dispatch(updatePageAction(updatedData.id, updatedData));
            setFilterValues(parseDataToFilters(updatedData.page_map?.graphs || [], filters));
            showSuccessMessage(t('messages.successSaved', { name: pageName || '' }));
          })
          .finally(() => setLoading(false));
      })
      .catch((error) => {
        showErrorMessage(t('messages.errorSaved', { name: pageName || '', error: error?.message }));
        setLoading(false);
      });
  };

  const menu = (
    <Menu>
      <MenuItem>
        <button onClick={openAnalyticsModal} disabled={isLoading}>
          {t('openAnalytics')}
        </button>
      </MenuItem>

      <MenuItem>
        <button onClick={openDashboardModal} disabled={!dashboards.length || isLoading}>
          {t('addToDashboard')}
        </button>
      </MenuItem>

      {!graph.is_fixed && (
        <>
          <MenuItem>
            <button onClick={pinGraph} disabled={isLoading}>
              {t('pin')}
            </button>
          </MenuItem>

          <MenuItem isDanger={true}>
            <button onClick={openDeleteModal}>{t('delete')}</button>
          </MenuItem>
        </>
      )}

      {!!graph.is_fixed && (
        <MenuItem isDanger={true}>
          <button onClick={unpinGraph} disabled={isLoading}>
            {t('unpin')}
          </button>
        </MenuItem>
      )}
    </Menu>
  );

  if (
    chart &&
    displayType?.displayType === DisplayType.dynamic &&
    displayType?.updatePeriod &&
    graph.name !== 'УТЗ' &&
    graph.graph.chart_type !== ChartType.pie
  ) {
    chart.series.forEach((series, index) => {
      const updatedPeriodValues = getDynamicPeriodValues(series.data, displayType.updatePeriod || '');
      if (updatedPeriodValues) {
        newChart.series[index].data = updatedPeriodValues.flat();
      }
    });
  }

  if (
    chart &&
    displayType?.displayType === DisplayType.static &&
    graph.name !== 'УТЗ' &&
    graph.graph.chart_type !== ChartType.pie
  ) {
    chart.series.forEach((series, index) => {
      newChart.series[index].data = series.data;
    });
  }

  const chartRef = useRef<Highcharts.Chart>(null);
  const indicatorKey = graph.graph.key;

  const chartOptions = useChartOptions(
    {
      chart: newChart,
      chartHiddenSeries: hiddenChartSeries,
      chartRef,
      chartType,
      displayType: displayType?.displayType,
      graph,
      onToggleChartSeries,
      showingValuesOnChart,
      theme,
      updatePeriod: displayType?.updatePeriod,
      chartUnit: isIndicatorsKey(indicatorKey) ? DEFAULT_CHART_UNITS[indicatorKey] : null,
      graphDisplayType: displayType,
    },
    [
      newChart,
      chartRef,
      chartType,
      displayType?.displayType,
      displayType?.updatePeriod,
      graph,
      hiddenChartSeries,
      showingValuesOnChart,
      theme,
    ]
  );

  return (
    <>
      <Container className={className}>
        <MenuContainer>
          <LeftMenu>
            <Tooltip
              placement="topRight"
              title={
                <div>
                  <TooltipText>
                    {t('tooltip.zoomDesktop')} <span>{t('tooltip.zoomDesktopKey')}</span>
                  </TooltipText>
                  <TooltipText>
                    {t('tooltip.zoomMobile')} <span>{t('tooltip.zoomMobileKey')}</span>
                  </TooltipText>
                </div>
              }
            >
              <QuestionIcon />
            </Tooltip>
            <Tooltip
              placement="topRight"
              title={showingValuesOnChart ? t('disableValuesOnChart') : t('enableValuesOnChart')}
            >
              <Switcher
                checked={showingValuesOnChart}
                onChange={(value) => onChangeShowingValuesOnChart(value, graph.graph.key)}
              />
            </Tooltip>
          </LeftMenu>

          <Dropdown
            overlay={menu}
            placement="bottomRight"
            visible={isMenuOpened}
            onVisibleChange={(flag) => setMenuOpened(flag)}
          >
            <MenuIcon />
          </Dropdown>
        </MenuContainer>
        <Chart options={chartOptions} containerClassName={containerClassName} ref={chartRef} />
      </Container>

      <AddToDashboardModal
        isVisible={isDashboardModalOpened}
        onClose={() => setDashboardModalOpened(false)}
        graph={graph}
        chart={newChart}
        displayType={displayType?.displayType}
        updatePeriod={displayType?.updatePeriod}
        chartType={chartType}
        hiddenSeries={hiddenChartSeries}
      />

      <OpenAnalyticsModal
        isVisible={isAnalyticsModalOpened}
        onClose={() => setAnalyticsModalOpened(false)}
        graph={graph}
        chart={newChart}
      />

      <DeleteAnalyticsChartModal
        isVisible={isDeleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        graph={graph}
        filterValues={filterValues}
        setFilterValues={setFilterValues}
      />
    </>
  );
};

export default AnalyticsChart;
