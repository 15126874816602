import { Select } from 'antd';
import { FormEventHandler, useEffect, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';

import SelectPicker from 'src/components/SelectPicker';
import { SelectChangeHandler } from 'src/components/SelectPicker/SelectPicker.types';
import {
  getReportUID,
  getReportTypes,
  getReportStatus,
  getReportFile,
} from 'src/services/ReportsService/ReportsService';
import { ReportStatus, ReportType } from 'src/services/ReportsService/ReportsService.types';
import { showErrorMessage } from 'src/utils';

import { getReportFieldsFromFilters } from './ReportForm.helpers';
import { FieldWrapper, FormWrapper, ReportSpin, SubmitButton } from './ReportForm.styled';
import { ReportFormProps } from './ReportForm.types';

const UPDATE_DELAY = 6000;

const downloadFile = async (fileName: string): Promise<void> => {
  const { data, status } = await getReportFile(fileName);

  if (status === 200) {
    const dataURL = window.URL.createObjectURL(data);
    const link = document.createElement('a');
    link.href = dataURL;
    link.download = 'Report';
    link.click();
    link.remove();
  }
};

const ReportForm: VFC<ReportFormProps> = ({ filtersValues }) => {
  const { t } = useTranslation('report');

  const [reportType, setReportType] = useState<string>();
  const [uid, setUID] = useState<string>();
  const [reportTypes, setReportTypes] = useState<ReportType[]>();
  const [isLoading, setIsLoading] = useState(false);
  const [isTypesFetching, setIsTypesFetching] = useState(false);

  useEffect(() => {
    if (uid) {
      setIsLoading(true);

      const interval = window.setInterval(async () => {
        const {
          data: { status: fileStatus, file },
        } = await getReportStatus(uid);

        if (fileStatus === ReportStatus.completed) {
          window.clearInterval(interval);

          await downloadFile(file);

          setUID('');
          setIsLoading(false);
        }
      }, UPDATE_DELAY);

      return () => {
        window.clearInterval(interval);
      };
    }
  }, [uid]);

  useEffect(() => {
    setIsTypesFetching(true);

    const fetchReportTypes = async () => {
      const { data, status } = await getReportTypes();

      if (status === 200) {
        setReportTypes(data);
      } else {
        throw new Error();
      }
    };

    setIsTypesFetching(true);

    fetchReportTypes()
      .catch(() => {
        showErrorMessage('Failed to fetch report types.');
      })
      .finally(() => setIsTypesFetching(false));
  }, []);

  const onReportTypeChange: SelectChangeHandler = (value) => {
    if (value) {
      setReportType(String(value));
    }
  };

  const onSubmit: FormEventHandler<HTMLFormElement> = async (e) => {
    e.preventDefault();

    if (reportType) {
      setIsLoading(true);

      try {
        const reportFields = filtersValues ? getReportFieldsFromFilters(filtersValues) : {};

        const { data, status } = await getReportUID(reportType, reportFields);

        if (status === 200) {
          setUID(data.uid);
        }
      } catch (error) {
        console.error(error);

        showErrorMessage('Failed to get report.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  return (
    <FormWrapper onSubmit={onSubmit}>
      <FieldWrapper>
        <label>{t('reportType')}</label>
        <SelectPicker
          value={reportType}
          onChange={onReportTypeChange}
          loading={isTypesFetching}
          disabled={isTypesFetching || isLoading}
        >
          {reportTypes?.map(({ id, name }) => (
            <Select.Option key={id} value={id}>
              {name}
            </Select.Option>
          ))}
        </SelectPicker>
      </FieldWrapper>

      <SubmitButton htmlType="submit" disabled={!reportType || isTypesFetching || isLoading}>
        {isLoading && <ReportSpin />}
        {t('downloadReport')}
      </SubmitButton>
    </FormWrapper>
  );
};

export default ReportForm;
