import { FC } from 'react';

import { ModalWrapper } from '../forms/CreatePageForm/CreatePageForm.styled';
import { OpenAnalyticsModalProps } from '../OpenAnalyticsModal/OpenAnalyticsModal.types';

import { FilterName } from './ChartPropertiesModal.styled';

const ChartPropertiesModal: FC<OpenAnalyticsModalProps> = (props) => {
  const { isVisible, graph, onClose } = props;

  const graphProperties = graph.data?.filter_display_info;

  return (
    <ModalWrapper visible={isVisible} centered title={graph.name} onCancel={onClose} footer={null}>
      {graphProperties?.map((prop) => (
        <p key={prop.prop_name}>
          <FilterName>{prop.prop_name}:</FilterName> {prop.value}
        </p>
      ))}
    </ModalWrapper>
  );
};

export default ChartPropertiesModal;
