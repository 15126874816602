import { Layouts } from 'react-grid-layout';

import { FrontendValue, PageResponse, TemplateDashboardResponse } from 'src/services/PageService/PageService.types';

import { PagesActions, PagesActionTypes, ViewMode } from './types';

export const setPagesAction = (pages: PageResponse[]): PagesActions => ({
  type: PagesActionTypes.setPages,
  payload: pages,
});

export const addPageAction = (page: PageResponse): PagesActions => ({
  type: PagesActionTypes.addPage,
  payload: page,
});

export const removePageAction = (id: number): PagesActions => ({
  type: PagesActionTypes.removePage,
  payload: id,
});

export const updatePageAction = (id: number, data: PageResponse): PagesActions => ({
  type: PagesActionTypes.updatePage,
  payload: { id, data },
});

export const updatePageLayoutAction = (id: number, data: Layouts): PagesActions => ({
  type: PagesActionTypes.updatePageLayout,
  payload: { id, data },
});

export const setTemplatesAction = (templates: TemplateDashboardResponse[]): PagesActions => ({
  type: PagesActionTypes.setTemplates,
  payload: templates,
});

export const addTemplateDashboard = (template: TemplateDashboardResponse): PagesActions => ({
  type: PagesActionTypes.addTemplate,
  payload: template,
});

export const updateTemplateDashboard = (template: TemplateDashboardResponse): PagesActions => ({
  type: PagesActionTypes.updateTemplate,
  payload: template,
});

export const cleanPageMapInAllPages = (): PagesActions => ({
  type: PagesActionTypes.cleanPageMap,
});

export const changeViewModeDashboard = (viewMode: ViewMode): PagesActions => ({
  type: PagesActionTypes.changeViewMode,
  payload: viewMode,
});

export const updateChartHiddenSeries = (
  pageId: number,
  chartKey: string,
  data: FrontendValue<string[]>
): PagesActions => ({
  type: PagesActionTypes.updateChartHiddenSeries,
  payload: { pageId, chartKey, data },
});

export const updateShowingValuesOnChart = (pageId: number, chartKey: string, data: boolean): PagesActions => ({
  type: PagesActionTypes.updateShowingValuesOnChart,
  payload: { pageId, chartKey, data },
});
