import { Button } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;

  & .ant-picker.ant-picker-range {
    border-bottom: 1px solid #d9d9d9;
    border-top: 1px solid #d9d9d9;
    border-radius: 0;
  }

  & .ant-btn {
    :first-child {
      border-radius: 2px 0 0 2px;
    }

    &:last-child {
      border-radius: 0 2px 2px 0;
    }
  }
`;

export const ButtonChangePeriod = styled(Button)`
  background: ${({ theme }) => theme.button.background};
  color: ${({ theme }) => theme.button.color};

  &:hover {
    background: ${({ theme }) => theme.button.background};
  }
`;
