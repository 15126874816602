import { AxiosResponse } from 'axios';

import { agent } from 'src/agent';

import { WellDetails } from '../ReportsService/ReportsService.types';

import { PageResponse, PageRequest, TemplateDashboardRequest, TemplateDashboardResponse } from './PageService.types';

export const getPageList = (): Promise<AxiosResponse<PageResponse[]>> => {
  return agent.get('/api/pages');
};

export const createPage = (data: PageRequest, isRetrieveData?: boolean): Promise<AxiosResponse<PageResponse>> => {
  return agent.post('api/pages', data, { params: { retrieve_data: isRetrieveData } });
};

export const removePage = (id: number): Promise<AxiosResponse<void>> => {
  return agent.delete(`api/pages/${id}`);
};

export const updatePage = (
  id: number,
  data: Partial<PageResponse>,
  isRetrieveData?: boolean
): Promise<AxiosResponse<PageResponse>> => {
  return agent.patch(`api/pages/${id}/`, data, { params: { retrieve_data: isRetrieveData } });
};

export const getPageById = (id: number, isRetrieveData?: boolean): Promise<AxiosResponse<PageResponse>> => {
  return agent.get(`/api/pages/${id}`, { params: { retrieve_data: isRetrieveData } });
};

export const getWellDetailsById = (id: string): Promise<AxiosResponse<WellDetails>> => {
  return agent.get(`/api/pages/well-details/${id}`);
};

export const getPageAsXLSX = (pageId: string | number): Promise<AxiosResponse<Blob>> => {
  return agent.get(`/api/pages/${pageId}/export-xls`, { responseType: 'blob' });
};

export const createTemplateDashboard = (
  data: TemplateDashboardRequest
): Promise<AxiosResponse<TemplateDashboardResponse>> => {
  return agent.post('api/templates', data);
};

export const editTemplateDashboard = (
  id: number,
  data: TemplateDashboardRequest
): Promise<AxiosResponse<TemplateDashboardResponse>> => {
  return agent.patch(`api/templates/${id}`, data);
};

export const getTemplates = (): Promise<AxiosResponse<TemplateDashboardResponse[]>> => {
  return agent.get('/api/templates');
};
