import { Drawer } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const DrawerWrapper = styled(Drawer)`
  .ant-drawer-body {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .ant-drawer-content {
    background: ${({ theme }) => theme.navMobile.background};
  }

  .anticon-close {
    color: ${({ theme }) => theme.navMobile.color};
  }
`;

export const LanguageWrapper = styled.div`
  .ant-dropdown-trigger {
    color: ${({ theme }) => theme.navMobile.color};
  }
`;

export const UserInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 15px;
`;

export const AvatarImg = styled.img`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: ${staticColors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
`;

export const AvatarIcon = styled.div`
  height: 60px;
  width: 60px;
  border-radius: 50%;
  object-fit: cover;
  background: ${staticColors.background};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;

  span svg {
    font-size: 34px;
  }
`;

export const UserName = styled.p`
  color: ${({ theme }) => theme.navMobile.color};
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  margin: 0;
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const UserPosition = styled.p`
  line-height: 20px;
  font-size: 16px;
  margin: 0;
  color: ${({ theme }) => theme.navMobile.colorRgba};
  max-width: 216px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;

export const Nav = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const NavItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 104px;
  margin: 2px;
  padding: 5px 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  color: ${staticColors.black};
  transition: color, background-color 0.3s;
  background-color: ${staticColors.background};
  color: ${staticColors.black};
`;

export const GoHubLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 104px;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  color: ${staticColors.white};
  transition: background-color 0.3s;
  background-color: rgba(${staticColors.redRgba}, 0.85);
  margin-top: 2px;

  &:hover {
    color: ${staticColors.white};
    background-color: ${staticColors.red};
  }

  svg {
    margin-right: 10px;
  }
`;

export const AppIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

export const EmptyApps = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
  color: ${({ theme }) => theme.navMobile.color};
`;

export const Logout = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 104px;
  flex: 40px 0 0;
  border-radius: 4px;
  color: ${staticColors.white};
  transition: background-color 0.3s;
  background-color: #4e8cae;
  margin-top: 15px;
  border: 0;

  svg {
    margin-right: 10px;
  }
`;
