import { AxiosResponse } from 'axios';

import { agent } from 'src/agent';

import { ReportFields, ReportResponse, ReportStatusResponse, ReportTypesResponse } from './ReportsService.types';

export const getReportTypes = (): Promise<AxiosResponse<ReportTypesResponse>> => {
  return agent.get('/api/pages/report/types/');
};

export const getReportUID = (type: string, params: ReportFields): Promise<AxiosResponse<ReportResponse>> => {
  return agent.post('/api/pages/report/', {
    type,
    params,
  });
};

export const getReportStatus = (uid: string): Promise<AxiosResponse<ReportStatusResponse>> => {
  return agent.get(`/api/pages/report/status/${uid}/`);
};

export const getReportFile = (fileName: string): Promise<AxiosResponse<Blob>> => {
  return agent.get(`/api/pages/report/file/${fileName}/`, { responseType: 'blob' });
};
