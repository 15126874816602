import { AnalyticsFilters } from 'src/services/AnalyticsService/AnalyticsService.types';

export type AnalyticsState = {
  filters: AnalyticsFilters | null;
};

export enum AnalyticsActionTypes {
  setFilters = 'SET_FILTERS',
}

export type AnalyticsActions = { type: AnalyticsActionTypes.setFilters; payload: AnalyticsFilters | null };
