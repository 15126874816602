import { DefaultTheme } from 'styled-components';

export const staticColors = {
  primary: '#003b5c',
  black: '#011f30',
  white: '#ffffff',
  background: '#e2e2e2',
  red: '#da291c',
  redRgba: '218, 41, 28',
  blue: '#4e8cae',
  modalButton: '#004264',
  borderField: '1px solid rgba(0, 59, 92, 0.2)',
  lightGray: '#f5f5f5',
};

export const lightTheme: DefaultTheme = {
  colors: {
    backgroundContent: '#fefefe',
    text: '#111111',
    descriptionColor: '#808f98',
    primary: '#003B5C',
    title: '#5892b2',
    backgroundLayout: '#f3f3f3',
    input: '#ffffff',
    inputBorder: '#ccd8de',
    chartLabel: null,
  },
  tabs: {
    background: '#e9eaeb',
    color: '#003B5C',
    backgroundActive: '#D4E1E9',
    colorActive: '#003B5C',
  },
  export: {
    background: '#357293',
    backgroundHover: '#02293F',
  },
  modal: {
    background: '#ffffff',
    text: '#111111',
    primaryText: '#ffffff',
    inputBorder: '#ccd8de',
  },
  input: {
    color: '#111111',
    background: '#ffffff',
  },
  navMobile: {
    color: '#011f30',
    colorRgba: 'rgba(1, 31, 48, 0.5)',
    background: '#ffffff',
  },
  analytics: {
    border: '#f0f0f0',
    color: '#011f30',
    background: '#e9eaeb',
    toggleBackground: '#D4E1E9',
    toggleColor: '#003B5C',
    loader: 'rgba(255, 255, 255, 0.7)',
  },
  filters: {
    text: '#111111',
    selection: '#f0f0f0',
  },
  chart: {
    background: '#fff',
  },
  reset: {
    color: '#357293',
    colorHover: '#004264',
  },
  buttonCollapse: {
    color: 'rgba(0, 0, 0, 0.85)',
  },
  switch: {
    background: '#e9eaeb',
    backgroundActive: '#357293',
  },
  button: {
    background: '#ffffff',
    color: '#003B5C',
  },
};

export const darkTheme: DefaultTheme = {
  colors: {
    backgroundContent: '#002031',
    text: '#ffffff',
    descriptionColor: '#99b3c1',
    primary: '#5892b2',
    title: '#003B5C',
    backgroundLayout: '#092e45',
    input: '#003A58',
    inputBorder: '#588eab',
    chartLabel: '#ffffff',
  },
  tabs: {
    background: '#02293F',
    color: '#4E8CAE',
    backgroundActive: '#004264',
    colorActive: '#ffffff',
  },
  export: {
    background: '#02293F',
    backgroundHover: '#357293',
  },
  modal: {
    background: '#004264',
    text: '#ffffff',
    primaryText: '#ffffff',
    inputBorder: '#588eab',
  },
  input: {
    color: '#ffffff',
    background: '#003A58',
  },
  navMobile: {
    color: '#ffffff',
    colorRgba: 'rgba(255, 255, 255, 0.5)',
    background: '#003A58',
  },
  analytics: {
    border: '#5892b2',
    color: '#ffffff',
    background: '#02293F',
    toggleBackground: '#004264',
    toggleColor: '#ffffff',
    loader: 'rgba(14, 59, 92, 0.7)',
  },
  filters: {
    text: '#ffffff',
    selection: '#5892b2',
  },
  chart: {
    background: '#002031',
  },
  reset: {
    color: '#357293',
    colorHover: '#4e8cae',
  },
  buttonCollapse: {
    color: '#ffffff',
  },
  switch: {
    background: '#02293F',
    backgroundActive: '#004264',
  },
  button: {
    background: '#004264',
    color: '#ffffff',
  },
};
