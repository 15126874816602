import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { ConfirmModal } from 'src/components';

import { DeleteAnalyticsChartModalProps } from './DeleteAnalyticsChartModal.types';

const DeleteAnalyticsChartModal: FC<DeleteAnalyticsChartModalProps> = (props) => {
  const { isVisible, onClose, graph, filterValues, setFilterValues } = props;
  const { t } = useTranslation('widget');

  const handleDelete = () => {
    const updatedIndicators = filterValues?.indicators?.filter((item) => item.key !== graph.graph.key);
    const updatedFilters = filterValues?.filters?.filter((item) => item.keyIndicator !== graph.graph.key);
    setFilterValues({ ...filterValues, indicators: updatedIndicators, filters: updatedFilters });
    onClose();
  };

  return (
    <ConfirmModal
      isVisible={isVisible}
      onRemove={handleDelete}
      onCancel={onClose}
      title={t('deleteModal.title')}
      okText={t('deleteModal.closeButton')}
      cancelText={t('deleteModal.cancelButton')}
    >
      {t('deleteModal.content')}
    </ConfirmModal>
  );
};

export default DeleteAnalyticsChartModal;
