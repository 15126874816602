import { Menu, Dropdown } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const DropdownLang = styled(Dropdown)`
  cursor: pointer;
  margin-right: 24px;
  font-weight: bold;
  font-size: 16px;
  color: ${staticColors.white};
  line-height: 20px;
`;

export const MenuItem = styled(Menu.Item)`
  font-weight: bold;
  font-size: 16px;
  color: ${staticColors.black};
  line-height: 20px;
  padding: 6px 18px;
`;
