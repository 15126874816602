import { FC, ReactElement, useState } from 'react';

interface LoadingState {
  loading: boolean;
  disabled: boolean;
  onFocus: VoidFunction;
  onBlur: VoidFunction;
}

const IndicatorFilterStateWrapper: FC<{ isLoading?: boolean; children: (state: LoadingState) => ReactElement }> = ({
  children,
  isLoading = false,
}) => {
  const [open, setOpen] = useState(false);

  const loadingState = {
    loading: isLoading,
    disabled: isLoading && !open,
    onFocus: () => setOpen(true),
    onBlur: () => setOpen(false),
  };

  return children(loadingState);
};

export default IndicatorFilterStateWrapper;
