import { unitOfTime } from 'moment';

import { ChartType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { RangePeriod } from 'src/components/RangePicker/RangePicker.types';

export enum IndicatorsKeys {
  factorAnalysis = '9d573d48-19c1-46ae-a5af-adcadb0a350c', // Факторный анализ
}

export const isIndicatorsKey = (key: any): key is IndicatorsKeys => IndicatorsKeys.factorAnalysis === key;

export const DEFAULT_AVAILABLE_CHART_TYPES = [ChartType.column, ChartType.line, ChartType.pie, ChartType.stack];

export const DEFAULT_CHART_TYPE = ChartType.column;

export const INDICATORS_AVAILABLE_CHART_TYPES: {
  [key in IndicatorsKeys]: { chartTypes: ChartType[]; defaultChartType: ChartType };
} = {
  [IndicatorsKeys.factorAnalysis]: {
    chartTypes: [ChartType.waterfall],
    defaultChartType: ChartType.waterfall,
  },
};

export const getDefaultChartTypes = (indicatorKey: string) => {
  if (!isIndicatorsKey(indicatorKey)) {
    return {
      chartTypes: DEFAULT_AVAILABLE_CHART_TYPES,
      defaultChartType: DEFAULT_CHART_TYPE,
    };
  }

  return (
    INDICATORS_AVAILABLE_CHART_TYPES[indicatorKey] || {
      chartTypes: DEFAULT_AVAILABLE_CHART_TYPES,
      defaultChartType: DEFAULT_CHART_TYPE,
    }
  );
};

export const DEFAULT_CHART_UNITS: { [key in IndicatorsKeys]: string } = {
  [IndicatorsKeys.factorAnalysis]: 'сут.',
};

export const VALID_DYNAMIC_PERIODS: Partial<{ [key in RangePeriod]: unitOfTime.Base }> = {
  [RangePeriod.day]: 'month',
  [RangePeriod.month]: 'year',
  [RangePeriod.quarter]: 'year',
  [RangePeriod.year]: 'year',
};

export const isValidDynamicPeriod = (period: any): period is RangePeriod => Object.values(RangePeriod).includes(period);
