import { Drawer } from 'antd';
import { ReflexElement } from 'react-reflex';
import styled from 'styled-components';

import { GridItem } from '../Dashboard/Dashboard.styled';

export const ReportWrapper = styled.div`
  position: relative;
  min-height: 100%;
  width: 100% !important;
  height: 100%;

  .reflex-container.vertical > .reflex-splitter {
    border-color: ${({ theme }) => theme.analytics.background};
  }

  .reflex-container {
    > .reflex-element {
      overflow: hidden;
    }

    > .reflex-splitter {
      background-color: ${({ theme }) => theme.analytics.background};
    }

    > .reflex-splitter.reflex-thin {
      opacity: 1;
    }
  }

  .ant-drawer-mask {
    display: none;
  }

  @media (max-width: 1366px) {
    .reflex-container.vertical > .reflex-splitter.reflex-thin {
      border-right: 8px solid rgba(255, 255, 255, 0);
      border-left: 8px solid rgba(255, 255, 255, 0);
    }

    .reflex-container.vertical > .reflex-splitter.reflex-thin.active,
    .reflex-container.vertical > .reflex-splitter.reflex-thin:hover {
      border-right: 8px solid #e4e4e4;
      border-left: 8px solid #e4e4e4;
    }
  }

  @media (max-width: 768px) {
    position: static;

    .ant-drawer-left.ant-drawer-open,
    .ant-drawer-right.ant-drawer-open {
      width: 256px;
    }

    .r-splitter {
      display: none;
    }

    .reflex-container {
      > .reflex-element {
        flex: 100% 0 0 !important;
        margin-top: 43px;
      }
    }
  }

  @media (max-width: 520px) {
    .ant-drawer-left.ant-drawer-open,
    .ant-drawer-right.ant-drawer-open,
    .ant-drawer-content-wrapper {
      width: 100% !important;
    }
  }
`;

export const ToggleLeftPanel = styled.button`
  position: fixed;
  top: 160px;
  left: 0px;
  z-index: 10;
  width: 40px;
  height: calc(100vh - 160px);
  background: ${({ theme }) => theme.analytics.toggleBackground};
  color: ${({ theme }) => theme.analytics.toggleColor};
  border: 0;
  border-radius: 0;
  cursor: pointer;

  > span.anticon {
    display: block;
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 61px;
    left: 5px;
    width: 160px;
    height: 40px;

    > span.anticon {
      top: 11px;
      left: unset;
      transform: unset;
      right: 8px;
    }
  }
`;
export const ToggleRightPanel = styled.button`
  position: fixed;
  top: 160px;
  right: 0;
  z-index: 10;
  width: 40px;
  height: calc(100vh - 160px);
  background: ${({ theme }) => theme.analytics.toggleBackground};
  color: ${({ theme }) => theme.analytics.toggleColor};
  border: 0;
  border-radius: 0;
  cursor: pointer;

  > span.anticon {
    display: block;
    position: absolute;
    top: 18px;
    left: 50%;
    transform: translateX(-50%);
    font-size: 18px;
  }

  @media (max-width: 768px) {
    position: absolute;
    top: 61px;
    right: 5px;
    width: 160px;
    height: 40px;

    > span.anticon {
      top: 11px;
      left: 8px;
      transform: unset;
    }
  }
`;

export const FilterLabel = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) rotate(270deg);
  white-space: nowrap;
  font-size: 16px;

  .anticon {
    margin-right: 6px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    transform: translate(-50%, -50%) rotate(0deg);
  }
`;

export const DrawerWrapper = styled(Drawer)`
  display: none;
  position: absolute;

  .ant-drawer-content {
    background: ${({ theme }) => theme.analytics.background};
    color: ${({ theme }) => theme.analytics.color};
  }

  .anticon-close {
    color: ${({ theme }) => theme.analytics.color};
  }

  .ant-drawer-body {
    padding: 30px 10px 10px 10px;
  }

  @media (max-width: 768px) {
    display: unset;
  }
`;

export const FilterPanel = styled(ReflexElement)`
  background: ${({ theme }) => theme.analytics.background};
  color: ${({ theme }) => theme.analytics.color};

  > div {
    min-height: 100%;
  }

  .ant-space-item {
    margin-top: 20px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

export const ContentWrapper = styled(ReflexElement)`
  color: ${({ theme }) => theme.analytics.color};
  background: ${({ theme }) => theme.colors.backgroundContent};
  height: 100%;
`;

export const ChartContainer = styled(GridItem)`
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const ContentPadding = styled.div`
  padding: 8px 10px;
  min-height: 100%;

  @media (max-width: 768px) {
    padding-bottom: 64px;
  }
`;

export const ReportLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: ${({ theme }) => theme.analytics.loader};
`;

export const ReportWells = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-top: 16px;
`;
