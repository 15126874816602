import Highcharts, { Options, XAxisLabelsOptions, XAxisOptions } from 'highcharts';
import { TFunction } from 'i18next';
import { MutableRefObject, RefObject } from 'react';
import { DefaultTheme } from 'styled-components';

import { CategoryData, ChartData, GraphDisplayTypes } from 'src/services/PageService/PageService.types';

import { ChartType } from '../forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';

export interface ChartWithGroupedCategoriesOptions extends Options {
  labels: (XAxisLabelsOptions & { groupedOptions?: { rotation?: number | undefined }[] | undefined }) | undefined;
  xAxis: XAxisOptions & {
    categories?: CategoryData[] | string[];
    labels?: {
      groupedOptions?: {
        rotation?: number;
      }[];
    };
  };
}

export type ChartProps = {
  options: ChartWithGroupedCategoriesOptions;
  containerClassName?: string;
};

export type ChartWrapper = {
  chart: Highcharts.Chart | null;
  containerRef: MutableRefObject<HTMLDivElement | null>;
};

export const hasChartField = (chartWrapper: any): chartWrapper is { chart: Highcharts.Chart | null } =>
  chartWrapper && 'chart' in chartWrapper && chartWrapper.chart instanceof Highcharts.Chart;

export type ChartOptionsParams = {
  chart: ChartData;
  graphName: string;
  graphKey: string;
  graphDisplayType: GraphDisplayTypes;
  tUnits: TFunction;
  chartCustomName?: string | null;
  id?: string;
  isFixed?: boolean;
  showingValuesOnChart?: boolean;
  chartTypes?: ChartType;
  theme?: DefaultTheme;
  hiddenSeries?: string[];
  onToggleSeries?: (key: string, seriesName: string, visible?: boolean) => void;
  chartRef?: RefObject<Highcharts.Chart>;
  chartUnit?: string | null;
};
