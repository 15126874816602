export const getLogVsAdvance = (logVsAdvance: number): string => {
  if (logVsAdvance <= 0) {
    return `${logVsAdvance}`;
  }

  return `+${logVsAdvance}`;
};

export const getColor = (stageType: number) => {
  if (stageType === 1) {
    return 'linear-gradient(to left top, rgb(5, 105, 26), rgb(9, 92, 72))'; // Действующая
  }
  return 'linear-gradient(to left top, rgb(3, 63, 94), rgb(10, 35, 51))'; // Законченная
};
