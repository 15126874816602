import { Checkbox, Select } from 'antd';
import { OptionData } from 'rc-select/lib/interface';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { DEFAULT_FILTER_OPTION } from './SelectPicker.constants';
import { HeaderDivider, SelectHeader } from './SelectPicker.styled';
import { SelectProps } from './SelectPicker.types';

const SelectPicker: FC<SelectProps> = ({
  children,
  onChange,
  value,
  mode,
  defaultValue,
  placeholder,
  filterOption = DEFAULT_FILTER_OPTION,
  ...rest
}) => {
  const { t } = useTranslation('filters');

  if (mode === 'multiple') {
    return (
      <Select
        mode={mode}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        placeholder={placeholder}
        filterOption={filterOption}
        {...rest}
        dropdownRender={(allSelectValue) => (
          <div>
            <SelectHeader>
              <Checkbox
                onChange={() => {
                  const options = allSelectValue.props.options.filter(({ disabled }: OptionData) => !disabled);

                  onChange?.(
                    options.map((option: OptionData) => option.value),
                    options
                  );
                }}
              >
                {t('selectAll')}
              </Checkbox>

              <Checkbox onChange={() => onChange?.(undefined, allSelectValue.props.options)}>
                {t('deselectAll')}
              </Checkbox>
            </SelectHeader>
            <HeaderDivider />
            {allSelectValue}
          </div>
        )}
      >
        {children}
      </Select>
    );
  }

  return (
    <Select
      mode={mode}
      onChange={onChange}
      value={value}
      defaultValue={defaultValue}
      placeholder={placeholder}
      filterOption={filterOption}
      {...rest}
    >
      {children}
    </Select>
  );
};

export default SelectPicker;
