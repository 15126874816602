import { useCallback, useEffect, useRef } from 'react';

import { Debounce } from './useDebounce.types';

const useDebounce = (): Debounce => {
  const timer = useRef<number>();

  useEffect(() => {
    return () => {
      window.clearTimeout(timer.current);
    };
  }, []);

  return useCallback((callback, delay, immediately) => {
    if (!timer.current && immediately) {
      timer.current = window.setTimeout(callback, 0);
    } else {
      window.clearTimeout(timer.current);
      timer.current = window.setTimeout(callback, delay);
    }
  }, []);
};

export default useDebounce;
