import { AppStore } from '../types';

import { AnalyticsState } from './types';

const getState = (state: AppStore): AnalyticsState => state.analyticsReducer;

export const getFiltersState = (state: AppStore) => {
  const analyticsState = getState(state);
  return analyticsState.filters;
};
