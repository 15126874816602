import { AppStore } from '../types';

import { ReportsState } from './types';

const getState = (state: AppStore): ReportsState => state.reportsReducer;

export const getFiltersState = (state: AppStore) => {
  const reportsState = getState(state);
  return reportsState.filters;
};
