import moment, { Moment, unitOfTime } from 'moment';

import { getDefaultChartTypes } from 'src/constants/charts';
import {
  AnalyticsFilters,
  CommonFiltersValues,
  Filters,
  FiltersValues,
  Indicators,
  IndicatorsValues,
  ItemFilterTypes,
  RulesFilterTypes,
} from 'src/services/AnalyticsService/AnalyticsService.types';
import { GraphBlock, GraphFilterTypes } from 'src/services/PageService/PageService.types';

const deserializeDate = (value?: string): string | undefined => {
  if (!value) return;

  return moment(value, 'DD.MM.YYYY').format('YYYY-MM-DD');
};

export const serializeDate = (value?: string): string | undefined => {
  if (!value) return;

  const date = new Date(value);
  if (!(date instanceof Date) || isNaN(date.valueOf())) {
    return;
  }

  return moment(date).format('DD.MM.YYYY');
};

//TODO: value: any добавить тип
export const getFiltersValues = (
  filter: Filters,
  value: any,
  filtersValues: FiltersValues,
  indicator?: Indicators
): FiltersValues => {
  const values: CommonFiltersValues = {
    key: filter.key,
    filter_type: filter.item_type,
    key_datatype: filter.key_datatype,
    filter_rule_type: filter.rules[0],
  };

  if (filter.item_type === ItemFilterTypes.number && filter.rules.includes(RulesFilterTypes.single)) {
    values.value = value;
  }
  if (filter.item_type === ItemFilterTypes.number && filter.rules.includes(RulesFilterTypes.multiple)) {
    values.values_list = value;
  }
  if (filter.item_type === ItemFilterTypes.date && filter.rules.includes(RulesFilterTypes.less_or_equal)) {
    values.value = value;
  }
  if (filter.item_type === ItemFilterTypes.date && filter.rules.includes(RulesFilterTypes.interval)) {
    values.value_from = value[0];
    values.value_to = value[1];
  }
  if (filter.item_type === ItemFilterTypes.object && filter.rules.includes(RulesFilterTypes.multiple)) {
    values.values_list = value;
  }
  if (filter.item_type === ItemFilterTypes.string && filter.rules.includes(RulesFilterTypes.multiple)) {
    values.values_list = value;
  }
  if (filter.item_type === ItemFilterTypes.object && !filter.rules.includes(RulesFilterTypes.multiple)) {
    values.value = value;
  }

  if (indicator) {
    values.keyIndicator = indicator.key;
  }

  const filteredValues =
    filtersValues.filters?.filter(
      (itemFilter: CommonFiltersValues) =>
        (itemFilter.keyIndicator && itemFilter.keyIndicator !== indicator?.key) || itemFilter.key !== filter.key
    ) || [];

  const indicatorValue = filtersValues.indicators?.find(({ key }) => key === indicator?.key);
  const updatedIndicatorsValues =
    indicator && !indicatorValue
      ? [
          ...(filtersValues?.indicators || []),
          {
            key: indicator.key,
            display_name: indicator.display_name,
            filters: values,
            chart_type: indicator.chart_type || getDefaultChartTypes(indicator.key).defaultChartType,
          },
        ]
      : filtersValues.indicators;

  return { ...filtersValues, filters: [...filteredValues, values], indicators: updatedIndicatorsValues };
};

export const prepareFilters = (filters: CommonFiltersValues[]): CommonFiltersValues[] => {
  const prepareFilters = filters.map((filter: CommonFiltersValues) => {
    const resultFilter: any /* TODO: any добавить тип */ = {
      key: filter.key,
      filter_rule_type: filter.filter_rule_type,
      filter_type: filter.filter_type,
      key_datatype: filter.key_datatype,
    };

    if (filter.keyIndicator) {
      resultFilter.keyIndicator = filter.keyIndicator;
    }

    if (filter.filter_type === ItemFilterTypes.date && filter.filter_rule_type === RulesFilterTypes.less_or_equal) {
      resultFilter.value = deserializeDate(filter.value);
    }

    if (filter.filter_type === ItemFilterTypes.date && filter.filter_rule_type === RulesFilterTypes.interval) {
      resultFilter.value_from = deserializeDate(filter.value_from);
      resultFilter.value_to = deserializeDate(filter.value_to);
    }

    if (filter.filter_type === ItemFilterTypes.number && filter.filter_rule_type === RulesFilterTypes.single) {
      resultFilter.value = filter.value;
    }

    resultFilter.values_list = filter.values_list || [];

    return resultFilter;
  });
  const prepareFiltersNoEmptyValues = prepareFilters.filter(
    (filter: CommonFiltersValues) => filter.value || filter.value_from || filter.values_list?.length
  );
  return prepareFiltersNoEmptyValues || [];
};

export const prepareDataGraphs = (filtersValues: FiltersValues): GraphBlock[] => {
  if (filtersValues.indicators && filtersValues.indicators.length > 0) {
    const graphs: GraphBlock[] = filtersValues.indicators.map((indicator: IndicatorsValues) => {
      const graph: any /* TODO: any добавить тип */ = {
        key: indicator.key,
        display_name: indicator.display_name,
      };

      if (indicator.axes) {
        graph.axis = {
          ...graph.axis,
          key: indicator.axes,
        };
        // TODO: пока нельзя заменить, ts не находит key
        // graph.axis.key = indicator.axes;
      }

      if (indicator.axis_groups) {
        graph.axis.axis_group_key = indicator.axis_groups;
      }

      graph.group = indicator.groupFullData || null;

      if (indicator.is_cumulative) {
        graph.is_cumulative = indicator.is_cumulative;
      }

      graph.filters = [];

      if (filtersValues.filters && filtersValues.filters.length > 0) {
        const filters = prepareFilters(filtersValues.filters);
        const commonFilters = filters.filter((common) => common?.keyIndicator === undefined);
        const indicatorFilters = filters.filter((ind) => ind?.keyIndicator && ind.keyIndicator === indicator.key);

        if (indicatorFilters) {
          graph.filters = [...commonFilters, ...indicatorFilters];
        } else {
          graph.filters = [...commonFilters];
        }
      }

      if (indicator?.purpose_infos) {
        graph.purpose_infos = indicator.purpose_infos?.map((key) => ({ key }));
      }

      if (indicator?.chart_type) {
        graph.chart_type = indicator.chart_type;
      }

      return {
        name: indicator.display_name,
        is_fixed: false,
        graph: { ...graph },
        layout_id: '0',
      };
    });

    return [...graphs, ...(filtersValues.fixedGraphs || [])];
  }

  return filtersValues.fixedGraphs || [];
};

export const parseDataToFilters = (graphs: GraphBlock[], analyticsFilters: AnalyticsFilters | null): FiltersValues => {
  const fixedGraphs = graphs.filter((item) => item.is_fixed);
  const notFixedGraphs = graphs.filter((item) => !item.is_fixed);

  const indicators = notFixedGraphs.map((itemGraph: GraphBlock) => {
    return {
      display_name: itemGraph.name,
      key: itemGraph.graph.key,
      axes: itemGraph.graph.axis?.key || '',
      axis_groups: itemGraph.graph.axis?.axis_group_key,
      groupFullData: itemGraph.graph.group,
      group: itemGraph.graph.group,
      is_cumulative: itemGraph.graph.is_cumulative,
      chart_is_cumulative: itemGraph.data?.charts?.[0]?.is_cumulative,
      purpose_infos: itemGraph.graph.purpose_infos?.map(({ key }) => key),
      chart_type: itemGraph.graph.chart_type,
    };
  });

  const parsedFilters = notFixedGraphs.reduce((acc: CommonFiltersValues[], graph) => {
    return graph.graph?.filters?.reduce((filterAcc: CommonFiltersValues[], filterItem) => {
      const indicator = analyticsFilters?.indicators?.find((item) => item.key === graph.graph?.key);
      const indicatorFilter = indicator?.filters?.find((item) => item.key === filterItem.key);
      const findedFilter = acc.find((item) => item.key === filterItem.key);

      const updatedFilter = {
        ...filterItem,
        value_from: filterItem.filter_type === GraphFilterTypes.date ? serializeDate(filterItem.value_from) : undefined,
        value_to: filterItem.filter_type === GraphFilterTypes.date ? serializeDate(filterItem.value_to) : undefined,
      };

      if (filterItem.filter_type === GraphFilterTypes.number) {
        updatedFilter.value = filterItem.value;
      } else if (filterItem.filter_type === GraphFilterTypes.date) {
        updatedFilter.value = serializeDate(filterItem.value);
      } else {
        updatedFilter.value = undefined;
      }

      if (indicatorFilter) {
        return [...filterAcc, { ...updatedFilter, keyIndicator: graph.graph.key }];
      } else if (!findedFilter) {
        return [...filterAcc, updatedFilter];
      }

      return filterAcc;
    }, acc);
  }, []);

  return {
    fixedGraphs,
    filters: parsedFilters,
    indicators: indicators,
  };
};

export const setCurrentDateToChartFilters = (graphs: GraphBlock[]) => {
  const newGraphs: GraphBlock[] = JSON.parse(JSON.stringify(graphs));

  newGraphs.map((graph) => {
    return graph.graph.filters.map((filter) =>
      filter.filter_rule_type === 'less_or_equal' && filter.filter_type === 'date'
        ? (filter.value = moment().format('yyyy-MM-DDT00:00:00'))
        : filter.value
    );
  });

  return newGraphs;
};

export const isPeriodExact = (start: Moment, end: Moment, period: unitOfTime.StartOf) => {
  const startOfQuarter = moment(start)?.startOf(period);
  const endOfQuarter = moment(start)?.endOf(period);

  return !moment(startOfQuarter).diff(start, 'days') && !moment(endOfQuarter).diff(end, 'days');
};
