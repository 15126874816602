import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;

  .highcharts-background {
    fill: ${({ theme }) => theme.chart.background};
  }

  .highcharts-axis text {
    color: ${({ theme }) => theme.colors.text} !important;
    fill: ${({ theme }) => theme.colors.text} !important;
  }

  .highcharts-axis-labels > text {
    fill: ${({ theme }) => theme.colors.text} !important;
  }

  .highcharts-title {
    color: ${({ theme }) => theme.colors.text} !important;
    fill: ${({ theme }) => theme.colors.text} !important;
  }

  .highcharts-legend-item > text {
    fill: ${({ theme }) => theme.colors.text} !important;
  }
`;
