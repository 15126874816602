import { Tabs, TabsProps } from 'antd';
import styled from 'styled-components';

type PageTabsProps = TabsProps & { showTabsNav: boolean };

export const PagesTabs = styled(Tabs)<PageTabsProps>`
  width: 100%;
  overflow: unset;

  .ant-tabs-nav {
    display: ${({ showTabsNav }) => !showTabsNav && 'none'};
    height: 48px;

    margin: 0;
    ::before {
      border: none;
    }
    .ant-tabs-nav-add,
    .ant-tabs-nav-more {
      font-size: 20px;
      font-weight: bold;
      border: none;
      background: ${({ theme }) => theme.tabs.background};
      color: ${({ theme }) => theme.tabs.colorActive} !important;
      border-radius: 0;
    }

    .ant-tabs-nav-more {
      padding: 6px 12px;
      background-color: ${({ theme }) => theme.tabs.border};
    }
  }

  &.ant-tabs-card > .ant-tabs-nav .ant-tabs-tab,
  &.ant-tabs-card > div > .ant-tabs-nav .ant-tabs-tab {
    padding: 8px 12px;
    background-color: ${({ theme }) => theme.tabs.background};
    border-color: ${({ theme }) => theme.tabs.border};
    color: ${({ theme }) => theme.tabs.color};
    margin-left: 0 !important;
    border-radius: 0;
    border: 0;
  }

  &.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
  &.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
    background-color: ${({ theme }) => theme.tabs.backgroundActive};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${({ theme }) => theme.tabs.colorActive};
    text-shadow: none;
  }

  .ant-tabs-tab-btn {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 130px;
    overflow: hidden;
    font-size: 16px;
    text-align: center;
  }

  .ant-tabs-tab-remove {
    color: ${({ theme }) => theme.tabs.color};
  }

  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-remove {
    color: ${({ theme }) => theme.tabs.colorActive};
  }

  .ant-tabs-content {
    height: 100%;
  }

  @media (max-width: 768px) {
    .ant-tabs-nav {
      padding: 0 5px;
    }
  }
`;
