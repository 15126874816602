import { OptionsType, OptionData, OptionGroupData } from 'rc-select/lib/interface';

import { SelectOptions } from 'src/components/Fields/Fields.types';
import { IndicatorsValues, Filters } from 'src/services/AnalyticsService/AnalyticsService.types';
import { FiltersValues, Indicators } from 'src/services/AnalyticsService/AnalyticsService.types';
import { FrontendValue, GraphDisplayTypes } from 'src/services/PageService/PageService.types';
import { ValueOf } from 'src/types';

export enum DisplayType {
  static = 'static',
  dynamic = 'dynamic',
}

export enum ChartType {
  line = 'line',
  column = 'column',
  stack = 'stack',
  pie = 'pie',
  waterfall = 'waterfall',
}

export const isChartType = (chartType: any): chartType is ChartType => Object.values(ChartType).includes(chartType);

export type IndicatorsFiltersFormProps = {
  filtersValues: FiltersValues;
  setFiltersValues: (filters: FiltersValues) => void;
  onResetIndicators: VoidFunction;
  onChangeDisplayType: (key: string | undefined, type: DisplayType, periodUpdate?: string | null | undefined) => void;
  displayTypes?: FrontendValue<GraphDisplayTypes>[];
  indicators?: Indicators[];
  isLoading?: boolean;
  onFiltersChange?: (filters: FiltersValues) => void;
};

export type GroupOptionsType = {
  children: string;
  key: string;
  value: string;
};

export type AxesDataType = {
  axesName: string;
  axes: string;
};

export type ChangeIndicatorsValuesType = (
  indicator: Indicators,
  key: keyof Omit<IndicatorsValues, 'key' | 'display_name'>,
  value: ValueOf<IndicatorsValues> | number,
  groupOptions?: OptionsType | OptionData | OptionGroupData
) => void;

export type IndicatorsFiltersFormItemProps = {
  indicator: Indicators;
  displayTypeOptions: SelectOptions[];
  chartTypeOptions: SelectOptions[];
  displayType: DisplayType;
  axisGroupDate?: string | null;
  onChangeFiltersValues: (filter: Filters, value: any /* TODO: any добавить тип */, indicator?: Indicators) => void;
  onChangeIndicatorsValues: ChangeIndicatorsValuesType;
  itemIndicatorsValues?: IndicatorsValues;
} & Pick<IndicatorsFiltersFormProps, 'filtersValues' | 'onChangeDisplayType' | 'isLoading'>;

export type IndicatorFiltersProps = { selectedAxisName?: string } & Pick<
  IndicatorsFiltersFormItemProps,
  | 'displayType'
  | 'itemIndicatorsValues'
  | 'filtersValues'
  | 'indicator'
  | 'onChangeFiltersValues'
  | 'axisGroupDate'
  | 'isLoading'
>;
