import { CommonFiltersValues } from 'src/services/AnalyticsService/AnalyticsService.types';
import { ReportFields } from 'src/services/ReportsService/ReportsService.types';
import { ValueOf } from 'src/types';

export interface ValueGetter<T extends ValueOf<ReportFields>> {
  (filter: CommonFiltersValues): T;
}

export enum FiltersKeys {
  date = '6d6d310b-30a3-41e9-862e-76886205c2e4',
  operatorDivs = '635e94be-81b5-4cd3-af5b-ef7ac3fa0a08',
  blocks = '39abab9c-26c9-4495-b1c3-73212e45922f',
  fields = 'db6705f5-144b-4bdd-9244-5fee9ea2ecfa',
  pads = '5573e456-9362-4314-8d8c-5501a1faf832',
  wellIds = '585768f3-6cae-4821-bae5-db5988fe1711',
  wellTypes = 'e39a42b4-1556-4f96-8909-dde7f966cee9',
  purposes = 'af088a3d-4e2a-472e-98c8-5ec8b717c52d',
  drillingRigs = 'dc714831-3b14-478d-b30e-98752e7b218f',
  fluids = '98e33c0c-5215-4858-b56e-12fa52540aa6',
  wellActivities = 'bd18e3b9-ebeb-44e5-a745-bc112f7dd638',
}

export const DATE_FORMAT = 'YYYY-MM-DD';
export const FILTERS_DATE_FORMAT = 'DD.MM.YYYY';
