import 'moment/locale/ru';
import { Select } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOptions } from 'src/components/Fields/Fields.types';
import SelectPicker from 'src/components/SelectPicker';
import { SortingBy } from 'src/services/ReportsService/ReportsService.types';

import { FieldWrapper, FormWrapper } from './SortingsForm.styled';
import { SortingsFormProps } from './SortingsForm.types';

const SortingsForm: FC<SortingsFormProps> = ({ onChangeSortingValue, sorting, withFiltersInPanel }) => {
  const { t } = useTranslation('filters');

  const options: SelectOptions[] = [
    {
      title: t('sortingOptions.filterIsNotApplied'),
      value: SortingBy.without,
    },
    {
      title: t('sortingOptions.timeVsDepthDeviation'),
      value: SortingBy.log_vs_advance,
    },
    {
      title: t('sortingOptions.dailyNpt'),
      value: SortingBy.npt_hours,
    },
  ];

  const onChangeSortingsValues = (value: string | number | undefined) => {
    if (value && Object.values(SortingBy).includes(value as SortingBy)) {
      onChangeSortingValue(value as SortingBy);
    }
  };

  return (
    <FormWrapper padding={withFiltersInPanel ? '0 30px 25px 30px' : undefined}>
      <FieldWrapper>
        <label>{t('sorting')}</label>
        <SelectPicker onChange={(value) => onChangeSortingsValues(value)} defaultValue={sorting}>
          {options.map((option: SelectOptions) => (
            <Select.Option value={option.value} key={option.value}>
              {option.title}
            </Select.Option>
          ))}
        </SelectPicker>
      </FieldWrapper>
    </FormWrapper>
  );
};

export default SortingsForm;
