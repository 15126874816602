import { AnyFrontendValue, Frontend, FrontendValue, GraphBlock } from 'src/services/PageService/PageService.types';

export const sortGraphsByFixed = (graphs?: GraphBlock[]): GraphBlock[] => {
  if (!graphs) {
    return [];
  }

  return graphs.sort((a) => {
    if (a.is_fixed) {
      return -1;
    }

    if (!a.is_fixed) {
      return 1;
    }

    return 0;
  });
};

export const clearFrontend = (graphs: GraphBlock[], frontend?: Frontend): Frontend => {
  if (!graphs.length || !frontend) {
    return {};
  }

  const graphKeys = graphs.map(({ graph }) => graph.key);
  // Graph layout id + graph key
  const graphFullKeys = graphs.map(({ graph, layout_id }) => `${layout_id}-${graph.key}`);

  const clearSettings = (field: keyof Frontend, existingGraphsKeys: string[]): FrontendValue<any>[] | undefined =>
    (frontend?.[field] as Array<AnyFrontendValue>)?.filter(({ key }) => existingGraphsKeys.includes(key));

  return {
    ...frontend,
    graph_display_types: clearSettings('graph_display_types', graphKeys),
    chart_types: clearSettings('chart_types', graphFullKeys.concat(graphKeys)),
    showing_values_on_chart: clearSettings('showing_values_on_chart', graphKeys),
    report_wells_sorting: clearSettings('report_wells_sorting', graphKeys),
    hidden_chart_series: clearSettings('hidden_chart_series', graphFullKeys),
  };
};

export const getIsGroupedCategoriesCharts = (graphs: GraphBlock[]) => {
  return Object.fromEntries(
    graphs.map(({ data, layout_id, graph: { key } }) => [
      `${layout_id}-${key}`,
      typeof data?.charts?.[0]?.argument_axes[0]?.categories[0] !== 'string',
    ])
  );
};
