import { Collapse } from 'antd';
import styled from 'styled-components';

export const CollapseWrapper = styled(Collapse)`
  &.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    color: ${({ theme }) => theme.filters.text};
  }
  &.ant-collapse-icon-position-right > .ant-collapse-item > .ant-collapse-header {
    padding: 15px 32px 15px 16px;
    font-size: 16px;
    line-height: 28px;
    letter-spacing: 0.02em;
    color: ${({ theme }) => theme.filters.text};
  }
`;

export const Panel = styled(Collapse.Panel)`
  h4 {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    margin: 0 0 6px;
    color: ${({ theme }) => theme.filters.text};
  }
  h5 {
    font-size: 14px;
    line-height: 17px;
    letter-spacing: 0.02em;
    margin: 6px 0;
    color: ${({ theme }) => theme.filters.text};
  }

  .ant-collapse-content {
    color: ${({ theme }) => theme.filters.text};

    > .ant-collapse-content-box {
      padding-left: 30px;
      padding-right: 30px;
    }
  }

  .ant-select {
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.input.color};

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: ${({ theme }) => theme.input.background};
        border: 0;
      }
    }

    &-arrow {
      color: ${({ theme }) => theme.input.color};
    }

    &-dropdown {
      background: ${({ theme }) => theme.input.background};
    }
    &.ant-select-single {
      border: 0;
      outline: none;
      box-shadow: none;
    }
  }

  .ant-picker,
  .ant-select-multiple {
    width: 100%;
    color: ${({ theme }) => theme.input.color};
    background: ${({ theme }) => theme.input.background};
    border: 0;
  }

  .ant-picker-input > input,
  .ant-picker-suffix {
    color: ${({ theme }) => theme.input.color};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ theme }) => theme.input.background};
    border: 0;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${({ theme }) => theme.filters.selection};
    color: ${({ theme }) => theme.input.color};
    border: 0;
    align-items: center;
  }

  .dynamic {
    margin-top: 6px;
  }

  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker-focused {
    box-shadow: none;
  }
`;

export const Indicator = styled.div`
  padding: 6px 0;

  > span {
    margin-left: 10px;
  }
`;

export const ResetFilters = styled.div`
  display: flex;
  justify-content: center;
  padding: 10px;

  .ant-btn {
    border: 0;
    background: transparent;
    color: ${({ theme }) => theme.reset.color};
    box-shadow: none;

    &:hover {
      color: ${({ theme }) => theme.reset.colorHover};
    }
  }
`;
