import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { AllApplicationsIcon } from 'src/assets/icons';
import { Language } from 'src/components';
import { GOHUB_REDIRECT_URL } from 'src/constants';
import { ICard } from 'src/services/AuthService';
import { getToken, removeToken, returnField } from 'src/utils';

import {
  DrawerWrapper,
  LanguageWrapper,
  UserInfoWrapper,
  AvatarImg,
  AvatarIcon,
  UserName,
  UserPosition,
  Nav,
  NavItem,
  AppIcon,
  EmptyApps,
  GoHubLink,
  Logout,
} from './NavMobile.styled';
import { NavMobileProps } from './NavMobile.types';

const NavMobile: FC<NavMobileProps> = ({ isVisible, person, products, onClose }) => {
  const { t } = useTranslation('header');
  const jwtToken = getToken();

  const { avatar, position, first_name: firstName, last_name: lastName } = person || {};

  const onLogout = () => {
    removeToken();
    window.location.href = GOHUB_REDIRECT_URL;
  };

  return (
    <DrawerWrapper placement="right" onClose={onClose} visible={isVisible}>
      <div>
        <LanguageWrapper>
          <Language />
        </LanguageWrapper>
        <UserInfoWrapper>
          {avatar ? (
            <AvatarImg src={avatar} alt="avatar" />
          ) : (
            <AvatarIcon>
              <UserOutlined />
            </AvatarIcon>
          )}
          <UserName>
            {firstName || ''} {lastName || ''}
          </UserName>
          <UserPosition>{position || ''}</UserPosition>
        </UserInfoWrapper>
        <div>
          {products && products?.length > 0 ? (
            <Nav>
              {products.map((productItem: ICard) => {
                const url = productItem.auth_url ? `${productItem.auth_url}${jwtToken}` : productItem.url;
                return (
                  <NavItem href={url} target="_blank" rel="noopener noreferrer" key={`mob-${productItem.id}`}>
                    <AppIcon src={productItem.logo} alt={returnField(productItem.name_ru, productItem.name_en)} />
                    {returnField(productItem.name_ru, productItem.name_en)}
                  </NavItem>
                );
              })}
            </Nav>
          ) : (
            <EmptyApps>{t('emptyApps')}</EmptyApps>
          )}
          <GoHubLink href={GOHUB_REDIRECT_URL} target="_blank" rel="noopener noreferrer">
            <AllApplicationsIcon /> {t('goToGOhub')}
          </GoHubLink>
        </div>
      </div>
      <Logout onClick={onLogout}>
        <LogoutOutlined /> {t('nav.exit')}
      </Logout>
    </DrawerWrapper>
  );
};

export default NavMobile;
