import { AppStore } from '../types';

import { UserState } from './types';

const getState = (state: AppStore): UserState => state.userReducer;

export const getIsAuthorizedUser = (state: AppStore) => {
  const userState = getState(state);
  return userState.isAuthorized;
};

export const getIsSuperuser = (state: AppStore) => {
  const userState = getState(state);
  return userState.isSuperuser;
};

export const getUserCanOpenReports = (state: AppStore) => {
  const userState = getState(state);
  return userState.canOpenReports;
};
