import Highcharts, { Point, Series, SeriesOptionsType, SVGElement, SVGRenderer } from 'highcharts';
import moment from 'moment';
import { DependencyList, RefObject, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { DefaultTheme } from 'styled-components';

import { useForceUpdate } from 'src/hooks';
import i18n from 'src/i18n';
import {
  CategoryData,
  ChartData,
  GraphBlock,
  GraphDisplayTypes,
  SeriesData,
} from 'src/services/PageService/PageService.types';
import { getDynamicPeriodValues, getLocaleDate, showErrorMessage } from 'src/utils';

import { getChartLabelHash } from '../../utils/chart';
import { ChartType, DisplayType } from '../forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';

import { getChartOptionsGetter } from './Chart.constants';
import { ChartOptionsParams, ChartWithGroupedCategoriesOptions, hasChartField } from './Chart.types';
import LabelsMatrix from './DataLabelsPlacement/LabelsMatrix';
import { Position } from './DataLabelsPlacement/types';

export type Drawer = {
  drawLabel: (str: string, x: number, y: number, color?: string, fontSize?: number, invert?: boolean) => SVGElement;
  drawVerticalLine: (x: number, y1: number, y2: number, color?: string) => SVGElement;
  drawLine: (x1: number, y1: number, x2: number, y2: number, color?: string) => SVGElement;
};

type PointWithPosition = {
  point: Point;
  position: { x: number; y: number };
  style?: {
    color?: string;
    fontSize?: number;
  };
};

type PointWithParent = {
  point: PointWithPosition;
  parent: PointWithPosition;
};

type GroupedPoints = Record<string, PointWithPosition[]>;

type ChartOptionsHookParams = {
  chart: ChartData;
  graph: GraphBlock;
  theme: DefaultTheme;
  chartHiddenSeries: string[];
  chartRef: RefObject<Highcharts.Chart>;
  showingValuesOnChart: boolean;
  chartCustomName?: string | null;
  chartType: ChartType;
  chartUnit?: string | null;
  onToggleChartSeries?: (key: string, seriesName: string, visible?: boolean | undefined) => void;
};

type AnalyticsChartOptionsHookParams = ChartOptionsHookParams & {
  displayType: DisplayType;
  updatePeriod?: string | null;
};

type ChartCategories = (CategoryData | string)[];

const MIN_CATEGORIES_LENGTH = 5;

const LABEL_OFFSET = 18;
const CONNECTED_LINE_OFFSET = 50;
const DATA_LABEL_HEIGHT = 15;
const RESERVED_ROWS_MAX_LENGTH = 4;

const LINE_DATA_LABELS_FONT_SIZE = 10;

const DEFAULT_LABEL_COLOR = '#1e1e1e';
const DEFAULT_LINE_COLOR = '#b6b6b6';

const isNotGroupedCategories = (categories: any[]) => {
  return categories.flat().every((category) => !('categories' in category));
};

export const getCategoriesOptions = (
  chart: ChartData,
  chartType?: ChartType
): { categories: ChartCategories; isGroupedCategories: boolean } => {
  const isWaterfall = chartType === ChartType.waterfall;
  let isGroupedCategories = !isWaterfall;

  const categories = chart.argument_axes.map(({ categories }) => {
    if (isGroupedCategories && typeof categories[0] === 'string') {
      isGroupedCategories = false;
    }

    return categories;
  });

  if (isWaterfall) {
    return { categories: categories.flat(), isGroupedCategories };
  }

  if (!isGroupedCategories) {
    const categoriesFromData: string[] = [];

    chart.series.forEach((item) => {
      item.data.forEach((data) => {
        categoriesFromData.push(getLocaleDate(data));
      });
    });

    return { categories: categoriesFromData, isGroupedCategories };
  }

  const categoriesFromData: CategoryData[] = [];
  const addedCategoriesNames = new Set<string>();

  // Get categories on first level (like month, quarter, day; bottom row) from chart data
  // and receive corresponding second level (top row) categories from categories data.
  chart.series.forEach((item) => {
    item.data.forEach((data) => {
      const foundCategory = categories.flat().find((category) => (category as CategoryData)?.name === data.key);

      if (!addedCategoriesNames.has(data.key)) {
        categoriesFromData.push({
          name: getLocaleDate(data),
          categories: typeof foundCategory === 'string' ? [foundCategory] : foundCategory?.categories || [''],
        });

        addedCategoriesNames.add(data.key);
      }
    });
  });

  return { categories: categoriesFromData, isGroupedCategories };
};

const getYAxisIndex = (chart: ChartData, seriesItem: SeriesData): number => {
  const foundIndex = chart.value_axes.findIndex((axis) => {
    return axis.display_name === seriesItem.value_axis_display_name;
  });

  if (!~foundIndex) {
    return 0;
  }

  return foundIndex;
};

const getDrawer = (renderer: SVGRenderer, labelClass?: string): Drawer => ({
  drawLabel: (str, x, y, color = DEFAULT_LABEL_COLOR, fontSize = 10, invert = false) => {
    const label = renderer
      .label(str, x, y)
      .css({
        color: invert ? '#fff' : color,
        fontWeight: 'bold',
        textAlign: 'center',
        fontSize: `${fontSize}px`,
      })
      .attr({
        fill: invert ? color : 'transparent',
      })
      .addClass(labelClass || '')
      .add()
      .toFront();

    const bbox = label.getBBox();

    label.align({ x: x - bbox.width / 2, y: y });

    return label;
  },
  drawVerticalLine: (x, y1, y2, color = DEFAULT_LINE_COLOR) => {
    return renderer
      .path([
        ['M', x, y1 + LABEL_OFFSET],
        ['L', x, y2 + CONNECTED_LINE_OFFSET],
      ])
      .attr({
        'stroke-width': 1,
        stroke: color,
      })
      .addClass(labelClass || '')
      .add();
  },
  drawLine: (x1, y1, x2, y2, color = DEFAULT_LINE_COLOR) => {
    return renderer
      .path([
        ['M', x1, y1],
        ['L', x2, y2],
      ])
      .attr({
        'stroke-width': 1,
        stroke: color,
      })
      .addClass(labelClass || '')
      .add();
  },
});

const groupPointsByCategory = (series: Series[], range?: { min: number; max: number }) => {
  return series.reduce((groups: GroupedPoints, currentSeries: Series) => {
    if (currentSeries?.visible) {
      currentSeries.data
        .slice(Math.round(range?.min || 0), Math.ceil((range?.max || currentSeries.data.length) + 1))
        .forEach((currentData: Point) => {
          const point: PointWithPosition = {
            point: currentData,
            position: {
              x: currentSeries.xAxis?.toPixels(currentData.x, true),
              y: currentSeries.yAxis?.toPixels(Number(currentData.y), true),
            },
          };

          // Filter zero-values
          if (point.point.y?.toString()) {
            if (groups?.[currentData.category]) {
              groups[currentData.category].push(point);
            } else {
              groups[currentData.category] = [point];
            }
          }
        });
    }

    return groups;
  }, {});
};

const groupSeriesByType = (series: Series[]): Record<string, Series[]> => {
  return series.reduce((groups: Record<string, Series[]>, singleSeries: Series) => {
    const type = singleSeries.type;

    return {
      ...(groups || {}),
      [type]: [...(groups[type] || []), singleSeries],
    };
  }, {});
};

const sortPointsVertically = <T extends { position: { y: number } }>(points: T[]) =>
  points.sort((first, second) => Number(first.position.y) - Number(second.position.y));

const drawLabelsAround = (
  chart: Highcharts.Chart,
  groupedSeries: GroupedPoints,
  plotLeft: number,
  plotWidth: number,
  plotHeight: number,
  drawer: Drawer,
  unavailablePositions?: Position[]
) => {
  const labelOffsetTop = 40;
  const labelOffsetBottom = 20;

  const _groupedSeriesValues = Object.values(groupedSeries);
  const isSingleSeries = Number(_groupedSeriesValues[0]?.length) === 1;
  const allPoints = _groupedSeriesValues.flat();
  const categoryGroups = isSingleSeries ? [allPoints] : _groupedSeriesValues;

  const matrix = new LabelsMatrix([
    chart?.plotTop || 0,
    plotWidth + plotLeft,
    plotHeight + (chart?.plotTop || 0),
    plotLeft,
  ]);

  if (unavailablePositions) {
    matrix.setUnavailablePositions(unavailablePositions, [
      [false, false, false, false],
      [false, false, false, false],
      [true, true, true, true],
      [true, true, true, true],
      [true, true, true, true],
      [true, true, true, true],
    ]);
  }

  // TODO: set line coordinates as unavailable
  // matrix.setUnavailablePositions();

  categoryGroups.forEach((points) => {
    const sortedPoints = sortPointsVertically(points);

    sortedPoints.forEach((positionedPoint, index) => {
      if (!positionedPoint.point.y?.toString()) return;

      const simplePoint = {
        position: positionedPoint.position,
        label: positionedPoint.point.y.toString() || '',
        color: positionedPoint.point.color?.toString(),
        isTop: isSingleSeries ? false : index === 0,
        isBottom: isSingleSeries ? false : index === sortedPoints.length - 1,
      };

      const yPosition = simplePoint.isBottom
        ? simplePoint.position.y + labelOffsetBottom
        : simplePoint.position.y - labelOffsetTop;

      const labelPoint = {
        ...simplePoint,
        position: {
          ...simplePoint.position,
          y: yPosition > plotHeight ? plotHeight : yPosition < 0 ? 0 : yPosition,
        },
        parentPosition: simplePoint.position,
      };

      matrix.setUnavailablePositions(simplePoint.position);
      matrix.addPoint(labelPoint);
    });
  });

  matrix.matrix.forEach((row, rowNumber) => {
    row.forEach((cell, columnNumber) => {
      if (cell.isBusy && cell.point) {
        const { x, y } = cell.alignedPosition || matrix.toRealCoordinates(columnNumber, rowNumber);

        drawer.drawLine(x, y, cell.point.parentPosition.x + plotLeft, cell.point.parentPosition.y + chart?.plotTop);
        drawer.drawLabel(cell.point.label || '', x, y, cell.point.color, LINE_DATA_LABELS_FONT_SIZE, true);
      }
    });
  });
};

const placeLabelsInRows = (groupedSeries: GroupedPoints, plotLeft: number, plotWidth: number): PointWithParent[] => {
  const reservedRows: number[] = [];
  const _groupedSeriesValues = Object.values(groupedSeries);
  const isSingleSeries = Number(_groupedSeriesValues[0]?.length) === 1;
  const allPoints = _groupedSeriesValues.flat();
  const series = isSingleSeries ? [allPoints] : _groupedSeriesValues;

  const lastSeries = series?.[series.length - 1];
  const lastSeriesElement = lastSeries?.[lastSeries.length - 1];
  const lastSeriesPosition = lastSeriesElement?.position?.x || plotWidth;

  const reservedRowsMaxLength = getMaxReservedRowsNumber(lastSeriesPosition, allPoints.length);

  const minValue = 0;
  const maxValue = Math.max(
    ...allPoints.map((point: PointWithPosition) => point.position.y).filter((value) => value !== null || value > 0)
  );

  const rowsNumber = Math.floor((maxValue - minValue) / DATA_LABEL_HEIGHT);
  const placedPoints: { parent: PointWithPosition; labelPosition: { x: number; y: number; rowNumber: number } }[] = [];
  let maxReservedRow = 0;
  let isSmallHeight = false;

  const maxCalculationFunction = isSingleSeries ? Math.max : Math.min;

  series.forEach((points: PointWithPosition[]) => {
    const maxInGroup = maxCalculationFunction(
      ...points.map((point: PointWithPosition) => point.position.y).filter((value) => value !== null || value > 0)
    );

    points.forEach((positionedPoint: PointWithPosition) => {
      const rowNumber = Math.floor((maxValue - Math.min(positionedPoint.position.y, maxInGroup)) / DATA_LABEL_HEIGHT);

      if (rowNumber || rowNumber === 0) {
        // TODO: remove 'any'. Highcharts does not allow calculate X coordinate for each column,
        //  it can calculate only X coordinate for group of category.
        //  'barX' is X coordinate of column.
        const x =
          ((positionedPoint.point as any)?.barX || positionedPoint.position.x) +
          plotLeft +
          ((positionedPoint.point as any)?.shapeArgs?.width / 2 || 0);
        let y1: number;
        let y2: number;

        if (!reservedRows.includes(rowNumber)) {
          y1 = (rowsNumber - rowNumber) * DATA_LABEL_HEIGHT;
          y2 = positionedPoint.position.y;

          if (reservedRows.length === reservedRowsMaxLength) {
            reservedRows.shift();
          }

          reservedRows.push(rowNumber);
          maxReservedRow = maxReservedRow >= rowNumber ? maxReservedRow : rowNumber;

          if (y2 > 0 && y1 > 0) {
            placedPoints.push({
              parent: positionedPoint,
              labelPosition: {
                x,
                y: y1,
                rowNumber,
              },
            });
          }
        } else {
          let index = 0;

          do {
            index++;
          } while (reservedRows.includes(rowNumber + index));

          y1 = (rowsNumber - rowNumber - index) * DATA_LABEL_HEIGHT;
          y2 = positionedPoint.position.y;

          if (reservedRows.length === reservedRowsMaxLength) {
            reservedRows.shift();
          }

          reservedRows.push(rowNumber + index);
          maxReservedRow = maxReservedRow >= rowNumber + index ? maxReservedRow : rowNumber + index;

          if (y2 > 0 && y1 > 0) {
            placedPoints.push({
              parent: positionedPoint,
              labelPosition: {
                x,
                y: y1,
                rowNumber: rowNumber + index,
              },
            });
          }
        }

        if (!isSmallHeight && y1 < DATA_LABEL_HEIGHT && y1 > 0) {
          isSmallHeight = true;
        }
      }
    });
  });

  const labelsHeight = maxReservedRow * DATA_LABEL_HEIGHT;
  const dataLabelHeight = labelsHeight > maxValue ? Math.min(maxValue / maxReservedRow, 10) : DATA_LABEL_HEIGHT;

  return placedPoints.map(({ parent, labelPosition }) => {
    const { x, y: y1, rowNumber } = labelPosition;
    const {
      position: { y: y2 },
      point,
    } = parent;

    const newY = isSmallHeight ? y1 + (rowNumber + 1) * (DATA_LABEL_HEIGHT - dataLabelHeight) + 30 : y1;

    return {
      point: {
        point,
        position: { x, y: Math.min(newY, y2) },
        style: {
          color: isSingleSeries ? DEFAULT_LABEL_COLOR : point.color?.toString(),
          fontSize: isSmallHeight ? 10 : 12,
        },
      },
      parent: { point, position: { x, y: y2 } },
    };
  });
};

function getPreliminaryChartData(series: SeriesData[], plotHeight: number): number[][] {
  const clearSeries = series
    .map((serie) => serie.data.map(({ value }) => value).filter<number>((value): value is number => value !== null))
    .filter((serie) => serie.length > 0);

  if (clearSeries.length === 0) {
    return [];
  }

  const transposedSeries = clearSeries[0].map((_, ind) => clearSeries.map((serie) => serie[ind]));

  const maxValue = Math.max(...transposedSeries.map((val) => Math.max(...val)));

  const basis = maxValue > 0 ? plotHeight / maxValue : 0;

  return transposedSeries.map((val) => val.map((value) => plotHeight - basis * value));
}

function getMaxReservedRowsNumber(plotWidth: number, pointsNumber: number): number {
  const pointsDensity = plotWidth / pointsNumber;

  if (pointsDensity > 80) {
    return 1;
  }

  if (pointsDensity > 40) {
    return 2;
  }

  if (pointsDensity < 15) {
    return 6;
  }

  return RESERVED_ROWS_MAX_LENGTH;
}

/**
 * The function calculates the maxPadding value based on business data.
 * It's necessary so that maxPadding is defined before the chart is rendered
 */

const precalcMaxPadding = (
  seriesData: SeriesData[],
  plotHeight: number,
  plotWidth: number,
  graphDisplayType: GraphDisplayTypes
): number => {
  const formattedData = getPreliminaryChartData(seriesData, plotHeight);

  const newChartSeries: {
    value: number;
    type: string;
    key: string;
  }[][] = [];

  seriesData.forEach((series, index) => {
    const updatedPeriodValues = getDynamicPeriodValues(series.data, graphDisplayType.updatePeriod || '');
    if (updatedPeriodValues) {
      newChartSeries[index] = updatedPeriodValues.flat();
    }
  });

  const reservedRows: number[] = [];
  const categorySize = formattedData[0]?.length;

  if (!categorySize) {
    return 0;
  }

  const isSingleSeries = Number(categorySize) === 1;
  const allPoints = formattedData.flat();
  const series = isSingleSeries ? [allPoints] : formattedData;
  const seriesLength = newChartSeries.flat().length || allPoints.length;
  const reservedRowsMaxLength = getMaxReservedRowsNumber(plotWidth, seriesLength);

  const minValue = 0;
  const maxValue = Math.max(...allPoints.filter((value) => value !== null || value > 0));

  const rowsNumber = Math.floor((maxValue - minValue) / DATA_LABEL_HEIGHT);
  const placedPoints: {
    rowNumber: number;
    y: number;
  }[] = [];

  const maxCalculationFunction = isSingleSeries ? Math.max : Math.min;

  series.forEach((points) => {
    const minOrMaxInGroup = maxCalculationFunction(...points.filter((value) => value !== null || value > 0));

    points.forEach((positionedPoint) => {
      const rowNumber = Math.floor((maxValue - Math.min(positionedPoint, minOrMaxInGroup)) / DATA_LABEL_HEIGHT);

      if (rowNumber || rowNumber === 0) {
        let y1: number;

        if (!reservedRows.includes(rowNumber)) {
          y1 = (rowsNumber - rowNumber) * DATA_LABEL_HEIGHT;

          if (reservedRows.length === reservedRowsMaxLength) {
            reservedRows.shift();
          }

          reservedRows.push(rowNumber);

          placedPoints.push({
            y: y1,
            rowNumber,
          });
        } else {
          let index = 1;

          while (reservedRows.includes(rowNumber + index)) {
            index += 1;
          }

          y1 = (rowsNumber - rowNumber - index) * DATA_LABEL_HEIGHT;

          if (reservedRows.length === reservedRowsMaxLength) {
            reservedRows.shift();
          }

          const newRowNumber = rowNumber + index;

          reservedRows.push(newRowNumber);

          placedPoints.push({
            y: y1,
            rowNumber: newRowNumber,
          });
        }
      }
    });
  });

  const minY = Math.min(...placedPoints.map((point) => point.y));

  const pointsMinValue = Math.abs(minY) + DATA_LABEL_HEIGHT * 3;

  return pointsMinValue / plotHeight + 0.2;
};

const drawLabelsByRows = (
  groupedSeries: GroupedPoints,
  plotLeft: number,
  plotWidth: number,
  plotHeight: number,
  drawer: Drawer,
  theme?: DefaultTheme
): PointWithParent[] => {
  const placedLabels = placeLabelsInRows(groupedSeries, plotLeft, plotWidth);

  placedLabels.forEach(({ point, parent }) => {
    const {
      position: { x, y: y1 },
      style,
    } = point;
    const {
      position: { y: y2 },
    } = parent;

    const label = point.point.y?.toString();

    drawer.drawVerticalLine(x, y1, y2);
    drawer.drawLabel(
      label || '',
      x,
      Math.min(y1, y2),
      (theme as any)?.colors?.chartLabel || style?.color || DEFAULT_LABEL_COLOR,
      style?.fontSize
    );
  });

  return placedLabels;
};

const getEllipsisText = (categories: ChartCategories, labelName: string, plotWidth: number) => {
  if (isNotGroupedCategories(categories)) {
    return labelName;
  }

  const FONT_SIZE = 12;
  const ELLIPSIS = '...';

  const childCategoriesLength =
    (categories as CategoryData[])?.find((category: CategoryData) => {
      return category.name === labelName;
    })?.categories?.length || 0;

  const labelsNumber = categories.map((category) => (category as CategoryData)?.categories).flat(2).length;
  const cellWidth = (plotWidth / labelsNumber) * childCategoriesLength;
  const nameLength = Math.floor((cellWidth / FONT_SIZE) * (1 + FONT_SIZE / labelsNumber));

  if (labelName.length * FONT_SIZE > cellWidth) {
    if (nameLength === 0 || nameLength === 1) {
      return labelName.slice(0, 1) + ELLIPSIS;
    }

    if (nameLength > 1) {
      return labelName.slice(0, nameLength - 5) + ELLIPSIS;
    }
  }

  return labelName;
};

const updateAxes = (
  chart: ChartData,
  chartOptions: ChartWithGroupedCategoriesOptions,
  plotWidth: number,
  plotHeight: number,
  chartType: ChartType,
  showingValuesOnChart: boolean,
  graphDisplayType: GraphDisplayTypes
) => {
  const maxPadding =
    plotHeight && chartType === ChartType.column && showingValuesOnChart
      ? precalcMaxPadding(chart.series, plotHeight, plotWidth, graphDisplayType)
      : undefined;

  const { categories, isGroupedCategories } = getCategoriesOptions(chart, chartType);

  chartOptions.yAxis = chart.value_axes.map(({ key, display_name }, index) => ({
    maxPadding,
    reversedStacks: !(chartType === ChartType.stack),
    title: {
      text: display_name,
    },
    opposite: index !== 0,
    gridZIndex: -99,
    zIndex: 10,
  }));

  // Requires an additional yAxis to correctly update chart
  chartOptions.yAxis.push({
    title: {
      text: void 0,
    },
  });

  if (isGroupedCategories) {
    chartOptions.xAxis = {
      ...chartOptions.xAxis,
      labels: {
        ...chartOptions.xAxis.labels,
        formatter: function (label) {
          // If label hasn't child categories (at first level, on the top of axis) it has 'name' field
          // or 'string' type
          // else it has 'value' that contains name and categories.
          const labelName: string = (label as any)?.name || '';
          const labelValue: { name: string } | string = (label as any)?.value;

          if (labelName && categories && categories[0]) {
            return getEllipsisText(categories, labelName, plotWidth);
          }

          return (typeof labelValue === 'string' && labelValue.toString()) || '';
        },
      },
    };
  }
};

const updatePieChartHiddenSeries = (chart: Highcharts.Chart, chartType: ChartType, hiddenSeries: string[]) => {
  if (chartType === ChartType.pie) {
    const legend = chart?.legend;

    if (!legend) return;

    const { allItems } = legend;

    allItems.forEach((item) => {
      const visible = hiddenSeries ? !hiddenSeries.find((value) => value === item.name) : true;

      if ('setVisible' in item) {
        item.setVisible(visible, true);
      }
    });
  }
};

export const useChartOptions = (
  params: (ChartOptionsHookParams | AnalyticsChartOptionsHookParams) & { graphDisplayType: GraphDisplayTypes },
  deps: DependencyList
) => {
  const {
    chart,
    chartType,
    chartRef,
    showingValuesOnChart,
    chartCustomName,
    chartHiddenSeries,
    onToggleChartSeries,
    graph,
    theme,
    chartUnit,
    graphDisplayType,
  } = params;

  const chartOptionsRef = useRef<ChartWithGroupedCategoriesOptions>(null!);
  const { t: tUnits } = useTranslation('units');

  if (chartRef.current && !hasChartField(chartRef.current)) {
    throw new Error('Chart is not instance of Highcharts.Chart');
  }

  const highchart = chartRef.current?.chart;
  const forceUpdate = useForceUpdate();

  if (!chartOptionsRef.current) {
    chartOptionsRef.current = getChartOptions({
      chart,
      graphName: graph.name,
      id: `${graph.layout_id}-${graph.graph.key}`,
      graphKey: graph.graph.key,
      isFixed: false,
      showingValuesOnChart,
      chartCustomName,
      chartTypes: chartType,
      theme,
      hiddenSeries: chartHiddenSeries,
      onToggleSeries: onToggleChartSeries,
      chartRef,
      chartUnit,
      graphDisplayType,
      tUnits,
    });
  }

  useEffect(() => {
    chartOptionsRef.current = getChartOptions({
      chart,
      graphName: graph.name,
      id: `${graph.layout_id}-${graph.graph.key}`,
      graphKey: graph.graph.key,
      isFixed: graph.is_fixed,
      showingValuesOnChart,
      chartCustomName,
      chartTypes: chartType,
      theme,
      hiddenSeries: chartHiddenSeries,
      onToggleSeries: onToggleChartSeries,
      chartRef,
      chartUnit,
      graphDisplayType,
      tUnits,
    });

    if (chartRef.current) {
      forceUpdate();
    }
  }, deps);

  const plotWidth = highchart?.plotWidth || 0;
  const plotHeight = highchart?.plotHeight || 0;

  useEffect(() => {
    updateAxes(
      chart,
      chartOptionsRef.current,
      plotWidth,
      plotHeight,
      chartType,
      showingValuesOnChart,
      graphDisplayType
    );

    if (chartRef.current) {
      (chartRef.current as any)?.chart?.update(chartOptionsRef.current, true);
    }
  }, [chart, chartType, chartRef.current, plotWidth, plotHeight]);

  useEffect(() => {
    if (highchart && chartType === ChartType.pie) {
      updatePieChartHiddenSeries(highchart, chartType, chartHiddenSeries);
    }
  }, [chartHiddenSeries, chartRef, chartType]);

  return chartOptionsRef.current;
};

const drawLabels = (chart: Highcharts.Chart, drawer: Drawer, theme?: DefaultTheme) => {
  const series: Series[] = chart.series.filter(
    ({ name, options }) => !(name === 'Trend' || name === 'Тренд') && options.custom?.type !== ChartType.stack
  );

  if (!series) return;

  const chartTypesWeight: Partial<{ [key in ChartType]: number }> = {
    [ChartType.column]: 1,
    [ChartType.stack]: 1,
    [ChartType.line]: 0,
  };

  const sortedTypeGroups = Object.entries(groupSeriesByType(series)).sort(
    ([first], [second]) => (chartTypesWeight[second as ChartType] || 0) - (chartTypesWeight[first as ChartType] || 0)
  );

  const previouslyPlacedPoints: PointWithParent[] = [];

  try {
    for (const [type, typeGroup] of sortedTypeGroups) {
      const groupedSeries = groupPointsByCategory(typeGroup, {
        min: chart.xAxis[0]?.min || 0,
        max: chart.xAxis[0]?.max || 0,
      });

      switch (type) {
        case ChartType.column:
          const placedPoints = drawLabelsByRows(
            groupedSeries,
            chart.plotLeft,
            chart.plotWidth,
            chart.plotHeight,
            drawer,
            theme
          );

          previouslyPlacedPoints.push(...placedPoints);
          break;
        case ChartType.line:
          const unavailablePositions: Position[] = [];
          previouslyPlacedPoints.forEach(({ point, parent }) => {
            unavailablePositions.push(
              { x: point.position.x - chart.plotLeft, y: point.position.y - chart.plotTop },
              {
                ...parent.position,
                x: parent.position.x - chart.plotLeft,
              }
            );
          });

          drawLabelsAround(
            chart,
            groupedSeries,
            chart.plotLeft,
            chart.plotWidth,
            chart.plotHeight,
            drawer,
            unavailablePositions
          );
      }
    }
  } catch (e) {
    console.error(e);
    showErrorMessage('Произошла ошибка при отрисовке подписей значений.');
  }
};

const getWaterfallSeries = (
  chart: ChartData,
  chartType: ChartType,
  categories: ChartCategories,
  hiddenSeries?: string[],
  showingValuesOnChart?: boolean,
  chartUnit?: string | null
): SeriesOptionsType[] | null => {
  const series: SeriesOptionsType[] = [];

  for (let seriesIndex = 0; seriesIndex < chart.series.length; seriesIndex++) {
    const singleSeries = chart.series[seriesIndex];

    const visible = hiddenSeries ? !hiddenSeries.find((value) => value === singleSeries.display_name) : true;

    series.push({
      name: singleSeries.display_name,
      type: ChartType.waterfall,
      visible: visible,
      states: {
        hover: {
          enabled: !(singleSeries.display_name === 'Тренд' || singleSeries.display_name === 'Trend'),
        },
        inactive: {
          enabled: !(singleSeries.display_name === 'Тренд' || singleSeries.display_name === 'Trend'),
        },
      },
      data: singleSeries.data.map((data, dataIndex) => {
        const category = categories[dataIndex];

        if (!category || typeof category !== 'string') {
          return null;
        }

        return {
          isSum: data?.is_sum,
          name: category,
          y: data.value || undefined,
          type: singleSeries.chart_type || 'column',
          color: data?.color || singleSeries.color || undefined,
          marker: {
            enabled: !(singleSeries.display_name === 'Тренд' || singleSeries.display_name === 'Trend'),
          },
          custom: {
            unit: chartUnit,
          },
        };
      }),
      dataLabels: {
        enabled:
          showingValuesOnChart && !(singleSeries.display_name === 'Тренд' || singleSeries.display_name === 'Trend'),
        align: 'center',
        borderColor: '#ffffff',
        verticalAlign: 'middle',
        inside: true,
        className: 'dataLabel',
        useHTML: true,
        allowOverlap: true,
      },
      yAxis: getYAxisIndex(chart, singleSeries),
      custom: {
        type:
          chart.is_stacked && singleSeries.chart_type === ChartType.column ? ChartType.stack : singleSeries.chart_type,
      },
    });
  }

  return series;
};

export const getChartOptions = ({
  chart,
  chartTypes,
  chartRef,
  chartUnit,
  hiddenSeries,
  showingValuesOnChart,
  id,
  onToggleSeries,
  graphName,
  graphKey,
  theme,
  isFixed,
  graphDisplayType,
  tUnits,
  chartCustomName,
}: ChartOptionsParams): ChartWithGroupedCategoriesOptions => {
  const { categories, isGroupedCategories } = getCategoriesOptions(chart, chartTypes);

  const isStackType = chartTypes === ChartType.stack;
  const isColumnType = chartTypes === ChartType.column || (isStackType && !chart.is_stacked);

  const current = chartRef?.current;

  if (current && !hasChartField(current)) {
    throw new Error('Chart is not instance of Highcharts.Chart');
  }

  const plotWidth = current?.chart?.plotWidth || 0;
  const plotHeight = current?.chart?.plotHeight || 0;

  const maxPadding =
    plotHeight && plotWidth && isColumnType && showingValuesOnChart
      ? precalcMaxPadding(chart.series, plotHeight, plotWidth, graphDisplayType)
      : undefined;

  const getSeries = (): SeriesOptionsType[] => {
    switch (chartTypes) {
      case ChartType.pie:
        return [
          {
            type: 'pie',
            data: chart.series.map(({ display_name, data, color }) => ({
              name: display_name,
              y: data?.[0]?.value,
              color: color || undefined,
              custom: {
                unit: chartUnit,
              },
            })),
            dataLabels: {
              enabled: showingValuesOnChart,
              color: theme?.colors?.chartLabel || '#111111',
              borderColor: 'transparent',
              verticalAlign: 'middle',
            },
          },
        ];
      case ChartType.waterfall:
        return (
          getWaterfallSeries(chart, chartTypes, categories.flat(), hiddenSeries, showingValuesOnChart, chartUnit) || []
        );
      default:
        return chart.series.map((item): SeriesOptionsType => {
          const visible = hiddenSeries ? !hiddenSeries.find((value) => value === item.display_name) : true;

          const getType = () => {
            if (item.chart_type === ChartType.line) {
              return 'line';
            }

            if (item.chart_type === ChartType.stack || chartTypes === ChartType.stack) {
              return 'column';
            }

            return chartTypes || 'column';
          };

          const canDrawSeriesLabels =
            item.chart_type === ChartType.line || (item.chart_type === ChartType.column && !chart.is_stacked);

          return {
            name: item.display_name,
            stack: item.stack,
            type: getType(),
            visible: visible,
            color: item.color || undefined,
            states: {
              hover: {
                enabled: !(item.display_name === 'Тренд' || item.display_name === 'Trend'),
              },
              inactive: {
                enabled: !(item.display_name === 'Тренд' || item.display_name === 'Trend'),
              },
            },
            data: item.data.map((data) => ({
              name: getLocaleDate(data),
              y: data.value || undefined,
              type: item.chart_type || 'column',
              marker: {
                enabled: !(item.display_name === 'Тренд' || item.display_name === 'Trend'),
              },
              custom: {
                datetime: data.datetime,
                unit: chartUnit,
              },
            })),
            dataLabels: {
              enabled:
                !canDrawSeriesLabels &&
                showingValuesOnChart &&
                !(item.display_name === 'Тренд' || item.display_name === 'Trend'),
              align: 'center',
              color: !chart.is_stacked ? item.color || '#111111' : '#111111',
              borderColor: '#ffffff',
              verticalAlign: 'middle',
              inside: true,
              className: 'dataLabel',
              useHTML: true,
              allowOverlap: true,
              y: !(canDrawSeriesLabels || chart.is_stacked) ? -30 : undefined,
            },
            yAxis: getYAxisIndex(chart, item),
            custom: {
              type: chart.is_stacked && item.chart_type === ChartType.column ? ChartType.stack : item.chart_type,
            },
          };
        });
    }
  };

  const priorityChartOptions = getChartOptionsGetter(graphKey)?.({
    chart,
    isFixed,
    chartTypes,
    chartRef,
    chartUnit,
    hiddenSeries,
    showingValuesOnChart,
    id,
    onToggleSeries,
    graphName,
    graphKey,
    theme,
    graphDisplayType,
    tUnits,
    chartCustomName,
  });

  const options: Partial<ChartWithGroupedCategoriesOptions> = {
    ...priorityChartOptions,
    chart: {
      type: 'line',
      zoomType: 'x',
      zoomKey: 'shift',
      events: {
        load: function () {
          if (chartTypes === ChartType.pie) {
            const { allItems } = this.legend;

            allItems.forEach((item) => {
              const visible = hiddenSeries ? !hiddenSeries.find((value) => value === item.name) : true;

              if ('setVisible' in item) {
                item.setVisible(visible, true);
              }

              return item;
            });
          }

          if (!isGroupedCategories) {
            return;
          }

          // Get length of each name of categories at the first level and put it into common array.
          const categoriesNamesLength = categories
            .map((category) =>
              (category as CategoryData)?.categories.map((categoryName) => (categoryName as string)?.trim()?.length)
            )
            .flat(2);

          const labelsNumber = categoriesNamesLength.length;
          const maxLabelsLength = Math.max(...categoriesNamesLength);

          // Whether category name fits in the cell
          if (this.plotWidth / labelsNumber > maxLabelsLength * 10) {
            this.update(
              {
                xAxis: {
                  labels: {
                    rotation: 0,
                    y: 18,
                    align: 'center',
                  },
                },
              },
              true
            );
          }
        },
        render: function (event) {
          if (!(event?.target instanceof Highcharts.Chart)) {
            return;
          }

          if (showingValuesOnChart) {
            // TODO: remove chart label from label class name.
            // This requires chart label to make the label class unique.
            // Make chart's 'layout_id' field unique to solve this problem.
            const labelClass = `label-${id}-${getChartLabelHash(chart.label)}`;

            document.querySelectorAll(`.${labelClass}`).forEach((element) => {
              element.remove();
            });

            const drawer = getDrawer(this.renderer, labelClass);

            drawLabels(event.target as unknown as Highcharts.Chart, drawer, theme);
          }
        },
      },
    },
    plotOptions: {
      series: {
        dataLabels: {
          style: {
            fontSize: chart.series[0]?.data.length >= MIN_CATEGORIES_LENGTH ? '12px' : '16px',
          },
        },
        events: {
          show: (event: Event) => id && onToggleSeries?.(id, (event.target as any)?.userOptions.name, true),
          hide: (event: Event) => id && onToggleSeries?.(id, (event.target as any)?.userOptions.name, false),
        },
      },
      column: {
        pointRange: 1,
        pointPadding: 0.1,
        stacking: chart.is_stacked ? 'normal' : undefined,
        dataLabels: {
          enabled: true,
          format: chart.is_stacked ? '{point.percentage:.1f} %' : undefined,
          style: {
            fontSize: chart.is_stacked ? '12px' : undefined,
          },
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: `{point.name}: {point.y} ${tUnits('days')} ({point.percentage:.1f} %)`,
          style: {
            fontSize: '14px',
          },
        },
        showInLegend: true,
        point: {
          events: {
            legendItemClick: (event) => id && onToggleSeries?.(id, event.target.name),
          },
        },
      },
      line: {
        dataLabels: {
          allowOverlap: false,
          padding: 0,
        },
        zIndex: 5,
      },
      waterfall: {
        dataLabels: {
          color: theme?.colors?.chartLabel || '#111111',
          formatter: function () {
            const number = this.y;

            if (!number) {
              return number;
            }

            return Math.abs(number).toString();
          },
        },
      },
    },
    title: {
      text: chartCustomName || `${chart.label} ${isFixed ? ` (${i18n.t('widget:pinned')})` : ''}`,
      style: { padding: '0 8px 0 40px', textAlign: 'center' },
      useHTML: true,
      ...priorityChartOptions?.title,
    },
    yAxis: chart.value_axes.map(({ key, display_name }, index) => ({
      maxPadding,
      reversedStacks: !isStackType,
      title: {
        text: display_name,
      },
      opposite: index !== 0,
      gridZIndex: -99,
      zIndex: 10,
    })),
    xAxis: {
      // TODO: add type declaration for categories.
      // https://www.highcharts.com/docs/advanced-chart-features/highcharts-typescript-declarations
      categories: categories as unknown as string[],
      labels: {
        ...(isGroupedCategories
          ? {
              groupedOptions: [
                {
                  rotation: 0,
                },
              ],
              rotation: -90,
              align: 'right',
              y: 5,
              formatter: function (label) {
                // If label hasn't child categories (at first level, on the top of axis) it has 'name' field
                // or 'string' type
                // else it has 'value' that contains name and categories.
                const labelName: string = (label as any)?.name || '';
                const labelValue: { name: string } | string = (label as any)?.value;

                if (labelName && categories && categories[0]) {
                  return getEllipsisText(categories, labelName, plotWidth);
                }

                return (typeof labelValue === 'string' && labelValue.toString()) || '';
              },
            }
          : {}),
      },
    },
    tooltip: {
      enabled: !showingValuesOnChart || chart.is_stacked,
      pointFormat:
        chartTypes === ChartType.pie
          ? `<span style="color:{point.color}">●</span> {point.y} ${tUnits('days')} ({point.percentage:.1f} %)`
          : '<span style="color:{point.color}">●</span> {series.name}:<br/><b>{point.y}</b>',
      formatter: function (tooltip) {
        const tooltipText = tooltip.defaultFormatter.call(this, tooltip);
        const dateTime = (this as any).point?.custom?.datetime;

        if (this.series.name === 'Тренд' || this.series.name === 'Trend') {
          return false;
        }

        if (dateTime) {
          tooltipText.push(`${i18n.t('widget:date')}: ${moment(new Date(dateTime)).format('DD.MM.YYYY')}`);
        }

        if (chart.is_stacked) {
          const pointPercentage = this.point?.percentage || 0;

          tooltipText.push(` (${Math.floor(pointPercentage * 100) / 100} %)`);
        }

        const hasUnitField = (point: any): point is { custom: { unit?: string | null } } =>
          'custom' in this.point && 'unit' in (this.point as any).custom;

        if (hasUnitField(this.point) && this.point.custom.unit) {
          tooltipText.push(` ${this.point.custom.unit}`);
        }

        return tooltipText;
      },
      ...priorityChartOptions?.tooltip,
    },
    series: getSeries(),
    legend: {
      padding: 2,
      margin: 6,
      itemDistance: 10,
      ...priorityChartOptions?.legend,
    },
  };

  return options as ChartWithGroupedCategoriesOptions;
};
