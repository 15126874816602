import { ChartType } from '../../components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { GraphBlock } from '../PageService/PageService.types';

export enum ItemFilterTypes {
  none = 'none',
  string = 'string',
  number = 'number',
  date = 'date',
  object = 'object',
}

export enum RulesFilterTypes {
  none = 'none',
  single = 'single',
  interval = 'interval',
  multiple = 'multiple',
  less_or_equal = 'less_or_equal',
}

export enum KeyDataTypes {
  string = 'string',
  int = 'int',
  long = 'long',
  date = 'date',
}

export type VariantsFilter = {
  key: string | number;
  display_name: string;
  is_available?: boolean;
};

export enum AxisItemTypes {
  none = 'none',
  string = 'string',
  number = 'number',
  date = 'date',
  object = 'object',
}

export type PurposeInfo = {
  key: string;
  display_name: string;
};

export type Group = {
  key: string;
  display_name: string;
};

export type AxisInfo = {
  key: string;
  display_name: string;
  axis_item_type: AxisItemTypes;
  axis_groups: Group[];
};

export type Filters = {
  display_name: string;
  item_type: ItemFilterTypes;
  key: string;
  key_datatype: KeyDataTypes;
  rules: RulesFilterTypes[];
  variants?: VariantsFilter[];
};

export type Indicators = {
  key: string;
  display_name: string;
  axes: AxisInfo[];
  groups: Group[];
  filters: Filters[];
  is_cumulative?: boolean;
  has_cumulative_mode?: boolean;
  purpose_infos?: PurposeInfo[];
  chart_type?: ChartType;
};

export type GroupToFilter = {
  filter_key: string;
  group_key: string;
};

export type AnalyticsFilters = {
  common_filters: Filters[];
  indicators: Indicators[];
  groups_to_filters: GroupToFilter[];
  report_only_filters: string[];
};

export type CommonFiltersValues = {
  key?: string;
  filter_type?: string;
  key_datatype?: string;
  value?: string;
  value_from?: string;
  value_to?: string;
  values_list?: (number | string)[];
  filter_rule_type?: string;
  keyIndicator?: string;
};

export type IndicatorsValues = {
  key: string;
  display_name: string;
  group?: Group;
  axes?: string;
  axesName?: string;
  axis_groups?: string;
  /**
   * Value from frontend.
   */
  is_cumulative?: boolean;
  /**
   * Value that came from the server.
   */
  chart_is_cumulative?: boolean;
  groupFullData?: Group;
  purpose_infos?: string[];
  purposeInfosFullData?: PurposeInfo[];
  chart_type?: ChartType;
};

export type FiltersValues = {
  filters?: CommonFiltersValues[];
  indicators?: IndicatorsValues[];
  fixedGraphs?: GraphBlock[];
};
