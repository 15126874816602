import { Space, Spin } from 'antd';
import { FC } from 'react';

import { LoaderWrapper } from './Loader.styled';

const Loader: FC = () => (
  <LoaderWrapper>
    <Space size="large">
      <Spin size="large" />
    </Space>
  </LoaderWrapper>
);

export default Loader;
