import { PageResponse } from 'src/services/PageService/PageService.types';

import { SelectOptions } from '../Fields/Fields.types';

export const getDashboardOptions = (pages: PageResponse[]): SelectOptions[] => {
  return pages.map((page) => ({
    title: page.name,
    value: page.id,
  }));
};
