import moment from 'moment';

import { CommonFiltersValues } from 'src/services/AnalyticsService/AnalyticsService.types';
import { Fluid, ReportFields, WellActivity, WellPurpose } from 'src/services/ReportsService/ReportsService.types';

import { DATE_FORMAT, FILTERS_DATE_FORMAT, FiltersKeys, ValueGetter } from './ReportForm.constants';

const getStringValuesList: ValueGetter<string[] | undefined> = (filter) =>
  filter.values_list?.map((value) => value.toString());

const getNumberValuesList: ValueGetter<number[] | undefined> = (filter) =>
  filter.values_list?.reduce((numberValues: number[], value) => {
    const numberValue = typeof value === 'string' ? Number.parseInt(value) : value;

    return numberValue ? [...numberValues, numberValue] : numberValues || [];
  }, []);

export const getReportFieldsFromFilters = (filtersValues: CommonFiltersValues[]): ReportFields => {
  return filtersValues.reduce((resultFilters: ReportFields, currentFilter) => {
    if (!currentFilter.key) {
      return resultFilters;
    }

    switch (currentFilter.key) {
      case FiltersKeys.date:
        const from = moment(currentFilter.value_from, FILTERS_DATE_FORMAT);
        const to = moment(currentFilter.value_to, FILTERS_DATE_FORMAT);

        if (from.isValid()) {
          resultFilters.from = from.format(DATE_FORMAT);
        }

        if (to.isValid()) {
          resultFilters.to = to.format(DATE_FORMAT);
        }

        break;
      case FiltersKeys.operatorDivs:
        resultFilters.operatorDivs = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.blocks:
        resultFilters.blocks = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.fields:
        resultFilters.fields = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.pads:
        resultFilters.pads = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.purposes:
        resultFilters.purposes = currentFilter.values_list?.reduce((resultValues: string[], value) => {
          const numberValue = Number(value);

          if (!Number.isInteger(numberValue) && !WellPurpose[numberValue]) {
            return resultValues;
          }

          return [...resultValues, WellPurpose[numberValue]];
        }, []);
        break;
      case FiltersKeys.wellIds:
        resultFilters.wellIds = getNumberValuesList(currentFilter);
        break;
      case FiltersKeys.wellTypes:
        resultFilters.wellTypes = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.drillingRigs:
        resultFilters.drillingRigs = getStringValuesList(currentFilter);
        break;
      case FiltersKeys.fluids:
        resultFilters.fluids = currentFilter.values_list?.reduce((resultValues: string[], value) => {
          const numberValue = Number(value);

          if (!Number.isInteger(numberValue) && !Fluid[numberValue]) {
            return resultValues;
          }

          return [...resultValues, Fluid[numberValue]];
        }, []);
        break;
      case FiltersKeys.wellActivities:
        resultFilters.wellActivities = currentFilter.values_list?.reduce((resultValues: string[], value) => {
          const numberValue = Number(value);

          if (!Number.isInteger(numberValue) && !WellActivity[numberValue]) {
            return resultValues;
          }

          return [...resultValues, WellActivity[numberValue]];
        }, []);
    }

    return resultFilters;
  }, {});
};
