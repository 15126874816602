import { AnalyticsFilters } from 'src/services/AnalyticsService/AnalyticsService.types';

export type ReportsState = {
  filters: AnalyticsFilters | null;
};

export enum ReportsActionTypes {
  setFilters = 'SET_FILTERS',
}

export type ReportsActions = { type: ReportsActionTypes.setFilters; payload: AnalyticsFilters | null };
