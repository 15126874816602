import dashboard from './dashboard.json';
import filters from './filters.json';
import header from './header.json';
import profile from './profile.json';
import report from './report.json';
import units from './units.json';
import widget from './widget.json';

export default {
  header,
  profile,
  dashboard,
  filters,
  widget,
  report,
  units,
};
