import { combineReducers } from 'redux';

import { analyticsReducer } from './analytics';
import { pagesReducer } from './pages';
import { reportsReducer } from './reports';
import { userReducer } from './user';

export default combineReducers({
  pagesReducer,
  analyticsReducer,
  reportsReducer,
  userReducer,
});
