import { AxiosResponse } from 'axios';

import { agent } from 'src/agent';

import { GraphData, IndicatorData } from '../PageService/PageService.types';

import { AnalyticsFilters, CommonFiltersValues } from './AnalyticsService.types';

export const getFiltersList = (data: CommonFiltersValues[]): Promise<AxiosResponse<AnalyticsFilters>> => {
  return agent.post('api/pages/analytics/filters/', data);
};

export const getAnalyticsData = (data: GraphData): Promise<AxiosResponse<IndicatorData>> => {
  return agent.post('/api/pages/analytics/data', data);
};
