import { Dropdown, Menu, Tooltip } from 'antd';
import Highcharts from 'highcharts';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';

import {
  AddToDashboardModal,
  Chart,
  ChartPropertiesModal,
  DeleteChartModal,
  OpenAnalyticsModal,
  EditGraphTitleModal,
} from 'src/components';
import { useChartOptions } from 'src/components/Chart/Chart.helpers';
import { ChartType, DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import useForceUpdate from 'src/hooks/useForceUpdate';
import { ChartData } from 'src/services/PageService/PageService.types';
import { getDashboardsState, getGraphFrontendState } from 'src/store/pages';
import { getDynamicPeriodValues } from 'src/utils';

import { DEFAULT_CHART_UNITS, isIndicatorsKey } from '../../constants/charts';

import { useDisplayTypeMemo, useGraphMemo, useHiddenSeriesMemo } from './DashboardChart.helpers';
import {
  Container,
  LeftMenu,
  MenuContainer,
  MenuIcon,
  MenuItem,
  QuestionIcon,
  Switcher,
  TooltipText,
} from './DashboardChart.styled';
import { DashboardChartProps } from './DashboardChart.types';

const DashboardChart: FC<DashboardChartProps> = (props) => {
  const {
    containerClassName,
    className,
    pageId,
    chartId = 0,
    setDraggable,
    onChangeShowingValuesOnChart,
    onToggleChartSeries,
    onEditChartCustomName,
    ...restProps
  } = props;
  const { t } = useTranslation('widget');

  const dashboards = useSelector(getDashboardsState);

  const theme = useTheme();

  const graph = useGraphMemo(restProps.graph);

  const { chartType, showingValuesOnChart, chartCustomName, ...restFrontend } = useSelector(
    getGraphFrontendState(pageId, graph.graph.key, graph.layout_id)
  );

  const { value: hiddenChartSeries } = useHiddenSeriesMemo(restFrontend.hiddenChartSeries);
  const { value: displayType } = useDisplayTypeMemo(restFrontend.displayType);

  const chart = graph.data?.charts?.[chartId];

  const newChart: ChartData = useMemo(() => {
    return JSON.parse(JSON.stringify(chart));
  }, [chartId, graph]);

  const [isMenuOpened, setMenuOpened] = useState(false);
  const [isDeleteModalOpened, setDeleteModalOpened] = useState(false);
  const [isAnalyticsModalOpened, setAnalyticsModalOpened] = useState(false);
  const [isDashboardModalOpened, setDashboardModalOpened] = useState(false);
  const [isPropertyModalOpened, setPropertyModalOpened] = useState(false);
  const [isEditTitleModalOpened, setIsEditTitleModalOpened] = useState(false);

  const openPropertiesModal = () => {
    setPropertyModalOpened(true);
    setMenuOpened(false);
  };

  const openAnalyticsModal = () => {
    setAnalyticsModalOpened(true);
    setMenuOpened(false);
  };

  const openDashboardModal = () => {
    setDashboardModalOpened(true);
    setMenuOpened(false);
  };

  const openDeleteModal = () => {
    setDeleteModalOpened(true);
    setMenuOpened(false);
  };

  const openEditTitleModal = () => {
    setDraggable(false);
    setIsEditTitleModalOpened(true);
    setMenuOpened(false);
  };

  const closeEditTitleModal = () => {
    setDraggable(true);
    setIsEditTitleModalOpened(false);
  };

  const menu = (
    <Menu>
      <MenuItem>
        <button onClick={openPropertiesModal}>{t('properties')}</button>
      </MenuItem>

      <MenuItem>
        <button onClick={openAnalyticsModal}>{t('openAnalytics')}</button>
      </MenuItem>

      <MenuItem>
        <button onClick={openDashboardModal} disabled={!dashboards.length}>
          {t('addToDashboard')}
        </button>
      </MenuItem>

      <MenuItem>
        <button onClick={openEditTitleModal}>{t('editTitle')}</button>
      </MenuItem>

      <MenuItem isDanger={true}>
        <button onClick={openDeleteModal}>{t('delete')}</button>
      </MenuItem>
    </Menu>
  );

  const enableDraggable = () => {
    setDraggable(true);
  };
  const disableDraggable = () => {
    setDraggable(false);
  };

  const toggleMenu = (flag: boolean) => {
    setDraggable(!flag);
    setMenuOpened(flag);
  };

  if (
    chart &&
    displayType &&
    displayType?.displayType === DisplayType.dynamic &&
    displayType?.updatePeriod &&
    graph.name !== 'УТЗ' &&
    chartType !== ChartType.pie
  ) {
    chart.series.forEach((series, index) => {
      if (!displayType?.updatePeriod) return;

      const updatedPeriodValues = getDynamicPeriodValues(series.data, displayType?.updatePeriod);
      if (updatedPeriodValues) {
        newChart.series[index].data = updatedPeriodValues.flat();
      }
    });
  }

  // В ChartRef ссылка на график появляется только после первого рендера,
  // из-за этого при первом рендере в chartOptions не попадает актуальная высота графика.
  const forceUpdate = useForceUpdate();
  useEffect(() => forceUpdate(), [forceUpdate]);

  const chartRef = useRef<Highcharts.Chart>(null);
  const indicatorKey = graph.graph.key;

  const chartOptions = useChartOptions(
    {
      chart: newChart,
      chartHiddenSeries: hiddenChartSeries,
      chartCustomName,
      chartRef,
      chartType,
      graph,
      onToggleChartSeries,
      showingValuesOnChart,
      theme,
      chartUnit: isIndicatorsKey(indicatorKey) ? DEFAULT_CHART_UNITS[indicatorKey] : null,
      graphDisplayType: displayType,
    },
    [newChart, chartRef, chartType, graph, hiddenChartSeries, showingValuesOnChart, chartCustomName, theme]
  );

  return (
    <>
      <Container className={className}>
        <MenuContainer>
          <LeftMenu>
            <Tooltip
              placement="topRight"
              title={
                <div>
                  <TooltipText>
                    {t('tooltip.zoomDesktop')} <span>{t('tooltip.zoomDesktopKey')}</span>
                  </TooltipText>
                  <TooltipText>
                    {t('tooltip.zoomMobile')} <span>{t('tooltip.zoomMobileKey')}</span>
                  </TooltipText>
                </div>
              }
            >
              <QuestionIcon />
            </Tooltip>
            <Tooltip
              placement="topRight"
              title={showingValuesOnChart ? t('disableValuesOnChart') : t('enableValuesOnChart')}
            >
              <Switcher
                checked={showingValuesOnChart}
                onChange={(value: boolean) => onChangeShowingValuesOnChart(value, graph.graph.key, graph.layout_id)}
              />
            </Tooltip>
          </LeftMenu>

          <Dropdown overlay={menu} placement="bottomRight" visible={isMenuOpened} onVisibleChange={toggleMenu}>
            <MenuIcon
              onMouseOver={disableDraggable}
              onMouseLeave={enableDraggable}
              onTouchStartCapture={disableDraggable}
              onTouchEnd={enableDraggable}
            />
          </Dropdown>
        </MenuContainer>
        <Chart options={chartOptions} containerClassName={containerClassName} ref={chartRef} />
      </Container>

      <DeleteChartModal
        isVisible={isDeleteModalOpened}
        onClose={() => setDeleteModalOpened(false)}
        graph={graph}
        chart={newChart}
        pageId={pageId}
      />

      <AddToDashboardModal
        isVisible={isDashboardModalOpened}
        onClose={() => setDashboardModalOpened(false)}
        graph={graph}
        chart={newChart}
        displayType={displayType?.displayType}
        updatePeriod={displayType?.updatePeriod}
        chartType={chartType}
        hiddenSeries={hiddenChartSeries}
      />

      <OpenAnalyticsModal
        isVisible={isAnalyticsModalOpened}
        onClose={() => setAnalyticsModalOpened(false)}
        graph={graph}
        chart={newChart}
      />

      <ChartPropertiesModal
        isVisible={isPropertyModalOpened}
        onClose={() => setPropertyModalOpened(false)}
        graph={graph}
        chart={newChart}
      />

      <EditGraphTitleModal
        isVisible={isEditTitleModalOpened}
        onClose={closeEditTitleModal}
        onEditGraphTitle={(value: string) => onEditChartCustomName(value, graph.graph.key, graph.layout_id)}
        currentTitle={chartOptions.title?.text}
      />
    </>
  );
};

export default DashboardChart;
