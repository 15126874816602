import { Form } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { InputField } from 'src/components';
import { PATHS } from 'src/constants';
import { createPage } from 'src/services/PageService';
import { PageTypes } from 'src/services/PageService/PageService.types';
import { addPageAction } from 'src/store/pages';
import { showErrorMessage } from 'src/utils';

import { ModalWrapper } from '../forms/CreatePageForm/CreatePageForm.styled';

import { OpenAnalyticsModalProps } from './OpenAnalyticsModal.types';

const OpenAnalyticsModal: FC<OpenAnalyticsModalProps> = (props) => {
  const { isVisible, onClose, graph } = props;

  const { t } = useTranslation('dashboard');
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  const [isLoading, setLoading] = useState(false);

  const handleClose = () => {
    form.resetFields();
    setLoading(false);
    onClose();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true);

      createPage({ name: values.name, page_type: PageTypes.analytics, page_map: { graphs: [graph], layout: {} } }, true)
        .then(({ data }) => {
          dispatch(addPageAction(data));
          history.push(`${PATHS.pages}/${data.id}`);
          handleClose();
        })
        .catch((error) => {
          showErrorMessage(t('createForm.messages.analyticsError', { error: error?.message }));
        })
        .finally(() => setLoading(false));
    });
  };

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      title={t('createForm.createAnalyticsPage')}
      okText={t('createForm.createButton')}
      cancelText={t('createForm.cancelButton')}
      onCancel={handleClose}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
        <InputField
          name="name"
          placeholder={t('createForm.namePage')}
          rules={[
            { required: true, message: t('createForm.errors.namePage') },
            { max: 250, message: t('createForm.errors.maxLength') },
          ]}
        />
      </Form>
    </ModalWrapper>
  );
};

export default OpenAnalyticsModal;
