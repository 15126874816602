import { CellState, LabelPoint, Position } from './types';

const DEFAULT_CELL_WIDTH = 12;
const DEFAULT_CELL_HEIGHT = 8;

class LabelsMatrix {
  matrix: CellState[][];

  private readonly top: number;
  private readonly right: number;
  private readonly bottom: number;
  private readonly left: number;
  private readonly cellWidth: number;
  private readonly cellHeight: number;
  private readonly rowsCount: number;
  private readonly columnsCount: number;

  constructor(
    borders: [number, number, number, number],
    cellWidth: number = DEFAULT_CELL_WIDTH,
    cellHeight: number = DEFAULT_CELL_HEIGHT
  ) {
    const [top, right, bottom, left] = borders;

    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;

    this.cellWidth = cellWidth;
    this.cellHeight = cellHeight;

    this.rowsCount = Math.ceil((bottom - top) / cellHeight);
    this.columnsCount = Math.ceil((right - left) / cellWidth);

    this.matrix = [];

    for (let row = 0; row < this.rowsCount; row++) {
      this.matrix[row] = [];

      for (let column = 0; column < this.columnsCount; column++) {
        this.matrix[row][column] = { isBusy: false };
      }
    }
  }

  private setPaddingAround(
    x: number,
    y: number,
    paddingMap = [
      [false, true, true, true, true, true, false],
      [true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true],
      [true, true, true, true, true, true, true],
      [false, true, true, true, true, true, false],
    ]
  ) {
    paddingMap.forEach((row, rowNumber) => {
      row.forEach((cell, columnNumber) => {
        const horizontalPadding = Math.floor(row.length / 2);
        const verticalPadding = Math.floor(paddingMap.length / 2);

        const offsetX = x + columnNumber - horizontalPadding;
        const offsetY = y + rowNumber - verticalPadding;

        if (cell && this.getIsValidCoordinates(offsetX, offsetY) && !this.matrix[offsetY][offsetX].isBusy) {
          this.matrix[offsetY][offsetX].isBusy = true;
        }
      });
    });
  }

  private getIsValidCoordinates(x: number, y: number) {
    const isInteger = Number.isInteger(x) && Number.isInteger(y);

    return isInteger && x >= 0 && y >= 0 && x < this.columnsCount && y < this.rowsCount;
  }

  /**
   * @param x Center (point) X coordinate.
   * @param y Center (point) Y coordinate.
   * @param radius Radius of potential positions' matrix without inner radius of point offset.
   * @param priorityMask Must be 3x3.
   */
  private getPotentialPositionsBoundaries(
    x: number,
    y: number,
    radius: number,
    priorityMask: [number, number | null, number][]
  ) {
    if (radius < 1 || !this.getIsValidCoordinates(x, y) || priorityMask.length !== 3 || priorityMask[0].length !== 3) {
      return [];
    }

    const intRadius = Math.round(radius);

    const priorityBoundaries = [
      {
        priority: priorityMask[0][0],
        boundaries: {
          top: y - intRadius,
          bottom: y - 1,
          left: x - intRadius,
          right: x - 1,
        },
      },
      {
        priority: priorityMask[0][1],
        boundaries: {
          top: y - intRadius,
          bottom: y - 1,
          left: x,
          right: x,
        },
      },
      {
        priority: priorityMask[0][2],
        boundaries: {
          top: y - intRadius,
          bottom: y - 1,
          left: x + 1,
          right: x + intRadius,
        },
      },
      {
        priority: priorityMask[1][0],
        boundaries: {
          top: y,
          bottom: y,
          left: x - intRadius,
          right: x - 1,
        },
      },
      {
        priority: priorityMask[1][2],
        boundaries: {
          top: y,
          bottom: y,
          left: x + 1,
          right: x + intRadius,
        },
      },
      {
        priority: priorityMask[2][0],
        boundaries: {
          top: y + 1,
          bottom: y + intRadius,
          left: x - intRadius,
          right: x - 1,
        },
      },
      {
        priority: priorityMask[2][1],
        boundaries: {
          top: y + 1,
          bottom: y + intRadius,
          left: x,
          right: x,
        },
      },
      {
        priority: priorityMask[2][2],
        boundaries: {
          top: y + 1,
          bottom: y + intRadius,
          left: x + 1,
          right: x + intRadius,
        },
      },
    ];

    return priorityBoundaries.sort((first, second) => Number(first.priority) - Number(second.priority));
  }

  toMatrixCoordinates(x: number, y: number) {
    return {
      x: Math.floor(x / this.cellWidth),
      y: Math.floor(y / this.cellHeight),
    };
  }

  toRealCoordinates(x: number, y: number) {
    return {
      x: x * this.cellWidth + this.left,
      y: y * this.cellHeight + this.top,
    };
  }

  setUnavailablePositions(
    coordinates: Position[] | Position,
    paddingMap = [
      [true, true, true],
      [true, true, true],
      [true, true, true],
    ]
  ) {
    const setIsBusy = (x: number, y: number) => {
      const { x: matrixX, y: matrixY } = this.toMatrixCoordinates(x, y);

      if (this.getIsValidCoordinates(x, y)) {
        this.matrix[matrixY][matrixX] = { isBusy: true };
      }

      this.setPaddingAround(matrixX, matrixY, paddingMap);
    };

    if (Array.isArray(coordinates)) {
      coordinates.forEach(({ x, y }) => setIsBusy(x, y));
    } else {
      setIsBusy(coordinates.x, coordinates.y);
    }
  }

  /**
   * @return {Position} Position in real coordinates.
   */
  private getAlignedPointPosition({ position, parentPosition }: LabelPoint): Position {
    const { x, y } = this.toMatrixCoordinates(position.x, position.y);
    const { x: parentX } = this.toMatrixCoordinates(parentPosition.x, parentPosition.y);

    const isValidPositions = this.getIsValidCoordinates(x, y);
    if (!isValidPositions) {
      return position;
    }

    const isInSameColumn = x === parentX;
    if (!isInSameColumn) {
      return this.toRealCoordinates(x, y);
    }

    const { y: realY } = this.toRealCoordinates(x, y);

    return { x: position.x + this.left, y: realY };
  }

  addPoint(point: LabelPoint) {
    const { position, parentPosition } = point;

    const { x, y } = this.toMatrixCoordinates(position.x, position.y);
    const { x: parentX, y: parentY } = this.toMatrixCoordinates(parentPosition.x, parentPosition.y);

    if (this.getIsValidCoordinates(x, y) && !this.matrix[y][x].isBusy) {
      this.matrix[y][x] = { isBusy: true, point, alignedPosition: this.getAlignedPointPosition(point) };

      this.setPaddingAround(x, y);
    } else {
      const getPriorityMask = (): [number, number | null, number][] => {
        if (point.isTop) {
          return [
            [3, 1, 2],
            [7, null, 8],
            [6, 4, 5],
          ];
        }

        if (point.isBottom) {
          return [
            [5, 4, 6],
            [7, null, 8],
            [2, 1, 3],
          ];
        }

        return [
          [4, 1, 3],
          [7, null, 8],
          [6, 2, 5],
        ];
      };

      const potentialPositions = this.getPotentialPositionsBoundaries(parentX, parentY, 4, getPriorityMask());

      let isPlaced = false;

      for (let index = 0; index < potentialPositions.length && !isPlaced; index++) {
        const {
          boundaries: { top, right, bottom, left },
        } = potentialPositions[index];

        for (let row = top; row <= bottom && !isPlaced; row++) {
          for (let column = left; column <= right && !isPlaced; column++) {
            if (this.getIsValidCoordinates(column, row) && !this.matrix[row][column].isBusy) {
              this.matrix[row][column] = { isBusy: true, point };

              isPlaced = true;

              this.setPaddingAround(column, row);
            }
          }
        }
      }
    }
  }
}

export default LabelsMatrix;
