import { useEffect, useRef } from 'react';

import { useForceUpdate } from 'src/hooks';
import { CompareFn } from 'src/types';

const useLocalMemo = <T extends unknown>(actualState: T, compare: CompareFn<T>, updateComponent = false): T => {
  const ref = useRef<T>();
  const forceUpdate = useForceUpdate();

  if (!ref.current) {
    ref.current = actualState;
  }

  useEffect(() => {
    if (ref.current && !compare(ref.current, actualState)) {
      ref.current = actualState;

      if (updateComponent) {
        forceUpdate();
      }
    }
  }, [actualState, compare, forceUpdate, updateComponent]);

  return ref.current;
};

export default useLocalMemo;
