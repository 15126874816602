import { Form, Radio, RadioChangeEvent } from 'antd';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { InputField, SelectField } from 'src/components/';
import { SelectOptions } from 'src/components/Fields/Fields.types';
import { getRolesOfUsers } from 'src/services/AuthService';

import { getTemplatesState } from '../../../store/pages';

import { ModalWrapper, RadioGroup } from './PublishDashboardForm.styled';
import { PublishDashboardFormProps } from './PublishDashboardForm.types';

enum PublishVariant {
  new,
  change,
}

const PublishDashboardForm: FC<PublishDashboardFormProps> = ({
  isVisible,
  isLoading,
  onCreate,
  onUpdate,
  onCancel,
}) => {
  const { t } = useTranslation('dashboard');
  const [form] = Form.useForm();

  const templates = useSelector(getTemplatesState);
  const [publishVariant, setPublishVariant] = useState<PublishVariant>(PublishVariant.new);
  const [options, setOptions] = useState<SelectOptions[]>([]);

  useEffect(() => {
    getRolesOfUsers().then(({ data }) => {
      setOptions(data.map((role) => ({ title: role.name, value: role.id })));
    });
  }, []);

  const handleChangeTemplatePage = (value: string | number) => {
    const selectedTemplate = templates.find((item) => item.id === value);
    form.setFieldsValue({ name: selectedTemplate?.name });
    form.setFieldsValue({ roles: selectedTemplate?.roles });
  };

  const onCancelHandler = () => {
    onCancel();
    form.resetFields();
  };

  const onOkHandler = () => {
    form
      .validateFields()
      .then((values) => {
        if (publishVariant === PublishVariant.new) {
          onCreate(values);
        } else if (publishVariant === PublishVariant.change) {
          onUpdate(values.id, values);
        }
      })
      .then(() => form.resetFields());
  };

  const onChangePublishVariantHandler = (event: RadioChangeEvent) => {
    form.resetFields();
    setPublishVariant(event.target.value);
  };

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      confirmLoading={isLoading}
      title={t('publishDashboardForm.title')}
      okText={t('publishDashboardForm.createButton')}
      cancelText={t('publishDashboardForm.cancelButton')}
      onCancel={onCancelHandler}
      onOk={onOkHandler}
    >
      <Form form={form} layout="vertical">
        <RadioGroup onChange={onChangePublishVariantHandler} value={publishVariant}>
          <Radio.Button value={PublishVariant.new}>{t('publishDashboardForm.publishVariants.new')}</Radio.Button>
          <Radio.Button value={PublishVariant.change}>{t('publishDashboardForm.publishVariants.change')}</Radio.Button>
        </RadioGroup>
        {publishVariant === PublishVariant.new ? (
          <>
            <InputField
              name="name"
              placeholder={t('publishDashboardForm.templateName')}
              rules={[
                { required: true, message: t('publishDashboardForm.errors.templateName') },
                { max: 250, message: t('publishDashboardForm.errors.maxLength') },
              ]}
            />
            <SelectField
              name="roles"
              placeholder={t('publishDashboardForm.roles')}
              rules={[{ required: true, message: t('publishDashboardForm.errors.roles') }]}
              options={options}
              mode="multiple"
            />
          </>
        ) : (
          <>
            {templates.length > 0 && (
              <>
                <SelectField
                  name="id"
                  placeholder={t('createForm.templatePage')}
                  rules={[{ required: false }]}
                  options={templates.map((template) => ({ title: template.name, value: template.id }))}
                  onChange={(value) => handleChangeTemplatePage(value)}
                />
                <InputField
                  name="name"
                  placeholder={t('publishDashboardForm.templateName')}
                  rules={[
                    { required: true, message: t('publishDashboardForm.errors.templateName') },
                    { max: 250, message: t('publishDashboardForm.errors.maxLength') },
                  ]}
                />
                <SelectField
                  name="roles"
                  placeholder={t('publishDashboardForm.roles')}
                  rules={[{ required: true, message: t('publishDashboardForm.errors.roles') }]}
                  options={options}
                  mode="multiple"
                />
              </>
            )}
          </>
        )}
      </Form>
    </ModalWrapper>
  );
};

export default PublishDashboardForm;
