import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { MyApplicationsIcon, AllApplicationsIcon } from 'src/assets/icons';
import { GOHUB_REDIRECT_URL } from 'src/constants';
import { ICard } from 'src/services/AuthService';
import { getToken, returnField } from 'src/utils';

import { DropdownApps, Overlay, Nav, NavItem, GoHubLink, AppIcon, EmptyApps } from './Applications.styled';
import { ApplicationsProps } from './Applications.types';

const Applications: FC<ApplicationsProps> = ({ products }) => {
  const { t } = useTranslation('header');
  const jwtToken = getToken();

  const apps = (
    <Overlay>
      {products && products?.length > 0 ? (
        <Nav>
          {products.map((productItem: ICard) => {
            const url = productItem.auth_url ? `${productItem.auth_url}${jwtToken}` : productItem.url;
            return (
              <NavItem href={url} target="_blank" rel="noopener noreferrer" key={productItem.id}>
                <AppIcon src={productItem.logo} alt={returnField(productItem.name_ru, productItem.name_en)} />
                {returnField(productItem.name_ru, productItem.name_en)}
              </NavItem>
            );
          })}
        </Nav>
      ) : (
        <EmptyApps>{t('emptyApps')}</EmptyApps>
      )}
      <GoHubLink href={GOHUB_REDIRECT_URL} target="_blank" rel="noopener noreferrer">
        <AllApplicationsIcon /> {t('goToGOhub')}
      </GoHubLink>
    </Overlay>
  );

  return (
    <DropdownApps overlay={apps} trigger={['click']} placement="bottomRight">
      <div>
        <MyApplicationsIcon /> {t('myApps')}
      </div>
    </DropdownApps>
  );
};

export default Applications;
