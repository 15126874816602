import { FC } from 'react';

import { ModalWrapper } from './ConfirmModal.styled';
import { ConfirmModalProps } from './ConfirmModal.types';

const RemovePageModal: FC<ConfirmModalProps> = (props) => {
  const { isVisible, isLoading, onRemove, onCancel, okText, cancelText, children, title } = props;

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      confirmLoading={isLoading}
      title={title}
      okText={okText}
      cancelText={cancelText}
      onCancel={onCancel}
      onOk={onRemove}
    >
      {children}
    </ModalWrapper>
  );
};

export default RemovePageModal;
