// @ts-nocheck

import Highcharts from 'highcharts';
import exportingInit from 'highcharts/highcharts-more';
import { useEffect, forwardRef, useImperativeHandle } from 'react';
import { useRef } from 'react';

import { Container } from './Chart.styled';
import { ChartProps, ChartWrapper } from './Chart.types';

exportingInit(Highcharts);

Highcharts.wrap(Highcharts.Pointer.prototype, 'dragStart', function (proceed, event) {
  const zoomKey = (this as any).chart.options.chart.zoomKey && this.chart.options.chart.zoomKey + 'Key';

  if (event[zoomKey]) {
    proceed.call(this, event);
  }
});

const Chart = forwardRef<Highcharts.Chart, ChartProps>((props, ref) => {
  const { options, containerClassName } = props;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const chartRef = useRef<Highcharts.Chart | null>(null);

  useImperativeHandle<Highcharts.Chart, ChartWrapper>(
    ref,
    () => ({
      get chart() {
        return chartRef.current;
      },
      container: containerRef,
    }),
    []
  );

  useEffect(() => {
    if (containerRef.current && !chartRef.current) {
      chartRef.current = Highcharts.chart(containerRef.current, options);
    }
  }, [options]);

  useEffect(() => {
    if (chartRef.current) {
      chartRef.current = Highcharts.chart(containerRef.current, options);
    }
  }, [options]);

  useEffect(() => {
    return () => {
      chartRef.current?.destroy();
      chartRef.current = null;
    };
  }, []);

  useEffect(() => {
    if (chartRef.current) {
      // It might be a Highcharts bug.
      // Highcharts cannot read property 'y' of labelPos on 'moveLabel' function
      // when resizing a chart with grouped categories.
      // Issue: https://github.com/highcharts/highcharts/issues/14938.
      try {
        chartRef.current.reflow();
      } catch (e) {}
    }
  });

  return <Container ref={containerRef} className={containerClassName} />;
});

export default Chart;
