import { Layout } from 'antd';
import { FC, useContext, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useRouteMatch } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';

import { Header } from 'src/components';
import { PATHS } from 'src/constants';
import { ThemeContext } from 'src/context/themeContext';
import { PageTypes } from 'src/services/PageService/PageService.types';
import { getPagesState, getViewModeState, ViewMode } from 'src/store/pages';

import { LayoutWrapper } from './MainLayout.styled';

const MainLayout: FC = ({ children }) => {
  const { theme } = useContext(ThemeContext);
  const { pathname } = useLocation();
  const match = useRouteMatch<{ id: string }>(`${PATHS.pages}/:id`);
  const pages = useSelector(getPagesState);
  const activePage = pages.find((page) => String(page.id) === match?.params.id);
  const pageType = activePage?.page_type;
  const viewMode = useSelector(getViewModeState);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [pathname]);

  return (
    <ThemeProvider theme={theme}>
      <Layout>
        {!(pageType === PageTypes.dashboard && viewMode === ViewMode.fullscreen) && <Header />}
        <Layout.Content>
          <LayoutWrapper showHeader={!(pageType === PageTypes.dashboard && viewMode === ViewMode.fullscreen)}>
            {children}
          </LayoutWrapper>
        </Layout.Content>
      </Layout>
    </ThemeProvider>
  );
};

export default MainLayout;
