import { Form } from 'antd';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

import { SelectField } from 'src/components';
import { ModalWrapper } from 'src/components/forms/CreatePageForm/CreatePageForm.styled';
import { getPageById, updatePage } from 'src/services/PageService';
import { addOrUpdateFrontend } from 'src/services/PageService/PageService.helpers';
import { FrontendValue } from 'src/services/PageService/PageService.types';
import { getDashboardsState, updatePageAction } from 'src/store/pages';
import { showErrorMessage, showSuccessMessage, setCurrentDateToChartFilters } from 'src/utils';

import { getDashboardOptions } from './AddToDashboardModal.helpers';
import { AddToDashboardModalProps } from './AddToDashboardModal.types';

const AddToDashboardModal: FC<AddToDashboardModalProps> = (props) => {
  const { isVisible, onClose, graph, displayType, chartType, updatePeriod, hiddenSeries } = props;

  const { t } = useTranslation('dashboard');
  const [form] = Form.useForm();
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const dashboards = useSelector(getDashboardsState);
  const dashboardOptions = getDashboardOptions(dashboards);

  const handleClose = () => {
    form.resetFields();
    setLoading(false);
    onClose();
  };

  const handleSubmit = () => {
    form.validateFields().then((values) => {
      setLoading(true);

      getPageById(values.dashboard)
        .then(({ data }) => {
          const newLayoutId = uuidv4();

          const newFrontendDisplayTypeValue = {
            key: `${newLayoutId}-${graph.graph.key}`,
            value: { displayType, updatePeriod },
          };
          const newFrontendDisplayType = addOrUpdateFrontend(
            newFrontendDisplayTypeValue,
            data.page_map?.frontend,
            'graph_display_types'
          );

          const newFrontendChartTypeValue = { key: `${newLayoutId}-${graph.graph.key}`, value: chartType };
          const newFrontendChartType = addOrUpdateFrontend(
            newFrontendChartTypeValue,
            data.page_map?.frontend,
            'chart_types'
          );

          const newFrontendHiddenChartSeries: FrontendValue<string[]>[] = hiddenSeries
            ? [
                ...(data.page_map?.frontend?.hidden_chart_series || []),
                { key: `${newLayoutId}-${graph.graph.key}`, value: hiddenSeries },
              ]
            : [];

          updatePage(
            values.dashboard,
            {
              ...data,
              page_map: {
                ...data.page_map,
                graphs: setCurrentDateToChartFilters([
                  ...(data.page_map?.graphs || []),
                  { ...graph, layout_id: newLayoutId },
                ]),
                frontend: {
                  ...data.page_map?.frontend,
                  ...newFrontendDisplayType,
                  ...newFrontendChartType,
                  hidden_chart_series: newFrontendHiddenChartSeries,
                },
              },
            },
            true
          )
            .then(({ data: updatedData }) => {
              dispatch(updatePageAction(updatedData.id, updatedData));
              handleClose();
              showSuccessMessage(t('createDashboard.messages.success', { name: updatedData.name }));
            })
            .finally(() => setLoading(false));
        })
        .catch((error) => {
          setLoading(false);
          showErrorMessage(t('createDashboard.messages.error', { error: error?.message }));
        });
    });
  };

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      title={t('createDashboard.title')}
      okText={t('createDashboard.submit')}
      cancelText={t('createForm.cancelButton')}
      onCancel={handleClose}
      onOk={handleSubmit}
      confirmLoading={isLoading}
    >
      <Form form={form} layout="vertical">
        <SelectField
          name="dashboard"
          placeholder={t('createDashboard.pageName')}
          rules={[{ required: true, message: t('createDashboard.errors.pageName') }]}
          options={dashboardOptions}
        />
      </Form>
    </ModalWrapper>
  );
};

export default AddToDashboardModal;
