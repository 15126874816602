import { message } from 'antd';
import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';

import { PrivateWrapper } from 'src/components';
import { PATHS } from 'src/constants';
import { ThemeContextProvider } from 'src/context/themeContext';
import { MainLayout } from 'src/layouts';
import { getFiltersList } from 'src/services/AnalyticsService';
import { getFiltersState, setFiltersAction } from 'src/store/analytics';
import GlobalStyles from 'src/styles/GlobalStyles';
import { ExternalLogin, Pages } from 'src/views';

const Router: FC = () => {
  const dispatch = useDispatch();
  const filters = useSelector(getFiltersState);

  message.config({
    top: 70,
    duration: 6,
  });

  useEffect(() => {
    if (!filters) {
      getFiltersList([]).then(({ data }) => {
        dispatch(setFiltersAction(data));
      });
    }
  }, [filters, dispatch]);

  return (
    <ThemeContextProvider>
      <GlobalStyles />
      <BrowserRouter>
        <MainLayout>
          <Switch>
            <Route path={PATHS.page}>
              <PrivateWrapper>
                <Pages />
              </PrivateWrapper>
            </Route>

            <Route exact path={PATHS.auth} component={ExternalLogin} />
            <Redirect to={`${PATHS.pages}/1`} />
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </ThemeContextProvider>
  );
};

export default Router;
