import { ClearOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { OptionData } from 'rc-select/lib/interface';
import { FC } from 'react';
import { Scrollbars } from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'src/hooks';
import {
  AxisInfo,
  Filters,
  Group,
  Indicators,
  IndicatorsValues,
} from 'src/services/AnalyticsService/AnalyticsService.types';
import { GraphDisplayTypes } from 'src/services/PageService/PageService.types';
import { getFiltersValues } from 'src/utils';

import { SelectOptions } from '../../Fields/Fields.types';

import { FILTERS_DEPENDENCIES, isFiltersKey } from './IndicatorsFiltersForm.constants';
import { CollapseWrapper, Panel, ResetFilters } from './IndicatorsFiltersForm.styled';
import {
  ChangeIndicatorsValuesType,
  ChartType,
  DisplayType,
  IndicatorsFiltersFormProps,
} from './IndicatorsFiltersForm.types';
import { IndicatorsFiltersFormItem } from './IndicatorsFiltersFormItem';

const IndicatorsFiltersForm: FC<IndicatorsFiltersFormProps> = ({
  indicators,
  filtersValues,
  setFiltersValues,
  onResetIndicators,
  displayTypes,
  onChangeDisplayType,
  onFiltersChange,
  isLoading,
}) => {
  const { t } = useTranslation('filters');
  const debounce = useDebounce();

  const onChangeFiltersValues = (filter: Filters, value: any /* TODO: any добавить тип */, indicator?: Indicators) => {
    const resultFilters = getFiltersValues(filter, value, filtersValues, indicator);

    const isEmptyValue = !value || (Array.isArray(value) && !value.length);

    if (isEmptyValue && isFiltersKey(filter.key)) {
      const dependencies = FILTERS_DEPENDENCIES[filter.key];

      if (dependencies?.length) {
        resultFilters.filters = resultFilters?.filters?.filter(
          ({ key }) => !isFiltersKey(key) || !dependencies?.includes(key)
        );
      }
    }

    setFiltersValues(resultFilters);

    debounce(() => onFiltersChange?.(resultFilters), 500);
  };

  const onChangeIndicatorsValues: ChangeIndicatorsValuesType = (indicator, key, value, groupOptions) => {
    const restIndicators = filtersValues.indicators?.filter(
      (itemIndicator: IndicatorsValues) => itemIndicator.key === indicator.key
    )[0];
    const values: IndicatorsValues = {
      ...restIndicators,
      key: indicator.key,
      display_name: indicator.display_name,
      [key]: value,
    };

    if (key === 'group') {
      values.groupFullData = groupOptions
        ? {
            key: (groupOptions as OptionData).key?.toString() || '',
            display_name: (groupOptions as OptionData).children?.toString() || '',
          }
        : undefined;
    }

    const filteredIndicators =
      filtersValues.indicators?.filter((itemIndicator: IndicatorsValues) => itemIndicator.key !== indicator.key) || [];

    setFiltersValues({
      ...filtersValues,
      indicators: [...filteredIndicators, values],
    });
  };

  const collapseActive = filtersValues?.indicators?.map((indicator: IndicatorsValues) => indicator.key);

  const displayTypeOptions: SelectOptions[] = [
    {
      title: t('displayTypeVariants.static'),
      value: DisplayType.static,
    },
    {
      title: t('displayTypeVariants.dynamic'),
      value: DisplayType.dynamic,
    },
  ];

  const chartTypeOptions: SelectOptions[] = [
    {
      title: t('chartTypeVariants.line'),
      value: ChartType.line,
    },
    {
      title: t('chartTypeVariants.column'),
      value: ChartType.column,
    },
    {
      title: t('chartTypeVariants.stack'),
      value: ChartType.stack,
    },
    {
      title: t('chartTypeVariants.pie'),
      value: ChartType.pie,
    },
    {
      title: t('chartTypeVariants.waterfall'),
      value: ChartType.waterfall,
    },
  ];

  return (
    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
      <ResetFilters>
        <Button icon={<ClearOutlined />} onClick={onResetIndicators}>
          {t('reset')}
        </Button>
      </ResetFilters>
      <CollapseWrapper ghost expandIconPosition={'right'} defaultActiveKey={collapseActive || []}>
        {indicators &&
          indicators.map((indicator: Indicators) => {
            const itemIndicatorsValues = filtersValues.indicators?.filter(
              (itemIndicator) => itemIndicator.key === indicator.key
            )[0];

            let axisGroupDate: string | null | undefined = null;

            if (itemIndicatorsValues?.axis_groups) {
              const axes = indicator.axes.find(
                (itemAxisInfo: AxisInfo) => itemAxisInfo.key === itemIndicatorsValues?.axes
              );

              if (axes?.axis_item_type === 'date') {
                axisGroupDate = axes.axis_groups.find(
                  (itemAxisGroups: Group) => itemAxisGroups.key === itemIndicatorsValues?.axis_groups
                )?.display_name;
              }
            }

            const displayType = (displayTypes?.find((type) => type.key === indicator.key)?.value as GraphDisplayTypes)
              ?.displayType;

            return (
              <Panel header={indicator.display_name} key={indicator.key}>
                <IndicatorsFiltersFormItem
                  indicator={indicator}
                  chartTypeOptions={chartTypeOptions}
                  displayTypeOptions={displayTypeOptions}
                  filtersValues={filtersValues}
                  displayType={displayType}
                  itemIndicatorsValues={itemIndicatorsValues}
                  axisGroupDate={axisGroupDate}
                  isLoading={isLoading}
                  onChangeDisplayType={onChangeDisplayType}
                  onChangeFiltersValues={onChangeFiltersValues}
                  onChangeIndicatorsValues={onChangeIndicatorsValues}
                />
              </Panel>
            );
          })}
      </CollapseWrapper>
    </Scrollbars>
  );
};

export default IndicatorsFiltersForm;
