import { Form, Select } from 'antd';
import { FC } from 'react';

import SelectPicker from '../SelectPicker';

import { SelectWrapper } from './Fields.styled';
import { SelectFieldProps, SelectOptions } from './Fields.types';

const SelectField: FC<SelectFieldProps> = ({
  placeholder,
  name,
  rules,
  options,
  mode,
  onChange,
  emptySelect,
  defaultValue,
  ...rest
}) => (
  <SelectWrapper>
    <Form.Item name={name} rules={rules}>
      <SelectPicker
        defaultValue={defaultValue}
        placeholder={placeholder}
        mode={mode}
        onChange={(value) => onChange && value && onChange(value)}
        {...rest}
      >
        {emptySelect && <Select.Option value={emptySelect.value}>{emptySelect.title}</Select.Option>}
        {options.map((option: SelectOptions) => (
          <Select.Option value={option.value} key={option.value}>
            {option.title}
          </Select.Option>
        ))}
      </SelectPicker>
    </Form.Item>
  </SelectWrapper>
);

export default SelectField;
