import { Tabs, Button } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const ActionButton = styled(Button)`
  font-weight: 400;
  font-size: 18px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  border-radius: 8px;
  padding: 0 12px;
  margin-bottom: 8px;
  margin-left: 5px;
  cursor: pointer;
  border: none;
  color: ${staticColors.white};
  background: ${({ theme }) => theme.export.background};

  &:focus {
    color: ${staticColors.white};
    background: ${({ theme }) => theme.export.background};
  }

  &:hover {
    color: ${staticColors.white};
    background: ${({ theme }) => theme.export.backgroundHover};
  }

  &[disabled],
  &[disabled]:hover,
  &[disabled]:focus,
  &[disabled]:active {
    background: #e9eaeb;

    .ant-spin {
      height: 20px;
    }
  }
`;

export const TabsContentItem = styled(Tabs.TabPane)`
  min-height: calc(100vh - 211px);
`;
