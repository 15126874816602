import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import moment, { Moment } from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { isPeriodExact } from 'src/utils';

import { ButtonChangePeriod, Container } from './RangePicker.styled';
import { RangePeriod, RangePickerProps } from './RangePicker.types';

const dateFormat = 'DD.MM.YYYY';

const RangePicker: FC<RangePickerProps> = ({ value, format, locale, disabled = false, onChange, minPeriod }) => {
  const { t } = useTranslation('filters');
  const [rangePeriod, setRangePeriod] = useState<RangePeriod>(RangePeriod.custom);

  const ranges = useMemo(() => {
    const rangesObj: Record<string, [Moment, Moment]> = {};

    if (!(minPeriod === RangePeriod.month || minPeriod === RangePeriod.quarter || minPeriod === RangePeriod.year)) {
      rangesObj[t('datePeriod.today')] = [moment(), moment()];
      rangesObj[t('datePeriod.thisWeek')] = [moment().startOf('week'), moment().endOf('week')];
    }

    if (!(minPeriod === RangePeriod.quarter || minPeriod === RangePeriod.year)) {
      rangesObj[t('datePeriod.thisMonth')] = [moment().startOf('month'), moment().endOf('month')];
    }

    if (minPeriod !== RangePeriod.year) {
      rangesObj[t('datePeriod.thisQuarter')] = [moment().startOf('quarter'), moment().endOf('quarter')];
    }

    rangesObj[t('datePeriod.thisYear')] = [moment().startOf('year'), moment().endOf('year')];

    return rangesObj;
  }, [t, minPeriod]);

  const onPrevPeriodClick = () => {
    if (value) {
      switch (rangePeriod) {
        case RangePeriod.day: {
          onChange([
            moment(moment(value[0]).subtract({ days: 1 }).startOf('day').valueOf()).format(dateFormat),
            moment(moment(value[1]).subtract({ days: 1 }).endOf('day').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.week: {
          onChange([
            moment(value[0].subtract({ weeks: 1 }).startOf('week').valueOf()).format(dateFormat),
            moment(value[1].subtract({ weeks: 1 }).endOf('week').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.month: {
          onChange([
            moment(value[0].subtract({ months: 1 }).startOf('month').valueOf()).format(dateFormat),
            moment(value[1].subtract({ months: 1 }).endOf('month').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.quarter: {
          onChange([
            moment(value[0].subtract({ quarters: 1 }).startOf('quarter').valueOf()).format(dateFormat),
            moment(value[1].subtract({ quarters: 1 }).endOf('quarter').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.year: {
          onChange([
            moment(value[0].subtract({ years: 1 }).startOf('year').valueOf()).format(dateFormat),
            moment(value[1].subtract({ years: 1 }).endOf('year').valueOf()).format(dateFormat),
          ]);
          break;
        }
        default: {
          onChange([
            moment(value[0].valueOf() - (value[1].valueOf() - value[0].valueOf())).format(dateFormat),
            moment(value[0]).format(dateFormat),
          ]);
          break;
        }
      }
    }
  };

  const onNextPeriodClick = () => {
    if (value) {
      switch (rangePeriod) {
        case RangePeriod.day: {
          onChange([
            moment(value[0].add({ days: 1 }).startOf('day').valueOf()).format(dateFormat),
            moment(value[1].add({ days: 1 }).endOf('day').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.week: {
          onChange([
            moment(value[0].add({ weeks: 1 }).startOf('week').valueOf()).format(dateFormat),
            moment(value[1].add({ weeks: 1 }).endOf('week').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.month: {
          onChange([
            moment(value[0].add({ months: 1 }).startOf('month').valueOf()).format(dateFormat),
            moment(value[1].add({ months: 1 }).endOf('month').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.quarter: {
          onChange([
            moment(value[0].add({ quarters: 1 }).startOf('quarter').valueOf()).format(dateFormat),
            moment(value[1].add({ quarters: 1 }).endOf('quarter').valueOf()).format(dateFormat),
          ]);
          break;
        }
        case RangePeriod.year: {
          onChange([
            moment(value[0].add({ years: 1 }).startOf('year').valueOf()).format(dateFormat),
            moment(value[1].add({ years: 1 }).endOf('year').valueOf()).format(dateFormat),
          ]);
          break;
        }
        default: {
          onChange([
            moment(value[1]).format(dateFormat),
            moment(value[1].valueOf() + (value[1].valueOf() - value[0].valueOf())).format(dateFormat),
          ]);
          break;
        }
      }
    }
  };

  const onDateChange = (dates: [Moment | null, Moment | null] | null, dateStrings: [string, string]) => {
    if (dates && dates[0] && dates[1]) {
      const start = dates[0];
      const end = dates[1];

      if (isPeriodExact(start, end, 'quarter')) {
        setRangePeriod(RangePeriod.quarter);
        onChange(dateStrings);
        return;
      }

      if (isPeriodExact(start, end, 'month')) {
        setRangePeriod(RangePeriod.month);
        onChange(dateStrings);
        return;
      }

      if (isPeriodExact(start, end, 'week')) {
        setRangePeriod(RangePeriod.week);
        onChange(dateStrings);
        return;
      }

      if (isPeriodExact(start, end, 'year')) {
        setRangePeriod(RangePeriod.year);
        onChange(dateStrings);
        return;
      }

      if (!moment(end).diff(start, 'days')) {
        setRangePeriod(RangePeriod.day);
        onChange(dateStrings);
        return;
      }
    }

    setRangePeriod(RangePeriod.custom);
    onChange(dateStrings);
  };

  useEffect(() => {
    if (value && value[0] && value[1]) {
      const start = value[0];
      const end = value[1];

      if (isPeriodExact(start, end, 'quarter')) {
        setRangePeriod(RangePeriod.quarter);
        return;
      }

      if (isPeriodExact(start, end, 'month')) {
        setRangePeriod(RangePeriod.month);
        return;
      }

      if (isPeriodExact(start, end, 'week')) {
        setRangePeriod(RangePeriod.week);
        return;
      }

      if (isPeriodExact(start, end, 'year')) {
        setRangePeriod(RangePeriod.year);
        return;
      }

      if (!moment(end).diff(start, 'days')) {
        setRangePeriod(RangePeriod.day);
        return;
      }

      setRangePeriod(RangePeriod.custom);
    }
  }, [value]);

  return (
    <Container>
      <ButtonChangePeriod icon={<LeftOutlined />} onClick={onPrevPeriodClick} disabled={disabled} />
      <DatePicker.RangePicker
        value={value}
        format={format}
        locale={locale}
        onOpenChange={() => {
          // setTimeout нужен, чтобы при 1м открытии отрисовались periods
          setTimeout(() => {
            const periods = document.querySelectorAll('.ant-picker-ranges .ant-tag');
            periods.forEach((item) => {
              switch (item.innerHTML) {
                case t('datePeriod.today'):
                  item.addEventListener('click', () => setRangePeriod(RangePeriod.day));
                  break;
                case t('datePeriod.thisWeek'):
                  item.addEventListener('click', () => setRangePeriod(RangePeriod.week));
                  break;
                case t('datePeriod.thisMonth'):
                  item.addEventListener('click', () => setRangePeriod(RangePeriod.month));
                  break;
                case t('datePeriod.thisQuarter'):
                  item.addEventListener('click', () => setRangePeriod(RangePeriod.quarter));
                  break;
                case t('datePeriod.thisYear'):
                  item.addEventListener('click', () => setRangePeriod(RangePeriod.year));
                  break;
              }
            });
          }, 0);
        }}
        onChange={onDateChange}
        ranges={ranges}
        disabled={disabled}
      />
      <ButtonChangePeriod icon={<RightOutlined />} onClick={onNextPeriodClick} disabled={disabled} />
    </Container>
  );
};

export default RangePicker;
