import { createContext, useState, FC } from 'react';
import { DefaultTheme } from 'styled-components';

import { darkTheme, lightTheme } from 'src/styles/theme';
import { isDarkTheme } from 'src/utils';

type ThemeContextType = {
  isDarkMode: boolean;
  theme: DefaultTheme;
  toggleTheme(): void;
};

export const ThemeContext = createContext<ThemeContextType>({
  isDarkMode: isDarkTheme(),
  theme: isDarkTheme() ? darkTheme : lightTheme,
  toggleTheme: () => null,
});

export const ThemeContextProvider: FC = (props) => {
  const { children } = props;
  const [isDarkMode, setIsDarkMode] = useState(isDarkTheme());
  const [theme, setTheme] = useState(isDarkTheme() ? darkTheme : lightTheme);

  const toggleTheme = (): void => {
    const newTheme = isDarkMode ? lightTheme : darkTheme;

    localStorage.setItem('theme', isDarkMode ? 'light' : 'dark');
    setIsDarkMode(!isDarkMode);
    setTheme(newTheme);
  };

  const value = {
    isDarkMode,
    theme,
    toggleTheme,
  };

  return <ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>;
};
