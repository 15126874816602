import { UserActionTypes, UserActions } from './types';

export const setUserRoleAction = (isSuperuser: boolean): UserActions => ({
  type: UserActionTypes.setUserRole,
  payload: isSuperuser,
});

export const setUserCanOpenReportsAction = (canOpenReports: boolean): UserActions => ({
  type: UserActionTypes.setUserCanOpenReports,
  payload: canOpenReports,
});

export const setUserIsAuthorizedAction = (isAuthorized: boolean): UserActions => ({
  type: UserActionTypes.setUserAuth,
  payload: isAuthorized,
});
