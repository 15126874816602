import styled from 'styled-components';

export const FormWrapper = styled.div<{ padding?: string }>`
  min-height: auto !important;
  padding: ${(props) => props.padding ?? '25px 30px'};
  color: ${({ theme }) => theme.filters.text};
`;

export const FieldWrapper = styled.div`
  color: ${({ theme }) => theme.filters.text};
  margin-bottom: 8px;

  label {
    display: block;
    font-size: 16px;
    line-height: 19px;
    letter-spacing: 0.02em;
    margin-bottom: 6px;
  }

  .ant-picker,
  .ant-select-multiple {
    border: 0;
    width: 100%;
    color: ${({ theme }) => theme.input.color};
    background: ${({ theme }) => theme.input.background};
  }

  .ant-picker-input > input,
  .ant-picker-suffix {
    color: ${({ theme }) => theme.input.color};
  }

  .ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: ${({ theme }) => theme.input.background};
    border: 0;
  }

  .ant-select-multiple .ant-select-selection-item {
    background: ${({ theme }) => theme.filters.selection};
    color: ${({ theme }) => theme.input.color};
    border: 0;
    align-items: center;
  }

  .ant-select {
    width: 100%;
    color: ${({ theme }) => theme.input.color};

    &:not(.ant-select-customize-input) {
      .ant-select-selector {
        background: ${({ theme }) => theme.input.background};
        outline: none;
      }
    }

    &-arrow {
      color: ${({ theme }) => theme.input.color};
    }

    &-dropdown {
      background: ${({ theme }) => theme.input.background};
    }
  }
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-picker-focused {
    box-shadow: none;
  }
`;
