import { FilterOutlined, LeftOutlined, RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { HandlerProps, ReflexContainer, ReflexSplitter } from 'react-reflex';

import { CommonFiltersForm, Loader, ReportForm } from 'src/components';
import SortingsForm from 'src/components/forms/SortingsForm';
import ReportWell from 'src/components/ReportWell/ReportWell';
import ReportWellDetails from 'src/components/ReportWellDetails/ReportWellDetails';
import { useDebounce, useWindowSize } from 'src/hooks';
import { getFiltersList } from 'src/services/AnalyticsService';
import { FiltersValues, ItemFilterTypes } from 'src/services/AnalyticsService/AnalyticsService.types';
import { getPageById, getWellDetailsById, updatePage } from 'src/services/PageService';
import { addOrUpdateFrontend, addOrUpdateFrontendValue } from 'src/services/PageService/PageService.helpers';
import { FrontendValue } from 'src/services/PageService/PageService.types';
import { SortingBy, WellData, WellDetails } from 'src/services/ReportsService/ReportsService.types';
import { getPageMapState, updatePageAction } from 'src/store/pages';
import { getFiltersState, setFiltersAction } from 'src/store/reports';
import { getFiltersValues, parseDataToFilters, prepareDataGraphs, prepareFilters, showErrorMessage } from 'src/utils';

import {
  ContentPadding,
  ContentWrapper,
  DrawerWrapper,
  FilterLabel,
  FilterPanel,
  ReportLoading,
  ReportWells,
  ReportWrapper,
  ToggleLeftPanel,
  ToggleRightPanel,
} from './Report.styled';
import { ReportProps } from './Report.types';

const UPDATE_DELAY = 1000 * 60 * 60 * 4; // 4 hours

const Report: FC<ReportProps> = ({ id }) => {
  const { t } = useTranslation('report');
  const debounce = useDebounce();
  const windowSize = useWindowSize();

  const filters = useSelector(getFiltersState);
  const dispatch = useDispatch();

  const { pageMap } = useSelector(getPageMapState(id));
  // на этой странице в charts.series.value приходит объект типа WellData
  const wells = useMemo(
    () =>
      pageMap?.graphs?.[0]?.data?.charts?.[0]?.series?.[0]?.data?.map((data) => data.value) as WellData[] | undefined,
    [pageMap]
  );
  const [sortedWells, setSortedWells] = useState<WellData[] | undefined>(wells);
  const [isVisibleLeftPanel, setIsVisibleLeftPanel] = useState(window.innerWidth >= 768);
  const [flexLeftPanel, setFlexLeftPanel] = useState(0.187113);
  const [isVisibleRightPanel, setIsVisibleRightPanel] = useState(false);
  const [flexRightPanel, setFlexRightPanel] = useState(0.187113);

  const [isLoadFilters, setIsLoadFilters] = useState<boolean>(!filters);
  const [isPageLoading, setPageLoading] = useState(false);
  const [isReportsLoading, setIsReportsLoading] = useState(false);
  const [isLoadWellDetails, setIsLoadWellDetails] = useState(false);
  const [selectedWellId, setSelectedWellId] = useState<string | undefined>();
  const [selectedWellDetails, setSelectedWellDetails] = useState<null | WellDetails>(null);

  const [filterValues, setFilterValues] = useState<FiltersValues>(parseDataToFilters(pageMap?.graphs || [], filters));
  const [sortingValue, setSortingValue] = useState<FrontendValue[]>([]);

  const onToggleLeftPanel = () => {
    setIsVisibleLeftPanel((prev) => !prev);
    setTimeout(() => {
      setFlexLeftPanel((prev) => prev - 0.0000001);
    }, 0);
  };

  const onStopResizeLeftPanel = ({ component }: HandlerProps) => {
    setFlexLeftPanel(component.props.flex || 0.20561);
  };

  const onToggleRightPanel = () => {
    setIsVisibleRightPanel((prev) => !prev);
    setTimeout(() => {
      setFlexRightPanel((prev) => prev - 0.0000001);
    }, 0);
  };

  const onStopResizeRightPanel = ({ component }: HandlerProps) => {
    setFlexRightPanel(component.props.flex || 0.20561);
  };

  const onClickReportWell = (id: string) => {
    setSelectedWellId(id);
  };

  const [isInitialDateFilter, setIsInitialDateFilter] = useState(true);

  const fetchFiltersList = useCallback(
    async (filtersValues?: FiltersValues) => {
      setIsLoadFilters(true);
      try {
        const preparedFilters = filtersValues?.filters ? prepareFilters(filtersValues.filters) : [];
        const { data } = await getFiltersList(preparedFilters);
        dispatch(setFiltersAction(data));
      } catch (error: any) {
        dispatch(setFiltersAction(null));
        showErrorMessage(error?.message);
      }
      setIsLoadFilters(false);
    },
    [dispatch]
  );

  const getPage = useCallback(() => {
    setPageLoading(true);
    getPageById(id, true)
      .then(({ data }) => {
        const dataFilters = parseDataToFilters(data.page_map?.graphs || [], filters);
        dispatch(updatePageAction(id, data));

        if (isInitialDateFilter) {
          const dateFilter = filters?.common_filters?.filter((filter) => filter.item_type === ItemFilterTypes.date)[0];

          if (dateFilter) {
            const resultFilters = getFiltersValues(dateFilter, [moment(), moment()], dataFilters);
            setFilterValues(() => resultFilters);
          }

          setIsInitialDateFilter(false);
          fetchFiltersList();
        } else {
          setFilterValues(dataFilters);
        }
      })
      .finally(() => setPageLoading(false));
  }, [id, dispatch, filters, isInitialDateFilter, setIsInitialDateFilter]);

  const onChangeSortingValue = (sorting: SortingBy) => {
    const newFrontend = addOrUpdateFrontend(
      { key: String(id), value: sorting },
      pageMap?.frontend,
      'report_wells_sorting'
    );
    const prepareData = prepareDataGraphs(filterValues);
    updatePage(
      id,
      {
        page_map: {
          layout: {},
          graphs: prepareData,
          frontend: newFrontend,
        },
      },
      true
    ).then(() => {
      setSortingValue((sortings) => {
        return addOrUpdateFrontendValue({ key: String(id), value: sorting }, sortings);
      });
    });
  };

  const getWellName = (wellId: string | undefined) => {
    const name =
      sortedWells?.find((well) => well.well_id === wellId)?.name ??
      sortedWells?.find((well) => well.well_id === wellId)?.field;

    return name ?? null;
  };

  useEffect(() => {
    if (!filters) {
      fetchFiltersList();
    }
  }, [fetchFiltersList, filters]);

  useEffect(() => {
    if (id && !pageMap && filters && !isPageLoading) {
      getPage();
    }
  }, [getPage, id, pageMap, filters, isPageLoading]);

  // Updating data every 4 hours
  useEffect(() => {
    const interval = window.setInterval(() => {
      if (id && filters && !isPageLoading) {
        getPage();
      }
    }, UPDATE_DELAY);

    return () => {
      window.clearInterval(interval);
    };
  }, [getPage, filters, isPageLoading, id]);

  useEffect(() => {
    if (filterValues) {
      setIsReportsLoading(true);
      const prepareData = prepareDataGraphs(filterValues);
      if (id && prepareData.length > 0) {
        debounce(() => {
          updatePage(
            id,
            {
              page_map: {
                layout: {},
                graphs: prepareData,
                frontend: pageMap?.frontend || {},
              },
            },
            true
          ).then(({ data }) => {
            dispatch(updatePageAction(id, data));
            setIsReportsLoading(false);
          });
        }, 500);
      } else {
        setIsReportsLoading(false);
      }
    }
  }, [id, filters, dispatch, debounce]);

  useEffect(() => {
    if (windowSize.width > 0 && windowSize.width <= 768) {
      setIsVisibleLeftPanel(false);
      setIsVisibleRightPanel(false);
    }
  }, [windowSize.width]);

  useEffect(() => {
    if (selectedWellId) {
      setIsLoadWellDetails(true);
      setIsVisibleRightPanel(true);
      getWellDetailsById(selectedWellId)
        .then(({ data }) => {
          setSelectedWellDetails(data);
        })
        .finally(() => {
          setIsLoadWellDetails(false);
        });
    }
  }, [selectedWellId]);

  useEffect(() => {
    if (pageMap && pageMap.frontend?.report_wells_sorting) {
      setSortingValue(
        pageMap.frontend.report_wells_sorting.map((sorting) => ({
          key: sorting.key,
          value: sorting.value,
        }))
      );
    }
  }, [pageMap]);

  useEffect(() => {
    if (wells) {
      const currentSorting = sortingValue.find((sorting) => sorting.key === String(id))?.value as SortingBy;
      if (currentSorting) {
        if (currentSorting === SortingBy.without) {
          setSortedWells(wells);
        } else {
          const wellsCopy: WellData[] = JSON.parse(JSON.stringify(wells));
          setSortedWells(wellsCopy.sort((wellPrev, wellNext) => wellNext[currentSorting] - wellPrev[currentSorting]));
        }
      } else {
        setSortedWells([...wells]);
      }
    }
  }, [id, sortingValue, wells]);

  return (
    <ReportWrapper>
      <ToggleLeftPanel onClick={onToggleLeftPanel}>
        {isVisibleLeftPanel ? <LeftOutlined /> : <RightOutlined />}
        <FilterLabel>
          <FilterOutlined />
          {t('filter')}
        </FilterLabel>
      </ToggleLeftPanel>
      <ToggleRightPanel onClick={onToggleRightPanel}>
        {isVisibleRightPanel ? <RightOutlined /> : <LeftOutlined />}
        <FilterLabel>{t('information')}</FilterLabel>
      </ToggleRightPanel>
      <ReflexContainer orientation="vertical">
        <FilterPanel
          flex={isVisibleLeftPanel ? flexLeftPanel : 0}
          onStopResize={onStopResizeLeftPanel}
          minSize={isVisibleLeftPanel ? 150 : undefined}
          maxSize={350}
        >
          {!isPageLoading ? (
            <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
              <CommonFiltersForm
                filters={filters?.common_filters}
                filtersValues={filterValues || {}}
                setFiltersValues={setFilterValues}
                withSortingInPanel={true}
                onChange={fetchFiltersList}
                isLoading={isLoadFilters}
              />
              <SortingsForm
                onChangeSortingValue={onChangeSortingValue}
                sorting={
                  (sortingValue.find((sorting) => sorting.key === String(id))?.value as SortingBy) || SortingBy.without
                }
                withFiltersInPanel={true}
              />
              <ReportForm filtersValues={filterValues.filters} />
            </Scrollbars>
          ) : (
            <Loader />
          )}
        </FilterPanel>

        {isVisibleLeftPanel && <ReflexSplitter propagate={true} className="r-splitter" />}

        <ContentWrapper>
          {isReportsLoading && (
            <ReportLoading>
              <Loader />
            </ReportLoading>
          )}
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <ContentPadding>
              <ReportWells>
                {sortedWells &&
                  sortedWells.map((well: WellData) => (
                    <ReportWell
                      key={well.well_id}
                      wellData={well}
                      onClickPanel={onClickReportWell}
                      selected={selectedWellId === well.well_id}
                    />
                  ))}
              </ReportWells>
            </ContentPadding>
          </Scrollbars>
        </ContentWrapper>

        {isVisibleRightPanel && <ReflexSplitter propagate={true} className="r-splitter" />}

        <FilterPanel
          flex={isVisibleRightPanel ? flexRightPanel : 0}
          onStopResize={onStopResizeRightPanel}
          minSize={isVisibleRightPanel ? 350 : undefined}
          maxSize={600}
        >
          {!isLoadWellDetails ? (
            <ReportWellDetails
              wellDetails={selectedWellDetails ?? null}
              wellId={selectedWellId ?? null}
              name={getWellName(selectedWellId)}
            />
          ) : (
            <Loader />
          )}
        </FilterPanel>
      </ReflexContainer>

      <DrawerWrapper
        placement="left"
        closable={true}
        onClose={onToggleLeftPanel}
        visible={isVisibleLeftPanel}
        getContainer={false}
      >
        {!isPageLoading ? (
          <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
            <CommonFiltersForm
              filters={filters?.common_filters}
              filtersValues={filterValues || {}}
              setFiltersValues={setFilterValues}
              withSortingInPanel={true}
              onChange={fetchFiltersList}
              isLoading={isLoadFilters}
            />
            <SortingsForm
              onChangeSortingValue={onChangeSortingValue}
              sorting={
                (sortingValue.find((sorting) => sorting.key === String(id))?.value as SortingBy) || SortingBy.without
              }
              withFiltersInPanel={true}
            />
            <ReportForm filtersValues={filterValues.filters} />
          </Scrollbars>
        ) : (
          <Loader />
        )}
      </DrawerWrapper>
      <DrawerWrapper
        placement="right"
        closable={true}
        onClose={onToggleRightPanel}
        visible={isVisibleRightPanel}
        getContainer={false}
      >
        {!isLoadWellDetails ? (
          <ReportWellDetails
            wellDetails={selectedWellDetails ?? null}
            wellId={selectedWellId ?? null}
            name={getWellName(selectedWellId)}
          />
        ) : (
          <Loader />
        )}
      </DrawerWrapper>
    </ReportWrapper>
  );
};

export default Report;
