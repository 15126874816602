import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const GridItem = styled.div`
  background-color: white;
  padding: 0 8px 8px;
  border-radius: 8px;
  border: 1px solid lightgray;
  padding-top: 8px;
  background-color: ${({ theme }) => theme.chart.background};

  .react-resizable-handle {
    fill: ${({ theme }) => theme.colors.text} !important;
  }
`;

export const EmptyContent = styled.div`
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  min-height: calc(100vh - 211px);
  font-size: 26px;
  font-weight: bold;
  color: ${staticColors.blue};
`;

export const LoaderContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
`;

export const ButtonCollapseContainer = styled.div`
  position: fixed;
  right: 0;
  bottom: 8px;

  .ant-btn {
    background: none;
    border: none;
    box-shadow: none;
    opacity: 0.5;
    color: ${({ theme }) => theme.buttonCollapse.color};
  }

  .ant-btn:hover {
    opacity: 1;
  }

  .ant-btn svg {
    width: 24px;
    height: 24px;
  }

  [ant-click-animating-without-extra-node='true']::after {
    display: none;
  }
`;
