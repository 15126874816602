import { Divider } from 'antd';
import styled from 'styled-components';

import { staticColors } from '../../styles/theme';

export const SelectHeader = styled.div`
  display: flex;
  flex-flow: column;
  cursor: pointer;

  & .ant-checkbox-wrapper {
    width: 100%;
    margin: 0;
    padding: 5px 4px;
    transition: background 0.3s ease;

    & .ant-checkbox {
      display: none;
    }

    &:hover {
      background-color: ${staticColors.lightGray};
    }
  }
`;

export const HeaderDivider = styled(Divider)`
  margin: 0;
`;
