import { DownloadOutlined, UploadOutlined } from '@ant-design/icons';
import { Spin, Tooltip } from 'antd';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import { ConfirmModal, CreatePageForm, DraggableTabs, Loader, PublishDashboardForm } from 'src/components';
import { IPageFormValues } from 'src/components/forms/CreatePageForm/CreatePageForm.types';
import { IPublishDashboardFormValues } from 'src/components/forms/PublishDashboardForm/PublishDashboardForm.types';
import { PATHS } from 'src/constants';
import {
  createPage,
  createTemplateDashboard,
  editTemplateDashboard,
  getPageAsXLSX,
  getPageList,
  removePage,
} from 'src/services/PageService';
import {
  PageRequest,
  PageResponse,
  PageTypes,
  TemplateDashboardRequest,
} from 'src/services/PageService/PageService.types';
import {
  addPageAction,
  addTemplateDashboard,
  removePageAction,
  setPagesAction,
  updateTemplateDashboard,
} from 'src/store/pages/actions';
import { getPagesState, getViewModeState } from 'src/store/pages/getters';
import { getIsSuperuser } from 'src/store/user';
import { RouterIdParam } from 'src/types';
import { showErrorMessage, showSuccessMessage } from 'src/utils';
import { Analytics, Dashboard } from 'src/views/index';
import Report from 'src/views/Report/Report';

import { ActionButton, TabsContentItem } from './Pages.styled';

const Pages: FC = () => {
  const { t } = useTranslation('dashboard');
  const dispatch = useDispatch();

  const { id } = useParams<RouterIdParam>();
  const history = useHistory();

  const pages = useSelector(getPagesState);
  const isSuperuser = useSelector(getIsSuperuser);
  const pagesOrder = useMemo(
    () => pages.sort((a, b) => a.order - b.order).map((page) => ({ id: page.id, order: page.order })),
    [pages]
  );

  const [activeTab, setActiveTab] = useState<number>(Number(id));
  const [removedTab, setRemovedTab] = useState<number>();
  const [isShowCreatePageForm, setIsShowCreatePageForm] = useState(false);
  const [isRemoveModalOpened, setIsRemoveModalOpened] = useState(false);
  const [isShowPublishDashboardForm, setIsShowPublishDashboardForm] = useState(false);
  const [isPageTabsLoading, setIsPageTabsLoading] = useState(true);
  const [isCreateLoading, setIsCreateLoading] = useState(false);
  const [isUpdateLoading, setIsUpdateLoading] = useState(false);
  const [isRemoveLoading, setIsRemoveLoading] = useState(false);
  const [isFetching, setFetching] = useState(false);
  const viewMode = useSelector(getViewModeState);

  const activePage = pages.find((page) => page.id === activeTab);
  const pageType = activePage?.page_type;
  const pageMap = activePage?.page_map;

  const fetchPageTabs = useCallback(async () => {
    try {
      setIsPageTabsLoading(true);
      const { data } = await getPageList();
      dispatch(setPagesAction(data));
    } catch (error: any) {
      dispatch(setPagesAction([]));
      showErrorMessage(error?.message);
    }
    setIsPageTabsLoading(false);
  }, [dispatch]);

  useEffect(() => {
    setActiveTab(Number(id));
    history.push(`${PATHS.pages}/${id}`);
  }, [id, history]);

  useEffect(() => {
    fetchPageTabs();
  }, [fetchPageTabs]);

  useEffect(() => {
    if (pages.length && !pages.some((page) => page.id === activeTab)) {
      const newActiveTab = pages[0].id;
      setActiveTab(newActiveTab);
      history.push(`${PATHS.pages}/${newActiveTab}`);
    }
  }, [pages, activeTab, history]);

  const onChangeActiveTab = (newActiveTab: string) => {
    setActiveTab(Number(newActiveTab));
    history.push(`${PATHS.pages}/${newActiveTab}`);
  };

  const onExportHandler = async () => {
    setFetching(true);

    try {
      const { data } = await getPageAsXLSX(activeTab);
      const fileUrl = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = fileUrl;
      link.download = 'Report';
      link.click();
      link.remove();
    } finally {
      setFetching(false);
    }
  };

  const onEditTab = (targetKey: React.MouseEvent | React.KeyboardEvent | string, action: string) => {
    if (action === 'add') {
      setIsShowCreatePageForm(true);
    }
    if (action === 'remove') {
      setIsRemoveModalOpened(true);
      setRemovedTab(Number(targetKey));
    }
  };

  const onCreatePageTab = async (values: IPageFormValues) => {
    setIsCreateLoading(true);
    try {
      const newPageTab: PageRequest = {
        name: values.name,
        page_type: values.pageType,
      };
      if (values.templateId) {
        newPageTab.template_id = values.templateId;
      }
      const { data } = await createPage(newPageTab, true);
      dispatch(addPageAction(data));
      setActiveTab(data.id);
      history.push(`${PATHS.pages}/${data.id}`);
    } catch (error: any) {
      showErrorMessage(t('createForm.errors.global') + error?.message);
    }

    setIsShowCreatePageForm(false);
    setIsCreateLoading(false);
  };

  const onRemovePageTab = async () => {
    if (removedTab !== undefined) {
      setIsRemoveLoading(true);

      try {
        await removePage(removedTab);
        dispatch(removePageAction(removedTab));
        showSuccessMessage(t('closeForm.success'));
      } catch (error: any) {
        if (error?.response?.data?.detail) {
          showErrorMessage(t('closeForm.errors.global') + error?.response?.data?.detail);
        } else {
          showErrorMessage(t('closeForm.errors.global') + error?.message);
        }
      }

      setIsRemoveLoading(false);
      setIsRemoveModalOpened(false);
    }
  };

  const onCreateTemplateOfDashboard = async (values: IPublishDashboardFormValues) => {
    setIsCreateLoading(true);
    try {
      const newTemplateDashboard: TemplateDashboardRequest = {
        name: values.name,
        roles: values.roles,
        page_map: pageMap,
      };

      const { data } = await createTemplateDashboard(newTemplateDashboard);
      dispatch(addTemplateDashboard(data));
      showSuccessMessage(t('publishDashboardForm.messages.success', { name: data.name }));
    } catch (error: any) {
      showErrorMessage(t('publishDashboardForm.messages.errors', { error: error?.message }));
    }

    setIsShowPublishDashboardForm(false);
    setIsCreateLoading(false);
  };

  const onUpdateTemplateOfDashboard = async (id: number, values: IPublishDashboardFormValues) => {
    setIsUpdateLoading(true);
    try {
      const newTemplateDashboard: TemplateDashboardRequest = {
        name: values.name,
        roles: values.roles,
        page_map: pageMap,
      };
      const { data } = await editTemplateDashboard(id, newTemplateDashboard);
      dispatch(updateTemplateDashboard(data));
      showSuccessMessage(t('publishDashboardForm.messages.success', { name: data.name }));
    } catch (error: any) {
      showErrorMessage(t('publishDashboardForm.messages.errors', { error: error?.message }));
    }

    setIsShowPublishDashboardForm(false);
    setIsUpdateLoading(false);
  };

  const onCancelModals = () => {
    setIsShowCreatePageForm(false);
    setIsRemoveModalOpened(false);
    setIsShowPublishDashboardForm(false);
  };

  if (isPageTabsLoading) {
    return <Loader />;
  }

  return (
    <>
      <DraggableTabs
        defaultOrder={pagesOrder}
        type="editable-card"
        activeKey={`${activeTab}`}
        onEdit={onEditTab}
        destroyInactiveTabPane={true}
        onTabClick={onChangeActiveTab}
        viewMode={viewMode}
        pageType={pageType}
        tabBarExtraContent={
          <>
            {isSuperuser && pageType === PageTypes.dashboard && (
              <Tooltip placement="topLeft" title={t('publishDashboard')}>
                <ActionButton onClick={() => setIsShowPublishDashboardForm(true)}>
                  <UploadOutlined />
                </ActionButton>
              </Tooltip>
            )}
            {pageType !== PageTypes.report && (
              <Tooltip placement="topLeft" title={t('exportXLSX')}>
                <ActionButton onClick={onExportHandler} disabled={isFetching}>
                  {isFetching ? <Spin /> : <DownloadOutlined />}
                </ActionButton>
              </Tooltip>
            )}
          </>
        }
      >
        {pages &&
          pages.map((page: PageResponse) => (
            <TabsContentItem
              tab={
                window.innerWidth < 768 ? (
                  page.name
                ) : (
                  <Tooltip placement="topLeft" title={page.name}>
                    {page.name}
                  </Tooltip>
                )
              }
              key={page.id}
            >
              {page.page_type === PageTypes.dashboard && <Dashboard id={page.id} />}
              {page.page_type === PageTypes.analytics && <Analytics id={page.id} />}
              {page.page_type === PageTypes.report && <Report id={page.id} />}
            </TabsContentItem>
          ))}
      </DraggableTabs>

      <CreatePageForm
        isVisible={isShowCreatePageForm}
        isLoading={isCreateLoading}
        onCreate={onCreatePageTab}
        onCancel={onCancelModals}
      />
      <ConfirmModal
        isVisible={isRemoveModalOpened}
        isLoading={isRemoveLoading}
        onRemove={onRemovePageTab}
        onCancel={onCancelModals}
        title={t('closeForm.title')}
        okText={t('closeForm.closeButton')}
        cancelText={t('closeForm.cancelButton')}
      >
        {t('closeForm.content')}
      </ConfirmModal>

      <PublishDashboardForm
        isVisible={isShowPublishDashboardForm}
        isLoading={isCreateLoading || isUpdateLoading}
        onCreate={onCreateTemplateOfDashboard}
        onUpdate={onUpdateTemplateOfDashboard}
        onCancel={onCancelModals}
      />
    </>
  );
};

export default Pages;
