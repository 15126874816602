import { PagesActions, PagesActionTypes, PageState, ViewMode } from './types';

const InitialState: PageState = {
  pages: [],
  templates: [],
  viewMode: ViewMode.default,
};

const pageReducer = (state = InitialState, action: PagesActions): PageState => {
  switch (action.type) {
    case PagesActionTypes.setPages: {
      return {
        ...state,
        pages: action.payload,
      };
    }

    case PagesActionTypes.updatePage: {
      const updatedPages = state.pages.map((page) => {
        if (page.id === action.payload.id) {
          const hiddenChartSeries = action.payload.data.page_map?.frontend?.hidden_chart_series;

          return {
            ...page,
            page_map: {
              ...page.page_map,
              ...action.payload.data.page_map,
              frontend: {
                ...page.page_map?.frontend,
                ...action.payload.data.page_map?.frontend,
                hidden_chart_series:
                  action.payload.data.page_map?.graphs?.map((graph) => {
                    const foundSeries = hiddenChartSeries?.find(
                      ({ key }) => key === `${graph.layout_id}-${graph.graph.key}`
                    );

                    if (!foundSeries) {
                      return {
                        key: `${graph.layout_id}-${graph.graph.key}`,
                        value: [],
                      };
                    }

                    return {
                      ...foundSeries,
                      value: Array.isArray(foundSeries.value) ? foundSeries.value : [foundSeries.value],
                    };
                  }) || [],
              },
            },
          };
        }
        return page;
      });

      return {
        ...state,
        pages: updatedPages,
      };
    }

    case PagesActionTypes.addPage: {
      return {
        ...state,
        pages: [...state.pages, action.payload],
      };
    }

    case PagesActionTypes.removePage: {
      const updatedPages = state.pages.filter((page) => page.id !== action.payload);
      return {
        ...state,
        pages: updatedPages,
      };
    }

    case PagesActionTypes.updatePageLayout: {
      const updatedPages = state.pages.map((page) => {
        if (page.id === action.payload.id) {
          return {
            ...page,
            page_map: { ...page.page_map, graphs: page.page_map?.graphs || [], layout: action.payload.data },
          };
        }
        return page;
      });

      return {
        ...state,
        pages: updatedPages,
      };
    }

    case PagesActionTypes.setTemplates: {
      return {
        ...state,
        templates: action.payload,
      };
    }

    case PagesActionTypes.addTemplate: {
      return {
        ...state,
        templates: [...state.templates, action.payload],
      };
    }

    case PagesActionTypes.updateTemplate: {
      const updatedTemplates = state.templates.map((template) => {
        if (template.id === action.payload.id) {
          return {
            ...template,
            ...action.payload,
          };
        }
        return template;
      });
      return {
        ...state,
        templates: updatedTemplates,
      };
    }

    case PagesActionTypes.cleanPageMap: {
      const updatedPages = state.pages.map((page) => {
        return {
          id: page.id,
          name: page.name,
          page_type: page.page_type,
          order: page.order,
        };
      });
      return {
        ...state,
        pages: updatedPages,
      };
    }

    case PagesActionTypes.changeViewMode: {
      return {
        ...state,
        viewMode: action.payload,
      };
    }

    case PagesActionTypes.updateChartHiddenSeries: {
      const { pageId, chartKey, data } = action.payload;

      const updatedPages = state.pages.map((page) => {
        if (page.id !== pageId) {
          return page;
        }

        const hiddenSeries = page.page_map?.frontend?.hidden_chart_series;

        return {
          ...page,
          page_map: {
            ...page.page_map,
            frontend: {
              ...page.page_map?.frontend,
              hidden_chart_series: hiddenSeries?.map((chartValue) => {
                if (chartValue.key !== chartKey) {
                  return chartValue;
                }

                return { ...chartValue, ...data };
              }),
            },
          },
        };
      });

      return {
        ...state,
        pages: updatedPages,
      };
    }

    case PagesActionTypes.updateShowingValuesOnChart: {
      const { pageId, chartKey, data } = action.payload;

      const updatedPages = state.pages.map((page) => {
        if (page.id !== pageId) {
          return page;
        }

        const showingValuesOnChart = page.page_map?.frontend?.showing_values_on_chart;

        return {
          ...page,
          page_map: {
            ...page.page_map,
            frontend: {
              ...page.page_map?.frontend,
              showing_values_on_chart: showingValuesOnChart?.map((chartValue) => {
                if (chartValue.key !== chartKey) {
                  return chartValue;
                }

                return {
                  key: chartKey,
                  value: data,
                };
              }) || [{ key: chartKey, value: data }],
            },
          },
        };
      });

      return {
        ...state,
        pages: updatedPages,
      };
    }

    default:
      return state;
  }
};

export default pageReducer;
