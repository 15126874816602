import { Dropdown } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const DropdownApps = styled(Dropdown)`
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: ${staticColors.white};
  height: 44px;
  margin-right: 24px;
  border-radius: 8px;
  padding: 0 15px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;

  svg {
    margin-right: 11px;
  }

  &:hover {
    color: ${staticColors.white} !important;
    background: rgba(255, 255, 255, 0.2);
  }

  &.ant-dropdown-open {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const Overlay = styled.div`
  max-width: 335px;
  background: ${staticColors.white};
  box-shadow: 0px 4px 8px rgba(0, 59, 92, 0.1);
  border-radius: 4px;
  padding: 16px;
`;

export const Nav = styled.nav`
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const NavItem = styled.a`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 33.33%;
  margin-bottom: 10px;
  padding: 5px 0;
  border-radius: 4px;
  font-weight: bold;
  font-size: 13px;
  color: ${staticColors.black};
  transition: color, background-color 0.3s;

  &:hover {
    background-color: ${staticColors.background};
    color: ${staticColors.black};
  }
`;

export const GoHubLink = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: 220px;
  height: 40px;
  border-radius: 4px;
  font-weight: bold;
  color: ${staticColors.white};
  transition: background-color 0.3s;
  background-color: rgba(${staticColors.redRgba}, 0.85);

  &:hover {
    color: ${staticColors.white};
    background-color: ${staticColors.red};
  }

  svg {
    margin-right: 10px;
  }
`;

export const AppIcon = styled.img`
  width: 50px;
  height: 50px;
  object-fit: cover;
`;

export const EmptyApps = styled.div`
  font-weight: bold;
  margin-bottom: 10px;
  text-align: center;
`;
