import { Form, Input } from 'antd';
import { FC } from 'react';

import { InputWrapper } from './Fields.styled';
import { InputFieldProps } from './Fields.types';

const InputField: FC<InputFieldProps> = ({ placeholder, name, rules, defaultValue, ...rest }) => (
  <InputWrapper>
    <Form.Item name={name} rules={rules}>
      <Input placeholder={placeholder} defaultValue={defaultValue} {...rest} />
    </Form.Item>
  </InputWrapper>
);

export default InputField;
