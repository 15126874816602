import { message } from 'antd';
import i18next from 'i18next';
import moment from 'moment';
import { ReactNode } from 'react';

import { RangePeriod } from 'src/components/RangePicker/RangePicker.types';
import { SeriesValue } from 'src/services/PageService/PageService.types';

export const getLocale = (): string => window.localStorage.getItem('i18nextLng') || i18next.language || 'ru';

export const returnField = (ru: string, en: string) => {
  const locale = getLocale();
  return locale === 'ru' || locale === 'ru-RU' ? ru : en;
};

export const isDarkTheme = (): boolean => {
  const localTheme = localStorage.getItem('theme');

  if (localTheme) {
    return localTheme === 'dark';
  } else if (window.matchMedia) {
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  } else {
    return false;
  }
};

export const parseDate = (key: string): string => {
  const dateRexExp = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/;

  if (dateRexExp.test(key)) {
    return key.replace(/T[0-9]{2}:\d{2}:\d{2}/, '');
  }

  return key;
};

export const getLocaleDate = (data: SeriesValue): string => {
  if (data.type === 'date') {
    return new Date(data.key).toLocaleDateString('ru-Ru');
  }

  return data.key;
};

export const getDaysArrayByMonth = () => {
  const daysInMonth = moment().daysInMonth();
  return Array(daysInMonth)
    .fill(null)
    .map((_, idx) => {
      return moment().date(daysInMonth - idx);
    });
};

export const getMonthsArrayByYear = () => {
  return Array(12)
    .fill(null)
    .map(function (_, i) {
      return moment().locale(getLocale()).month(i);
    });
};

export const getQuartersArrayByYear = () => {
  return Array(4)
    .fill(null)
    .map(function (_, i) {
      return moment().quarter(i);
    });
};

export const getDynamicPeriodValues = (seriesData: SeriesValue[], updatePeriod: string) => {
  if (updatePeriod === RangePeriod.day) {
    return getDaysArrayByMonth()
      .reverse()
      .map((day) => ({
        key: day.format('DD.MM.YYYY'),
        value: 0,
        type: 'string',
      }))
      .map((day) => {
        const dataValues = seriesData.filter((data) => day.key.slice(0, 5) === data.key);

        if (dataValues.length) {
          return dataValues.map((dataValue) => ({ ...day, value: dataValue.value, type: dataValue.type }));
        }

        return day;
      });
  }
  if (updatePeriod === RangePeriod.month) {
    return getMonthsArrayByYear()
      .map((month) => ({
        key: month.format('MMMM')[0].toUpperCase() + month.format('MMMM').slice(1),
        value: 0,
        type: 'string',
      }))
      .map((month) => {
        const dataValues = seriesData.filter((data) => month.key === data.key);

        if (dataValues.length) {
          return dataValues.map((dataValue) => ({ ...month, value: dataValue.value, type: dataValue.type }));
        }

        return month;
      });
  }
  if (updatePeriod === RangePeriod.quarter) {
    const quarters = getQuartersArrayByYear()
      .map((quarter) => {
        switch (quarter.format('Q')) {
          case '1': {
            return returnField('I квартал', 'I quarter');
          }
          case '2': {
            return returnField('II квартал', 'II quarter');
          }
          case '3': {
            return returnField('III квартал', 'III quarter');
          }
          case '4': {
            return returnField('IV квартал', 'IV quarter');
          }
          default: {
            return '';
          }
        }
      })
      .sort();

    return quarters
      .map((quarter) => ({
        key: quarter,
        value: 0,
        type: 'string',
      }))
      .map((quarter) => {
        const dataValues = seriesData.filter((data) => quarter.key === data.key);

        if (dataValues.length) {
          return dataValues.map((dataValue) => ({ ...quarter, value: dataValue.value, type: dataValue.type }));
        }

        return quarter;
      });
  }
};

export const getStaticPeriodValues = (seriesValues: SeriesValue[], updatePeriod: string) => {
  const currentDate = moment();
  if (updatePeriod === RangePeriod.day) {
    return seriesValues.slice(0, Number(currentDate.format('DD')));
  }

  if (updatePeriod === RangePeriod.month) {
    return seriesValues.slice(0, Number(currentDate.format('MM')));
  }

  if (updatePeriod === RangePeriod.quarter) {
    return seriesValues.slice(0, Number(currentDate.format('Q')));
  }

  return seriesValues;
};

export const getEndPeriodDate = (periodName: string): number => {
  switch (periodName) {
    case RangePeriod.day: {
      return moment().subtract(1, 'minute').endOf('day').valueOf();
    }
    case RangePeriod.week: {
      return moment().subtract(1, 'minute').endOf('week').valueOf();
    }
    case RangePeriod.month: {
      return moment().subtract(1, 'minute').endOf('month').valueOf();
    }
    case RangePeriod.quarter: {
      return moment().subtract(1, 'minute').endOf('quarter').valueOf();
    }
    case RangePeriod.year: {
      return moment().subtract(1, 'minute').endOf('year').valueOf();
    }
    default: {
      return moment().subtract(1, 'minute').endOf('day').valueOf();
    }
  }
};

export const getMinUpdatedPeriod = (updatedPeriods: (string | null | undefined)[]): string | null => {
  if (updatedPeriods) {
    if (updatedPeriods.includes(RangePeriod.day)) {
      return RangePeriod.day;
    }

    if (updatedPeriods.includes(RangePeriod.month)) {
      return RangePeriod.month;
    }

    if (updatedPeriods.includes(RangePeriod.quarter)) {
      return RangePeriod.quarter;
    }

    if (updatedPeriods.includes(RangePeriod.year)) {
      return RangePeriod.year;
    }
  }

  return null;
};

export const showSuccessMessage = (content: ReactNode) => {
  message.success({
    content,
    className: 'successMessage',
  });
};

export const showErrorMessage = (content: ReactNode) => {
  message.error({
    content,
    className: 'errorMessage',
  });
};

export const getArrayKey = (value: Array<unknown>) => [...value].sort().join(';');

export const getObjectKey = (value: Object) => JSON.stringify(value);
