export type UserState = {
  isAuthorized: boolean;
  isSuperuser: boolean;
  canOpenReports: boolean;
};

export enum UserActionTypes {
  setUserAuth = 'SET_USER_AUTH',
  setUserRole = 'SET_USER_ROLE',
  setUserCanOpenReports = 'SET_USER_CAN_OPEN_REPORTS',
}

export type UserActions =
  | { type: UserActionTypes.setUserAuth; payload: boolean }
  | { type: UserActionTypes.setUserRole; payload: boolean }
  | { type: UserActionTypes.setUserCanOpenReports; payload: boolean };
