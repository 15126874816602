import { Layouts } from 'react-grid-layout';

import { ChartType, DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';

import { FiltersValues, PurposeInfo } from '../AnalyticsService/AnalyticsService.types';

export enum PageTypes {
  dashboard = 'dashboard',
  analytics = 'analytics',
  report = 'report',
}

export type SeriesValue = {
  key: string;
  value: number;
  type: string;
  datetime?: string;
  color?: string;
  is_sum?: boolean;
};

export type SeriesData = {
  display_name: string;
  color: string;
  chart_type: string;
  data: SeriesValue[];
  value_axis_display_name: string;
  stack: string;
};

export type ValueAxis = {
  key: string;
  display_name: string;
};

export type CategoryData = {
  name: string;
  categories: CategoryData[] | string[];
};

export type ArgumentAxis = {
  display_name: string;
  is_opposite: boolean;
  categories: CategoryData[] | string[];
};

export type ChartData = {
  series: SeriesData[];
  value_axes: ValueAxis[];
  label: string;
  argument_axes: ArgumentAxis[];
  is_stacked: boolean;
  is_cumulative: boolean;
};

export type FilterDisplayInfo = {
  prop_name: string;
  value: string;
};

export type IndicatorData = {
  key: string;
  display_name: string;
  charts: ChartData[];
  filter_display_info?: FilterDisplayInfo[];
};

export enum GraphFilterRuleTypes {
  none = 'none',
  single = 'single',
  interval = 'interval',
  multiple = 'multiple',
  less_or_equal = 'less_or_equal',
}

export enum GraphFilterTypes {
  none = 'none',
  date = 'date',
  string = 'string',
  number = 'number',
  object = 'object',
}

export enum GraphKeyDatatypes {
  int = 'int',
  date = 'date',
  string = 'string',
  long = 'long',
}

export type GraphFilter = {
  key: string;
  filter_rule_type: GraphFilterRuleTypes;
  filter_type: GraphFilterTypes;
  key_datatype: GraphKeyDatatypes;
  value?: string;
  values_list?: number[];
  value_from?: string;
  value_to?: string;
};

export type GraphData = {
  key: string;
  display_name?: string;
  filters: GraphFilter[];
  group: { key: string; display_name: string };
  axis?: {
    key: string;
    axis_group_key?: string;
  };
  is_cumulative?: boolean;
  purpose_infos?: PurposeInfo[];
  chart_type?: ChartType;
};

export type GraphBlock = {
  name: string;
  graph: GraphData;
  is_fixed?: boolean;
  layout_id?: string;
  data_selections?: number[];
  data?: IndicatorData;
};

export type GraphDisplayTypes = {
  displayType: DisplayType;
  updatePeriod?: string | null;
};

// TODO: remove union type
export type FrontendValue<T = string | number | boolean | GraphDisplayTypes | string[]> = {
  key: string;
  value: T;
  hash?: string;
};

export type Frontend = {
  showing_values_on_chart?: FrontendValue<boolean>[];
  graph_display_types?: FrontendValue<GraphDisplayTypes>[];
  report_wells_sorting?: FrontendValue<string | number>[];
  chart_types?: FrontendValue<string | number>[];
  hidden_chart_series?: FrontendValue<string[]>[];
  chart_custom_names?: FrontendValue<string>[];
};

export type AnyFrontendValue = FrontendValue<string | number | boolean | GraphDisplayTypes | string[]>;

export type PageMap = {
  graphs?: GraphBlock[];
  layout?: Layouts;
  frontend?: Frontend;
};

export type PageRequest = {
  name: string;
  page_type: PageTypes;
  page_map?: PageMap;
  filtersValues?: FiltersValues;
  template_id?: number;
};

export type PageResponse = PageRequest & {
  id: number;
  order: number;
};

export type TemplateDashboardRequest = {
  name: string;
  roles: number[];
  page_map?: PageMap;
};

export type TemplateDashboardResponse = TemplateDashboardRequest & {
  id: number;
};
