import { Button } from 'antd';
import moment from 'moment';
import React, { FC } from 'react';
import Scrollbars from 'react-custom-scrollbars';
import { useTranslation } from 'react-i18next';

import { GOREPORT_URL, GOPERFORM_URL } from 'src/constants';
import { getToken } from 'src/utils';

import { InfoWrapper, Name, Row, Caption, Value, Actions, NoDetails } from './ReportWellDetails.styled';
import { ReportWellDetailsProps } from './ReportWellDetails.types';

const dateFormat = 'YYYY-MM-DD';

const getReportURL = (wellId: string | null, token: string | null, goReportURL: string | undefined, date: string) => {
  if (wellId && token && goReportURL && date) {
    return `${GOREPORT_URL}Account/ExternalLogin?token=${token}&redirectUri=%2FFolder%2FReportView%3FreportId%3D${wellId}%26date%3D${date}`;
  } else {
    return null;
  }
};

const getPerformURL = (wellId: string | null, token: string | null, goPerformURL?: string) => {
  if (wellId && token && goPerformURL) {
    return `${goPerformURL}Account/ExternalLogin?token=${token}&wellboreId=${wellId}`;
  }
  return null;
};

const ReportWellDetails: FC<ReportWellDetailsProps> = ({ wellDetails, wellId, name }) => {
  const { t: tReport } = useTranslation('report');

  const jwtToken = getToken();

  const reportURL = getReportURL(wellId, jwtToken, GOREPORT_URL, moment().format(dateFormat));

  const performURL = getPerformURL(wellId, jwtToken, GOPERFORM_URL);

  return (
    <Scrollbars autoHide autoHideTimeout={1000} autoHideDuration={200}>
      <InfoWrapper>
        {wellDetails ? (
          <>
            <Name>{name}</Name>
            {!!wellDetails.well_operator_div && (
              <Row>
                <Caption>{wellDetails.well_operator_div.description}:</Caption>
                <Value>{wellDetails.well_operator_div.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_block && (
              <Row>
                <Caption>{wellDetails.well_block.description}:</Caption>
                <Value>{wellDetails.well_block.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_field && (
              <Row>
                <Caption>{wellDetails.well_field.description}:</Caption>
                <Value>{wellDetails.well_field.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_pad && (
              <Row>
                <Caption>{wellDetails.well_pad.description}:</Caption>
                <Value>{wellDetails.well_pad.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_number && (
              <Row>
                <Caption>{wellDetails.well_number.description}:</Caption>
                <Value>{wellDetails.well_number.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_purpose && (
              <Row>
                <Caption>{wellDetails.well_purpose.description}:</Caption>
                <Value>{wellDetails.well_purpose.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_fluid && (
              <Row>
                <Caption>{wellDetails.well_fluid.description}:</Caption>
                <Value>{wellDetails.well_fluid.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_construction && (
              <Row>
                <Caption>{wellDetails.well_construction.description}:</Caption>
                <Value>{wellDetails.well_construction.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_sequence_number && (
              <Row>
                <Caption>{wellDetails.well_sequence_number.description}:</Caption>
                <Value>{wellDetails.well_sequence_number.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_sequence_count && (
              <Row>
                <Caption>{wellDetails.well_sequence_count.description}:</Caption>
                <Value>{wellDetails.well_sequence_count.value}</Value>
              </Row>
            )}
            {!!wellDetails.curator && (
              <Row>
                <Caption>{wellDetails.curator.description}:</Caption>
                <Value>{wellDetails.curator.value}</Value>
              </Row>
            )}
            {!!wellDetails.supervisor && (
              <Row>
                <Caption>{wellDetails.supervisor.description}:</Caption>
                <Value>{wellDetails.supervisor.value}</Value>
              </Row>
            )}
            {!!wellDetails.drilling_company && (
              <Row>
                <Caption>{wellDetails.drilling_company.description}:</Caption>
                <Value>{wellDetails.drilling_company.value}</Value>
              </Row>
            )}
            {!!(wellDetails.drilling_rig_type && wellDetails.is_svp_exists) && (
              <Row>
                <Caption>
                  {wellDetails.drilling_rig_type.description} / {wellDetails.is_svp_exists.description}:
                </Caption>
                <Value>
                  {wellDetails.drilling_rig_type.value} / {wellDetails.is_svp_exists.value}
                </Value>
              </Row>
            )}
            {!!wellDetails.drilling_crew && (
              <Row>
                <Caption>{wellDetails.drilling_crew.description}:</Caption>
                <Value>{wellDetails.drilling_crew.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_condition && (
              <Row>
                <Caption>{wellDetails.well_condition.description}:</Caption>
                <Value>{wellDetails.well_condition.value}</Value>
              </Row>
            )}
            {!!wellDetails.exec_summary && (
              <Row>
                <Caption>{wellDetails.exec_summary.description}:</Caption>
                <Value>{wellDetails.exec_summary.value}</Value>
              </Row>
            )}
            {!!wellDetails.rig_activity_6am && (
              <Row>
                <Caption>{wellDetails.rig_activity_6am.description}:</Caption>
                <Value>{wellDetails.rig_activity_6am.value}</Value>
              </Row>
            )}
            {!!wellDetails.well_spud_time && (
              <Row>
                <Caption>{wellDetails.well_spud_time.description}:</Caption>
                <Value>{wellDetails.well_spud_time.value}</Value>
              </Row>
            )}
            {!!wellDetails.end_date_prediction && (
              <Row>
                <Caption>{wellDetails.end_date_prediction.description}:</Caption>
                <Value>{wellDetails.end_date_prediction.value}</Value>
              </Row>
            )}
            {!!wellDetails.end_date && (
              <Row>
                <Caption>{wellDetails.end_date.description}:</Caption>
                <Value>{wellDetails.end_date.value}</Value>
              </Row>
            )}
            {!!(wellDetails.last_depth_plan && wellDetails.last_depth_fact) && (
              <Row>
                <Caption>{tReport('wellDetails.holeDepth')}:</Caption>
                <Value>{`${wellDetails.last_depth_plan.value} / ${wellDetails.last_depth_fact.value}`}</Value>
              </Row>
            )}
            {!!wellDetails.current_section && (
              <Row>
                <Caption>{wellDetails.current_section.description}:</Caption>
                <Value>{wellDetails.current_section.value}</Value>
              </Row>
            )}
            {!!(wellDetails.meterage_plan && wellDetails.meterage_fact) && (
              <Row>
                <Caption>{tReport('wellDetails.totalMetersDrilled')}:</Caption>
                <Value>{`${wellDetails.meterage_plan.value} / ${wellDetails.meterage_fact.value}`}</Value>
              </Row>
            )}
            {!!(wellDetails.commertial_speed_plan && wellDetails.commertial_speed_fact) && (
              <Row>
                <Caption>{tReport('wellDetails.effectiveRop')}:</Caption>
                <Value>
                  {`${wellDetails.commertial_speed_plan.value} / ${wellDetails.commertial_speed_fact.value}`}
                </Value>
              </Row>
            )}
            {!!(wellDetails.days_to_thousand_plan && wellDetails.days_to_thousand_fact) && (
              <Row>
                <Caption>{tReport('wellDetails.days1000m')}:</Caption>
                <Value>
                  {`${wellDetails.days_to_thousand_plan.value} / ${wellDetails.days_to_thousand_fact.value}`}
                </Value>
              </Row>
            )}
            {!!(
              wellDetails.construction_duration_plan &&
              wellDetails.construction_duration_fact &&
              wellDetails.lag_vs_advance
            ) && (
              <Row>
                <Caption>{tReport('wellDetails.timeVsDepth')}:</Caption>
                <Value>
                  {`${wellDetails.construction_duration_plan.value} / ${wellDetails.construction_duration_fact.value} / 
                  ${wellDetails.lag_vs_advance.value}`}
                </Value>
              </Row>
            )}

            <Actions>
              {reportURL && (
                <Button href={reportURL} target="_blank">
                  {tReport('dailyReport')}
                </Button>
              )}
              {performURL && (
                <Button href={performURL} target="_blank">
                  {tReport('monitoring')}
                </Button>
              )}
            </Actions>
          </>
        ) : (
          <>
            <Name>{tReport('wellInformation')}</Name>
            <NoDetails>{tReport('selectWell')}</NoDetails>
          </>
        )}
      </InfoWrapper>
    </Scrollbars>
  );
};

export default ReportWellDetails;
