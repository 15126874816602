import { Form } from 'antd';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { InputField } from 'src/components';

import { ModalWrapper } from '../forms/CreatePageForm/CreatePageForm.styled';

import { EditGraphTitleModalProps } from './EditGraphTitleModal.types';

const EditGraphTitleModal: FC<EditGraphTitleModalProps> = (props) => {
  const { isVisible, onClose, onEditGraphTitle, currentTitle } = props;

  const { t } = useTranslation('dashboard');
  const [form] = Form.useForm();

  const handleClose = () => {
    form.resetFields();
    onClose();
  };

  const handleSubmit = (title: string) => {
    onEditGraphTitle(title);
    handleClose();
  };

  return (
    <ModalWrapper
      visible={isVisible}
      centered
      title={t('editTitleForm.title')}
      okText={t('editTitleForm.saveButton')}
      cancelText={t('createForm.cancelButton')}
      onCancel={handleClose}
      onOk={form.submit}
    >
      <Form form={form} layout="vertical" onFinish={(values) => handleSubmit(values.title)}>
        <InputField
          defaultValue={currentTitle}
          name="title"
          placeholder={t('editTitleForm.placeholder')}
          rules={[{ required: true, message: t('editTitleForm.validation.requiredField') }]}
        />
      </Form>
    </ModalWrapper>
  );
};

export default EditGraphTitleModal;
