import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { ConfirmModal } from 'src/components';
import { updatePage } from 'src/services/PageService';
import { getPageByIdState, updatePageAction } from 'src/store/pages';
import { showErrorMessage, showSuccessMessage } from 'src/utils';

import { DeleteChartModalProps } from './DeleteChartModal.types';

const DeleteChartModal: FC<DeleteChartModalProps> = (props) => {
  const { isVisible, onClose, pageId, graph } = props;
  const { t } = useTranslation('widget');

  const dispatch = useDispatch();
  const page = useSelector(getPageByIdState(pageId));
  const [isLoading, setLoading] = useState(false);

  const handleDelete = () => {
    if (page) {
      setLoading(true);

      const newGraphs = page.page_map?.graphs?.filter((item) => item.layout_id !== graph.layout_id) || [];
      const newFrontend = {
        ...page.page_map?.frontend,
        hidden_chart_series: page.page_map?.frontend?.hidden_chart_series?.filter(
          ({ key }) => key !== `${graph.layout_id}-${graph.graph.key}`
        ),
      };

      updatePage(
        pageId,
        {
          ...page,
          page_map: { ...page.page_map, graphs: newGraphs, frontend: newFrontend || page.page_map?.frontend },
        },
        true
      )
        .then(({ data }) => {
          dispatch(updatePageAction(data.id, data));
          showSuccessMessage(t('messages.successClosed'));
          onClose();
        })
        .catch((error) => {
          showErrorMessage(t('messages.errorClosed', { error: error?.message }));
        })
        .finally(() => setLoading(false));
    }
  };

  return (
    <ConfirmModal
      isVisible={isVisible}
      onRemove={handleDelete}
      onCancel={onClose}
      title={t('deleteModal.title')}
      okText={t('deleteModal.closeButton')}
      cancelText={t('deleteModal.cancelButton')}
      isLoading={isLoading}
    >
      {t('deleteModal.content')}
    </ConfirmModal>
  );
};

export default DeleteChartModal;
