import moment from 'moment';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import DesktopTooltip from 'src/components/DesktopTooltip';

import { getColor, getLogVsAdvance } from './ReportWell.helpers';
import {
  Caption,
  ChartConstruction,
  ChartNPT,
  Col,
  ColWithTooltip,
  Content,
  ErrorIcon,
  Name,
  Panel,
  Row,
  RowWithTooltip,
  Value,
} from './ReportWell.styled';
import { ReportWellTypes } from './ReportWell.types';

const dateFormat = 'DD.MM.YYYY';

const ReportWell: FC<ReportWellTypes> = ({ wellData, onClickPanel, selected }) => {
  const { t: tReport } = useTranslation('report');
  const { t: tUnits } = useTranslation('units');

  return (
    <Panel
      background={getColor(wellData.stage)}
      selectedPanel={selected}
      onClick={() => onClickPanel(wellData.well_id)}
    >
      <Content>
        <Name>
          <DesktopTooltip title={wellData.name}>{wellData.name ?? wellData.field}</DesktopTooltip>
        </Name>
        <Row>
          <Col width="60%">
            <RowWithTooltip>
              <DesktopTooltip title={`${tReport('wellCard.padNumberHint')} ${wellData.pad}`}>
                <Caption>{tReport('wellCard.padNumber')}:</Caption>
                <Value>{wellData.pad}</Value>
              </DesktopTooltip>
            </RowWithTooltip>
            <RowWithTooltip>
              <DesktopTooltip title={`${tReport('wellCard.wellNumberHint')}  ${wellData.number}`}>
                <Caption>{tReport('wellCard.wellNumber')}:</Caption>
                <Value>{wellData.number}</Value>
              </DesktopTooltip>
            </RowWithTooltip>
            <RowWithTooltip>
              <DesktopTooltip title={`${tReport('wellCard.holeDepth')}: ${wellData.depth} ${tUnits('m')}`}>
                <Caption>{tReport('wellCard.holeDepth')}:</Caption>
                <Value>
                  {wellData.depth} {tUnits('m')}
                </Value>
              </DesktopTooltip>
            </RowWithTooltip>
            <RowWithTooltip>
              <DesktopTooltip
                title={`${tReport('wellCard.startDateHint')}: ${moment(wellData.spud_time).format(dateFormat)}`}
              >
                <Caption>{tReport('wellCard.startDate')}:</Caption>
                <Value>{moment(wellData.spud_time).format(dateFormat)}</Value>
              </DesktopTooltip>
            </RowWithTooltip>
            <RowWithTooltip>
              <DesktopTooltip
                title={`${tReport('wellCard.daysSavedHint')}: ${wellData.log_vs_advance} ${tUnits('days')}`}
              >
                <Caption>{tReport('wellCard.daysSaved')}:</Caption>
                <Value>
                  {getLogVsAdvance(wellData.log_vs_advance)} {tUnits('days')}
                </Value>
              </DesktopTooltip>
            </RowWithTooltip>
          </Col>
          <Col>
            <DesktopTooltip title={`${tReport('wellCard.npt')}: ${wellData.npt_hours}`}>
              <ChartNPT dangerouslySetInnerHTML={{ __html: wellData.svg_npt_persent }} />
            </DesktopTooltip>
          </Col>
        </Row>
        <RowWithTooltip>
          <DesktopTooltip
            title={
              wellData.stage === 2
                ? `${tReport('wellCard.endDate')}: ${moment(wellData.end_date_prediction).format(dateFormat)}`
                : `${tReport('wellCard.estimationDateHint')}: ${moment(wellData.end_date_prediction).format(
                    dateFormat
                  )}`
            }
          >
            <Caption>
              {wellData.stage !== 2 && `${tReport('wellCard.estimationDate')}:`}
              {wellData.stage === 2 && `${tReport('wellCard.endDate')}:`}
            </Caption>
            <Value>{moment(wellData.end_date_prediction).format(dateFormat)}</Value>
          </DesktopTooltip>
        </RowWithTooltip>
        <Row>
          <ColWithTooltip>
            <DesktopTooltip title={wellData.construction_type}>
              <ChartConstruction dangerouslySetInnerHTML={{ __html: wellData.svg_construction }} />
            </DesktopTooltip>
          </ColWithTooltip>
          <ColWithTooltip width="50%">
            <DesktopTooltip title={wellData.construction_type}>
              {wellData.construction_type.split('+').map((item, index) => (
                <Value key={index}>+{item}</Value>
              ))}
            </DesktopTooltip>
          </ColWithTooltip>
          {wellData.is_gti_absent && (
            <ColWithTooltip alignSelf="flex-end">
              <DesktopTooltip title={tReport('wellCard.hint')}>
                <ErrorIcon />
              </DesktopTooltip>
            </ColWithTooltip>
          )}
        </Row>
      </Content>
    </Panel>
  );
};

export default ReportWell;
