import { Dropdown, Menu } from 'antd';
import styled from 'styled-components';

import { staticColors } from 'src/styles/theme';

export const DropdownUser = styled(Dropdown)`
  cursor: pointer;
`;

export const AvatarImg = styled.img`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
  background: ${staticColors.background};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarIcon = styled.div`
  height: 40px;
  width: 40px;
  border-radius: 20px;
  object-fit: cover;
  background: ${staticColors.background};
  display: flex;
  justify-content: center;
  align-items: center;

  span svg {
    font-size: 25px;
  }
`;

export const Logout = styled(Menu.Item)`
  display: flex;
  align-items: center;
  font-weight: bold;
  color: ${staticColors.black};
  line-height: 20px;
  font-size: 16px;

  span {
    color: ${staticColors.primary} !important;
  }

  svg {
    height: 20px;
    width: 20px;
  }

  svg path {
    fill: ${staticColors.primary};
  }
`;

export const AvatarInfo = styled.div`
  padding: 15px 15px 0;
  margin-bottom: 22px;
`;

export const UserName = styled.p`
  color: ${staticColors.black};
  line-height: 20px;
  font-weight: bold;
  font-size: 16px;
  margin: 0 0 8px;
`;

export const UserPosition = styled.p`
  line-height: 20px;
  font-size: 16px;
  margin: 0;
  color: rgba(1, 31, 48, 0.5);
`;
