import { AnalyticsState, AnalyticsActionTypes, AnalyticsActions } from './types';

const InitialState: AnalyticsState = {
  filters: null,
};

const analyticsReducer = (state = InitialState, action: AnalyticsActions): AnalyticsState => {
  switch (action.type) {
    case AnalyticsActionTypes.setFilters: {
      return {
        ...state,
        filters: action.payload,
      };
    }

    default:
      return state;
  }
};

export default analyticsReducer;
