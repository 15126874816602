import 'moment/locale/ru';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useDebounce } from 'src/hooks';
import { Filters } from 'src/services/AnalyticsService/AnalyticsService.types';
import { getFiltersValues } from 'src/utils';

import CommonFilter from './CommonFilter';
import { FormWrapper } from './CommonFiltersForm.styled';
import { CommonFiltersFormProps } from './CommonFiltersForm.types';

const CommonFiltersForm: FC<CommonFiltersFormProps> = ({
  filters,
  filtersValues,
  setFiltersValues,
  withSortingInPanel,
  onChange,
  isLoading,
}) => {
  const { t } = useTranslation('filters');
  const debounce = useDebounce();

  const onFiltersValuesChange = (filter: Filters, value: any /* TODO: any добавить тип */) => {
    const resultFilters = getFiltersValues(filter, value, filtersValues);
    setFiltersValues(resultFilters);

    debounce(() => onChange?.(resultFilters), 500);
  };

  return (
    <FormWrapper padding={withSortingInPanel ? '25px 30px 0 30px' : undefined}>
      <h3>{t('commonFiltersTitle')}</h3>

      {filters &&
        filters.map((filter: Filters) => {
          const itemValues = filtersValues.filters?.filter((itemFilter) => itemFilter.key === filter.key)[0];

          return (
            <CommonFilter
              filter={filter}
              itemValues={itemValues}
              isLoading={isLoading}
              onFiltersValuesChange={onFiltersValuesChange}
            />
          );
        })}
    </FormWrapper>
  );
};

export default CommonFiltersForm;
