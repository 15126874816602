import { FullscreenExitOutlined, FullscreenOutlined } from '@ant-design/icons';
import { Button, Tooltip } from 'antd';
import moment from 'moment';
import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { DashboardChart, Loader } from 'src/components';
import { DisplayType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { useDebounce } from 'src/hooks';
import { getPageById, updatePage } from 'src/services/PageService';
import { addOrUpdateFrontend, toggleSeriesVisible } from 'src/services/PageService/PageService.helpers';
import { Frontend, GraphBlock, GraphDisplayTypes } from 'src/services/PageService/PageService.types';
import {
  changeViewModeDashboard,
  getPageMapState,
  getViewModeState,
  updateChartHiddenSeries,
  updatePageAction,
  updatePageLayoutAction,
  updateShowingValuesOnChart,
  ViewMode,
} from 'src/store/pages';
import { getEndPeriodDate, getMinUpdatedPeriod, setCurrentDateToChartFilters, showErrorMessage } from 'src/utils';

import { isValidDynamicPeriod, VALID_DYNAMIC_PERIODS } from '../../constants/charts';

import { MIN_CHART_WIDTH } from './Dashboard.constants';
import { getNewLayout } from './Dashboard.helpers';
import { ButtonCollapseContainer, EmptyContent, GridItem, LoaderContainer } from './Dashboard.styled';
import { DashboardProps } from './Dashboard.types';

const UPDATE_DELAY = 1000 * 60 * 60 * 4; // 4 hours

const ResponsiveGridLayout = WidthProvider(Responsive);

const Dashboard: FC<DashboardProps> = ({ id }) => {
  const dispatch = useDispatch();
  const debounce = useDebounce();
  const { t } = useTranslation('dashboard');

  const { pageMap, hiddenChartSeries, displayTypes } = useSelector(getPageMapState(id));
  const viewMode = useSelector(getViewModeState);
  const hasCharts = !!pageMap?.graphs?.some((graph) => !!graph?.data?.charts?.length);

  const [isLoading, setLoading] = useState(!pageMap);
  const [isErrorInitialized, setErrorInitialized] = useState(false);
  const [countRepeatedRequests, setCountRepeatedRequests] = useState(0);
  const graphs = useMemo(() => pageMap?.graphs || [], [pageMap]);
  const [layouts, setLayouts] = useState<Layouts>(pageMap?.layout || {});
  const [frontend, setFrontend] = useState<Frontend>(pageMap?.frontend || {});
  const [isDraggable, setDraggable] = useState(true);
  const [isMobile, setMobile] = useState(window.innerWidth < 768);

  const handleChangeBreakpoint = (breakpoint: string, cols: number) => {
    if (!Array.isArray(layouts[breakpoint]) || !layouts[breakpoint].length) {
      const prevLayout = Object.values(layouts).find((item) => !!item.length);
      const newLayout: Layout[] = getNewLayout(prevLayout || [], cols);
      setLayouts((prev) => ({ ...prev, [breakpoint]: newLayout }));
    }
  };

  const handleLayoutChange = (_: Layout[], gridLayouts: Layouts) => {
    const newLayouts: Layouts = {};

    for (const [breakpoint, layouts] of Object.entries(gridLayouts)) {
      newLayouts[breakpoint] = layouts.map((layout) => ({
        ...layout,
        minW: MIN_CHART_WIDTH,
        w: Math.max(MIN_CHART_WIDTH, layout.w),
      }));
    }

    setLayouts(newLayouts);
  };

  const updateDates = (layout: Layouts, graphs: GraphBlock[], frontend: Frontend) => {
    if (frontend && graphs) {
      const newGraphs: GraphBlock[] = JSON.parse(JSON.stringify(graphs));
      let needToUpdateGraphs = false;

      newGraphs.forEach((newGraph) => {
        const displayType = frontend.graph_display_types?.find(
          ({ key }) => key === newGraph.graph.key || key === `${newGraph.layout_id}-${newGraph.graph.key}`
        )?.value;

        const isDynamic = displayType?.displayType === DisplayType.dynamic;

        if (isDynamic) {
          const dateFormat = 'yyyy-MM-DDT00:00:00';

          const axisIsByWells = newGraph.graph.axis?.key === 'ee964c87-cccc-4e46-b679-5c6ccdf8c21f';

          if (axisIsByWells) {
            const currentDate = moment().subtract({ day: 1 }).format(dateFormat);

            newGraph.graph.filters.forEach((filter) => {
              let isCurrentDate = true;

              if (filter.value_from && filter.value_to) {
                filter.value_from = moment(filter.value_from).format(dateFormat);
                filter.value_to = moment(filter.value_to).format(dateFormat);

                isCurrentDate = filter.value_from === currentDate && filter.value_to === currentDate;
              } else {
                needToUpdateGraphs = true;
                filter.value_from = currentDate;
                filter.value_to = currentDate;
              }
              if (!isCurrentDate) {
                needToUpdateGraphs = true;
                filter.value_from = currentDate;
                filter.value_to = currentDate;
              }
            });
          } else {
            const { updatePeriod } = displayType;

            newGraph.graph.filters.forEach((filter) => {
              if (filter.filter_rule_type === 'interval' && filter.filter_type === 'date' && updatePeriod) {
                const validPeriod = isValidDynamicPeriod(updatePeriod) && VALID_DYNAMIC_PERIODS[updatePeriod];

                if (validPeriod) {
                  const start = moment().startOf(validPeriod).format(dateFormat);
                  const end = moment().endOf(validPeriod).format(dateFormat);

                  if (filter.value_from !== start || filter.value_to !== end) {
                    needToUpdateGraphs = true;
                    filter.value_from = start;
                    filter.value_to = end;
                  }
                }
              }
            });
          }
        }
      });

      if (needToUpdateGraphs) {
        setLoading(true);
        updatePage(
          id,
          {
            page_map: {
              layout,
              graphs: newGraphs,
              frontend,
            },
          },
          true
        )
          .then(({ data }) => {
            setLayouts(data.page_map?.layout || {});
            setFrontend(data.page_map?.frontend || {});

            dispatch(updatePageAction(id, data));
          })
          .finally(() => {
            setLoading(false);
          });
      }
    }
  };

  const initializePage = useCallback(() => {
    if (id && !pageMap && countRepeatedRequests <= 3) {
      setLoading(true);
      debounce(
        () => {
          getPageById(id, true)
            .then(({ data }) => {
              setErrorInitialized(false);
              setCountRepeatedRequests(0);
              setLayouts(data.page_map?.layout || {});
              setFrontend(data.page_map?.frontend || {});

              updateDates(data.page_map?.layout || {}, data.page_map?.graphs || [], data.page_map?.frontend || {});

              dispatch(updatePageAction(id, data));
            })
            .catch((error) => {
              setErrorInitialized(true);
              if (error && countRepeatedRequests < 3) {
                setTimeout(() => setCountRepeatedRequests(countRepeatedRequests + 1), 0);
                initializePage();
              } else {
                showErrorMessage(t('errorGetDashboard', { error: error?.message }));
              }
            })
            .finally(() => setLoading(false));
        },
        3000,
        true
      );
    }
  }, [t, debounce, id, pageMap, countRepeatedRequests, setCountRepeatedRequests, dispatch]);

  useEffect(() => {
    initializePage();
  }, [initializePage]);

  useEffect(() => {
    if (id && !isLoading && !isErrorInitialized && pageMap?.graphs) {
      const newGraphs = setCurrentDateToChartFilters(pageMap.graphs);

      debounce(() => {
        updatePage(id, {
          page_map: {
            layout: layouts,
            graphs: newGraphs,
            frontend,
          },
        });
      }, 2000);
    }
  }, [debounce, id, layouts, frontend, isLoading, isErrorInitialized, pageMap?.graphs]);

  useEffect(() => {
    const keydownListener = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setDraggable(false);
      }
      if (event.key === 'Escape') {
        dispatch(changeViewModeDashboard(ViewMode.default));
      }
    };
    const keyupListener = (event: KeyboardEvent) => {
      if (event.key === 'Shift') {
        setDraggable(true);
      }
    };

    window.document.addEventListener('keydown', keydownListener);
    window.document.addEventListener('keyup', keyupListener);

    return () => {
      window.document.removeEventListener('keydown', keydownListener);
      window.document.removeEventListener('keyup', keyupListener);
    };
  }, []);

  useEffect(() => {
    const resizeListener = () => {
      setMobile(window.innerWidth < 768);
    };
    window.addEventListener('resize', resizeListener);

    return () => {
      window.removeEventListener('resize', resizeListener);
    };
  }, []);

  // Updating data every 4 hours
  useEffect(() => {
    const interval = window.setInterval(() => {
      getPageById(id, true).then(({ data }) => {
        dispatch(updatePageAction(id, data));
      });

      if (pageMap?.graphs && pageMap?.layout && pageMap?.frontend) {
        updateDates(pageMap.layout, pageMap.graphs, pageMap.frontend);
      }
    }, UPDATE_DELAY);

    return () => {
      window.clearInterval(interval);
    };
  }, [id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(updatePageLayoutAction(id, layouts));
    };
  }, [id, layouts, dispatch]);

  const onChangeViewMode = () => {
    if (viewMode === ViewMode.default) {
      dispatch(changeViewModeDashboard(ViewMode.fullscreen));
    } else {
      dispatch(changeViewModeDashboard(ViewMode.default));
    }
  };

  const onChangeShowingValuesOnChart = (showingValuesOnChart: boolean, graphKey: string, layoutId?: string) => {
    const chartKey = `${layoutId}-${graphKey}`;

    const newFrontend = addOrUpdateFrontend(
      { key: chartKey, value: showingValuesOnChart },
      frontend,
      'showing_values_on_chart'
    );

    dispatch(updateShowingValuesOnChart(id, chartKey, showingValuesOnChart));

    debounce(() => {
      updatePage(
        id,
        {
          page_map: {
            layout: layouts,
            graphs: graphs,
            frontend: newFrontend,
          },
        },
        true
      ).then(({ data }) => {
        dispatch(updatePageAction(id, data));
      });
    }, 500);
  };

  const onEditChartCustomName = (chartName: string, graphKey: string, layoutId?: string) => {
    const chartKey = `${layoutId}-${graphKey}`;

    const newFrontend = addOrUpdateFrontend({ key: chartKey, value: chartName }, frontend, 'chart_custom_names');

    updatePage(
      id,
      {
        page_map: {
          layout: layouts,
          graphs: graphs,
          frontend: newFrontend,
        },
      },
      true
    ).then(({ data }) => {
      dispatch(updatePageAction(id, data));
    });
  };

  const onToggleChartSeries = useCallback(
    (key: string, seriesName: string, visible?: boolean) => {
      const newHiddenSeries = toggleSeriesVisible(hiddenChartSeries, key, seriesName, visible);
      const updatedSeries = newHiddenSeries?.find((hiddenSeries) => hiddenSeries.key === key);

      if (updatedSeries) {
        dispatch(updateChartHiddenSeries(id, key, updatedSeries));
      }

      debounce(() => {
        updatePage(
          id,
          {
            page_map: {
              ...pageMap,
              graphs: pageMap?.graphs || [],
              frontend: {
                ...pageMap?.frontend,
                hidden_chart_series: newHiddenSeries,
              },
            },
          },
          true
        ).then(({ data }) => {
          dispatch(updatePageAction(id, data));
        });
      }, 500);
    },
    [id, dispatch, hiddenChartSeries, debounce, pageMap]
  );

  useEffect(() => {
    if (pageMap && pageMap.frontend) {
      setFrontend(pageMap.frontend);
    }
  }, [pageMap]);

  // Updating data timer for dynamic display type charts
  useEffect(() => {
    const interval = window.setInterval(() => {
      const updatedPeriods = displayTypes
        ?.filter((type) => (type.value as GraphDisplayTypes).displayType === DisplayType.dynamic)
        .map((type) => (type.value as GraphDisplayTypes).updatePeriod);

      const minUpdatedPeriod = getMinUpdatedPeriod(updatedPeriods);

      if (minUpdatedPeriod && Date.now() >= getEndPeriodDate(minUpdatedPeriod)) {
        getPageById(id, true).then(({ data }) => {
          dispatch(updatePageAction(id, data));
        });
      }
    }, 60 * 1000);

    return () => {
      window.clearInterval(interval);
    };
  }, [id, dispatch, displayTypes]);

  if (isLoading || !pageMap) {
    return (
      <LoaderContainer>
        <Loader />
      </LoaderContainer>
    );
  }

  return (
    <>
      {hasCharts && (
        <ResponsiveGridLayout
          layouts={layouts}
          breakpoints={{ xxl: 2440, xl: 1920, l: 1200, m: 996, s: 768, xs: 480 }}
          rowHeight={250}
          cols={{ xxl: 12, xl: 10, l: 8, m: 6, s: 4, xs: 2 }}
          containerPadding={[0, 16]}
          onBreakpointChange={handleChangeBreakpoint}
          onLayoutChange={handleLayoutChange}
          isDraggable={!isMobile && isDraggable}
          isDroppable={!isMobile && isDraggable}
          isResizable={!isMobile}
        >
          {graphs.map((graph, idx) => {
            const chart = graph.data?.charts?.[0];

            if (!chart) {
              return null;
            }

            return (
              <GridItem key={graph.layout_id || idx}>
                <DashboardChart
                  chartId={0}
                  graph={graph}
                  pageId={id}
                  setDraggable={setDraggable}
                  onChangeShowingValuesOnChart={onChangeShowingValuesOnChart}
                  onToggleChartSeries={onToggleChartSeries}
                  onEditChartCustomName={onEditChartCustomName}
                />
              </GridItem>
            );
          })}
        </ResponsiveGridLayout>
      )}
      <ButtonCollapseContainer>
        <Tooltip title={t('fullscreen')} placement="left">
          <Button
            size="large"
            icon={viewMode === ViewMode.default ? <FullscreenOutlined /> : <FullscreenExitOutlined />}
            onClick={onChangeViewMode}
          />
        </Tooltip>
      </ButtonCollapseContainer>
      {!hasCharts && <EmptyContent>{t('emptyPage')}</EmptyContent>}
    </>
  );
};

export default Dashboard;
