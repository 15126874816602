import { Select, Switch } from 'antd';
import React, { VFC } from 'react';
import { useTranslation } from 'react-i18next';

import { SelectOptions } from 'src/components/Fields/Fields.types';
import SelectPicker from 'src/components/SelectPicker';
import { getDefaultChartTypes } from 'src/constants/charts';
import { AxisInfo, Group, PurposeInfo } from 'src/services/AnalyticsService/AnalyticsService.types';
import { checkInclusion } from 'src/utils/common';

import { IndicatorFilters } from './Indicators';
import { Indicator } from './IndicatorsFiltersForm.styled';
import { ChartType, DisplayType, IndicatorsFiltersFormItemProps, isChartType } from './IndicatorsFiltersForm.types';

export const IndicatorsFiltersFormItem: VFC<IndicatorsFiltersFormItemProps> = ({
  indicator,
  filtersValues,
  itemIndicatorsValues,
  displayTypeOptions,
  chartTypeOptions,
  displayType,
  axisGroupDate,
  onChangeIndicatorsValues,
  onChangeFiltersValues,
  onChangeDisplayType,
  isLoading,
}) => {
  const { t } = useTranslation('filters');
  const selectedAxisName = indicator.axes.find((axis) => axis.key === itemIndicatorsValues?.axes)?.display_name;

  const getIsShowDisplayType = () => {
    if (!itemIndicatorsValues) {
      return false;
    }
    const hasDependenciesOfSeveralAxes = checkInclusion(indicator.display_name, ['Проходка', 'Скорость бурения']);
    const isOneOfDependentAxes = checkInclusion(selectedAxisName, ['По дате', 'По скважинам']);

    if (hasDependenciesOfSeveralAxes && isOneOfDependentAxes) {
      return true;
    }

    const hasDependencyOfSingleAxes = checkInclusion(indicator.display_name, ['НПВ', 'УТЗ']);
    const isSingleDependentAxis = selectedAxisName === 'По дате';

    return hasDependencyOfSingleAxes && isSingleDependentAxis;
  };

  const availableChartTypes = getDefaultChartTypes(indicator.key);
  const isPassingOrDrillingSpeed = checkInclusion(indicator.display_name, ['Проходка', 'Скорость бурения']);

  return (
    <>
      {indicator.axes.length > 0 && (
        <Indicator>
          {!!indicator.purpose_infos?.length && (
            <Indicator>
              <h4>{t('indicator')}</h4>

              <SelectPicker
                mode="multiple"
                value={itemIndicatorsValues?.purpose_infos}
                onChange={(value, option) => onChangeIndicatorsValues(indicator, 'purpose_infos', value, option)}
              >
                {indicator.purpose_infos.map((purposeInfo: PurposeInfo) => (
                  <Select.Option key={purposeInfo.key} value={purposeInfo.key}>
                    {purposeInfo.display_name}
                  </Select.Option>
                ))}
              </SelectPicker>
            </Indicator>
          )}

          <h4>{t('grouping')}</h4>

          <Select
            value={itemIndicatorsValues?.axes}
            onChange={(value) => onChangeIndicatorsValues(indicator, 'axes', value)}
          >
            {indicator.axes.map((axes: AxisInfo) => {
              return (
                <Select.Option key={axes.key} value={axes.key}>
                  {axes.display_name}
                </Select.Option>
              );
            })}
          </Select>

          {itemIndicatorsValues?.axes &&
            indicator.axes.filter((itemAxisInfo: AxisInfo) => itemAxisInfo.key === itemIndicatorsValues?.axes)?.[0]
              ?.axis_groups.length > 0 && (
              <Select
                value={itemIndicatorsValues?.axis_groups}
                onChange={(value) => onChangeIndicatorsValues(indicator, 'axis_groups', value)}
                className="dynamic"
              >
                {indicator.axes
                  .filter((itemAxisInfo: AxisInfo) => itemAxisInfo.key === itemIndicatorsValues?.axes)[0]
                  .axis_groups.map((axisGroup: Group) => (
                    <Select.Option key={axisGroup.key} value={axisGroup.key}>
                      {axisGroup.display_name}
                    </Select.Option>
                  ))}
              </Select>
            )}
        </Indicator>
      )}

      {indicator.groups.length > 0 && (
        <Indicator>
          <h4>{t(indicator.display_name !== 'НПВ' ? 'combinedGrouping' : 'detailing')}</h4>

          <Select
            value={itemIndicatorsValues?.group?.key}
            allowClear
            onChange={(value, option) => onChangeIndicatorsValues(indicator, 'group', value, option)}
          >
            {indicator.groups.map((group: Group) => (
              <Select.Option key={group.key} value={group.key}>
                {group.display_name}
              </Select.Option>
            ))}
          </Select>
        </Indicator>
      )}

      {getIsShowDisplayType() && (
        <Indicator>
          <h4>{t('displayType')}</h4>

          <SelectPicker
            value={displayType ?? DisplayType.static}
            onChange={(value) => onChangeDisplayType(indicator.key, value as DisplayType, axisGroupDate)}
          >
            {displayTypeOptions.map((option: SelectOptions, index: number) => (
              <Select.Option key={index} value={option.value}>
                {option.title}
              </Select.Option>
            ))}
          </SelectPicker>
        </Indicator>
      )}

      {indicator.filters.length > 0 && (
        <IndicatorFilters
          displayType={displayType}
          filtersValues={filtersValues}
          indicator={indicator}
          onChangeFiltersValues={onChangeFiltersValues}
          selectedAxisName={selectedAxisName}
          axisGroupDate={axisGroupDate}
          itemIndicatorsValues={itemIndicatorsValues}
          isLoading={isLoading}
        />
      )}

      {!!indicator.has_cumulative_mode && (
        <Indicator>
          <Switch
            checked={
              itemIndicatorsValues?.chart_type === ChartType.pie
                ? itemIndicatorsValues.chart_is_cumulative
                : itemIndicatorsValues?.is_cumulative
            }
            onChange={(checked) => onChangeIndicatorsValues(indicator, 'is_cumulative', checked)}
            disabled={displayType === DisplayType.dynamic || itemIndicatorsValues?.chart_type === ChartType.pie}
          />

          <span>{t('cumulative')}</span>
        </Indicator>
      )}

      {!!availableChartTypes.chartTypes.length && (
        <Indicator>
          <h4>{t('chartType')}</h4>

          <SelectPicker
            value={itemIndicatorsValues?.chart_type ?? availableChartTypes.defaultChartType}
            onChange={(value) => onChangeIndicatorsValues(indicator, 'chart_type', value as ChartType)}
          >
            {chartTypeOptions
              .filter(({ value }) => {
                if (!(isChartType(value) && availableChartTypes.chartTypes.includes(value))) {
                  return false;
                }

                const isStackOrPieChartType = checkInclusion(value, [ChartType.stack, ChartType.pie]);

                return !(isPassingOrDrillingSpeed && isStackOrPieChartType);
              })
              .map((option: SelectOptions, index: number) => (
                <Select.Option key={index} value={option.value}>
                  {option.title}
                </Select.Option>
              ))}
          </SelectPicker>
        </Indicator>
      )}
    </>
  );
};
