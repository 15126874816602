import { useCallback } from 'react';

import useLocalMemo from 'src/hooks/useLocalMemo';
import { GraphBlock, GraphDisplayTypes } from 'src/services/PageService/PageService.types';
import { CompareFn } from 'src/types';
import { getArrayKey, getObjectKey } from 'src/utils';

export const useHiddenSeriesMemo = (hiddenSeries: { value: string[]; hash?: string }, updateComponent?: boolean) => {
  const compare: CompareFn<{ value: string[]; hash?: string }> = (prev, next) => {
    if (prev.hash || prev.hash === '') {
      return prev.hash === (next?.hash || getArrayKey(next.value));
    }

    return getArrayKey(prev.value) === getArrayKey(next.value);
  };

  return useLocalMemo(hiddenSeries, compare, updateComponent);
};

export const useDisplayTypeMemo = (
  displayType: { value: GraphDisplayTypes; hash?: string },
  updateComponent?: boolean
) => {
  const compare: CompareFn<{ value: GraphDisplayTypes; hash?: string }> = useCallback((prev, next) => {
    if (prev.hash || prev.hash === '') {
      return prev.hash === (next?.hash || getObjectKey(next.value));
    }

    return getObjectKey(prev.value) === getObjectKey(next.value);
  }, []);

  return useLocalMemo(displayType, compare, updateComponent);
};

export const useGraphMemo = (graph: GraphBlock, updateComponent?: boolean) => {
  const compare: CompareFn<GraphBlock> = (prev, next) => {
    return getObjectKey(prev) === getObjectKey(next);
  };

  return useLocalMemo(graph, compare, updateComponent);
};
