import styled from 'styled-components';

type LayoutWrapperProps = {
  showHeader: boolean;
};

export const LayoutWrapper = styled.div<LayoutWrapperProps>`
  background: ${({ theme }) => theme.colors.backgroundLayout};
  padding: ${({ showHeader }) => (showHeader ? '40px 40px 0 40px' : '8px 40px 0 40px')};
  min-height: ${({ showHeader }) => (showHeader ? 'calc(100vh - 72px)' : '100vh')};
  display: flex;

  @media (max-width: 768px) {
    position: relative;
    overflow: hidden;
    padding: 12px 0 0 0;
  }
`;
