import { Layouts } from 'react-grid-layout';

import { FrontendValue, PageResponse, TemplateDashboardResponse } from 'src/services/PageService/PageService.types';

export enum ViewMode {
  fullscreen = 'fullscreen',
  default = 'default',
}

export type PageState = {
  pages: PageResponse[];
  templates: TemplateDashboardResponse[];
  viewMode: ViewMode;
};

export enum PagesActionTypes {
  setPages = 'SET_PAGES',
  addPage = 'ADD_PAGE',
  removePage = 'REMOVE_PAGE',
  updatePage = 'UPDATE_PAGE',
  updatePageLayout = 'UPDATE_PAGE_LAYOUT',
  setTemplates = 'SET_TEMPLATES',
  addTemplate = 'ADD_TEMPLATE',
  updateTemplate = 'UPDATE_TEMPLATE',
  cleanPageMap = 'CLEAN_PAGEMAP',
  changeViewMode = 'CHANGE_VIEW_MODE',
  updateChartHiddenSeries = 'UPDATE_CHART_HIDDEN_SERIES',
  updateShowingValuesOnChart = 'UPDATE_SHOWING_VALUES_ON_CHART',
  setFrontendLoading = 'SET_FRONTEND_LOADING',
}

export type PagesActions =
  | { type: PagesActionTypes.setPages; payload: PageResponse[] }
  | { type: PagesActionTypes.addPage; payload: PageResponse }
  | { type: PagesActionTypes.removePage; payload: number }
  | { type: PagesActionTypes.updatePage; payload: { id: number; data: PageResponse } }
  | { type: PagesActionTypes.updatePageLayout; payload: { id: number; data: Layouts } }
  | { type: PagesActionTypes.setTemplates; payload: TemplateDashboardResponse[] }
  | { type: PagesActionTypes.addTemplate; payload: TemplateDashboardResponse }
  | { type: PagesActionTypes.updateTemplate; payload: TemplateDashboardResponse }
  | { type: PagesActionTypes.cleanPageMap }
  | { type: PagesActionTypes.changeViewMode; payload: ViewMode }
  | {
      type: PagesActionTypes.updateChartHiddenSeries;
      payload: { pageId: number; chartKey: string; data: FrontendValue<string[]> };
    }
  | { type: PagesActionTypes.updateShowingValuesOnChart; payload: { pageId: number; chartKey: string; data: boolean } }
  | { type: PagesActionTypes.setFrontendLoading; payload: { pageId: number; isLoading: boolean } };
