import { ChartType, isChartType } from 'src/components/forms/IndicatorsFiltersForm/IndicatorsFiltersForm.types';
import { DEFAULT_DISPLAY_TYPE } from 'src/constants/common';
import { PageTypes } from 'src/services/PageService/PageService.types';
import { getObjectKey } from 'src/utils';

import type { AppStore } from '../types';

import type { PageState } from './types';

const getState = (state: AppStore): PageState => state.pagesReducer;

export const getPagesState = (state: AppStore) => {
  const pageState = getState(state);
  return pageState.pages;
};

export const getPageByIdState = (id: number) => (state: AppStore) => {
  const pageState = getState(state);
  return pageState.pages.find((page) => page.id === id);
};

export const getDashboardsState = (state: AppStore) => {
  const pageState = getState(state);
  return pageState.pages.filter((page) => page.page_type === PageTypes.dashboard);
};

export const getFiltersValuesState = (id: number) => (state: AppStore) => {
  const pageState = getState(state);
  return pageState.pages.find((page) => page.id === id)?.filtersValues;
};

export const getPageNameState = (id: number) => (state: AppStore) => {
  return getPageByIdState(id)(state)?.name;
};

export const getTemplatesState = (state: AppStore) => {
  const pageState = getState(state);
  return pageState.templates;
};

export const getViewModeState = (state: AppStore) => {
  const pageState = getState(state);
  return pageState.viewMode;
};

export const getHiddenChartSeries = (id: number) => (state: AppStore) => {
  return getPageByIdState(id)(state)?.page_map?.frontend?.hidden_chart_series;
};

export const getShowingValuesOnChart = (id: number) => (state: AppStore) => {
  return getPageByIdState(id)(state)?.page_map?.frontend?.showing_values_on_chart;
};

export const getPageMapState = (id: number) => (state: AppStore) => {
  const page = getPageByIdState(id)(state);

  return {
    pageMap: page?.page_map,
    hiddenChartSeries: page?.page_map?.frontend?.hidden_chart_series || [],
    showingValuesOnCharts: page?.page_map?.frontend?.showing_values_on_chart || [],
    displayTypes: page?.page_map?.frontend?.graph_display_types || [],
  };
};

export const getGraphFrontendState =
  (pageId: number, graphKey: string, layoutId: string = '0') =>
  (state: AppStore) => {
    const graphFullKey = `${layoutId}-${graphKey}`;
    const page = getPageByIdState(pageId)(state);
    const frontend = page?.page_map?.frontend;

    // TODO: remove 'or' and keep only 'graphFullKey'.
    // Some frontend fields have keys containing only graph key instead of layout key + graph key.
    const showingValuesOnChart = frontend?.showing_values_on_chart?.find(
      ({ key }) => key === graphFullKey || key === graphKey
    );

    const chartCustomName = frontend?.chart_custom_names?.find(({ key }) => key === graphFullKey || key === graphKey);

    const hiddenChartSeries = frontend?.hidden_chart_series?.find(
      ({ key }) => key === graphFullKey || key === graphKey
    );
    const displayType = frontend?.graph_display_types?.find(({ key }) => key === graphFullKey || key === graphKey);
    const chartType = page?.page_map?.graphs?.find(
      ({ graph: { key }, layout_id }) => key === graphKey && layout_id === layoutId
    )?.graph?.chart_type;
    const reportWellsSorting = frontend?.report_wells_sorting?.find(
      ({ key }) => key === graphFullKey || key === graphKey
    );

    const actualDisplayType = displayType ?? {
      value: DEFAULT_DISPLAY_TYPE,
      hash: getObjectKey(DEFAULT_DISPLAY_TYPE),
    };

    return {
      showingValuesOnChart: showingValuesOnChart?.value ?? true,
      hiddenChartSeries: {
        value: hiddenChartSeries?.value || [],
        hash: hiddenChartSeries?.hash || '',
      },
      displayType: actualDisplayType,
      chartType: chartType && isChartType(chartType) ? chartType : ChartType.column,
      reportWellsSorting: reportWellsSorting?.value,
      chartCustomName: chartCustomName?.value || null,
    };
  };
