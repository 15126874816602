import { Select } from 'antd';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import { useEffect, useRef, useState, VFC } from 'react';

import RangePicker from 'src/components/RangePicker';
import SelectPicker from 'src/components/SelectPicker';
import {
  ItemFilterTypes,
  RulesFilterTypes,
  VariantsFilter,
} from 'src/services/AnalyticsService/AnalyticsService.types';
import { getLocale } from 'src/utils';

import { FieldWrapper } from './CommonFiltersForm.styled';
import { CommonFilterProps } from './CommonFiltersForm.types';

const dateFormat = 'DD.MM.YYYY';

const CommonFilter: VFC<CommonFilterProps> = ({ filter, itemValues, isLoading, onFiltersValuesChange }) => {
  const locale = getLocale();

  const ref = useRef<HTMLDivElement>(null);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (open && ref.current) {
      const { bottom } = ref.current.getBoundingClientRect();

      if (bottom >= (window.innerHeight || document.documentElement.clientHeight)) {
        ref.current.scrollIntoView();
      }
    }
  }, [open, itemValues?.value, itemValues?.value_to, itemValues?.values_list, itemValues?.value_from]);

  return (
    <FieldWrapper key={filter.key} ref={ref}>
      <label>
        {filter.display_name} ({filter.variants?.filter(({ is_available }) => is_available).length})
      </label>

      {filter.item_type === ItemFilterTypes.date && (
        <RangePicker
          value={
            itemValues?.value_from && itemValues?.value_to
              ? [moment(itemValues.value_from, dateFormat), moment(itemValues.value_to, dateFormat)]
              : [moment(), moment()]
          }
          format={dateFormat}
          locale={locale === 'ru' ? localeRu : localeEn}
          onChange={(dateStrings) => onFiltersValuesChange(filter, dateStrings)}
          minPeriod={null}
        />
      )}

      {filter.item_type === ItemFilterTypes.object && (
        <SelectPicker
          mode={filter.rules.includes(RulesFilterTypes.multiple) ? 'multiple' : undefined}
          onChange={(value) => onFiltersValuesChange(filter, value)}
          value={itemValues?.values_list}
          loading={isLoading}
          disabled={isLoading && !open}
          onFocus={() => setOpen(true)}
          onBlur={() => setOpen(false)}
        >
          {filter.variants &&
            filter.variants.map((variant: VariantsFilter) => (
              <Select.Option key={variant.key} value={variant.key} disabled={!variant?.is_available}>
                {variant.display_name}
              </Select.Option>
            ))}
        </SelectPicker>
      )}
    </FieldWrapper>
  );
};

export default CommonFilter;
