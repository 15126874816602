import { Select } from 'antd';
import localeEn from 'antd/es/date-picker/locale/en_US';
import localeRu from 'antd/es/date-picker/locale/ru_RU';
import moment from 'moment';
import React, { memo, useEffect, useState, VFC } from 'react';
import { useTranslation } from 'react-i18next';

import RangePicker from 'src/components/RangePicker';
import { RangePickerProps } from 'src/components/RangePicker/RangePicker.types';
import SelectPicker from 'src/components/SelectPicker';
import {
  Filters,
  ItemFilterTypes,
  RulesFilterTypes,
  VariantsFilter,
} from 'src/services/AnalyticsService/AnalyticsService.types';
import { getLocale } from 'src/utils';

import { Indicator } from '../IndicatorsFiltersForm.styled';
import { DisplayType, IndicatorFiltersProps } from '../IndicatorsFiltersForm.types';

import IndicatorFilterStateWrapper from './IndicatorFilterStateWrapper';

const dateFormat = 'DD.MM.YYYY';

// Другие индикаторы тоже локализовать?
export const IndicatorFilters: VFC<IndicatorFiltersProps> = memo(
  ({ displayType, filtersValues, indicator, onChangeFiltersValues, axisGroupDate, selectedAxisName, isLoading }) => {
    const locale = getLocale();

    const [dateValues, setDateValues] = useState<RangePickerProps['value']>();
    const itemFiltersDefaultValues = filtersValues.filters?.find(
      (itemFilter) => itemFilter.keyIndicator === indicator.key && itemFilter.filter_type === ItemFilterTypes.date
    );

    const canDynamicType = selectedAxisName === 'По скважинам' || selectedAxisName === 'По дате';

    useEffect(() => {
      if (itemFiltersDefaultValues?.value_from && itemFiltersDefaultValues?.value_to) {
        if (!displayType || displayType === DisplayType.static) {
          setDateValues([
            moment(itemFiltersDefaultValues.value_from, dateFormat),
            moment(itemFiltersDefaultValues.value_to, dateFormat),
          ]);
        }

        if (!canDynamicType) {
          setDateValues([
            moment(itemFiltersDefaultValues.value_from, dateFormat),
            moment(itemFiltersDefaultValues.value_to, dateFormat),
          ]);
        }
      } else {
        // reset filters
        setDateValues(undefined);
      }
    }, [itemFiltersDefaultValues?.value_from, itemFiltersDefaultValues?.value_to]);

    useEffect(() => {
      if (selectedAxisName === 'По скважинам' && displayType === DisplayType.dynamic) {
        const currentDate = moment(new Date(), dateFormat).subtract({ day: 1 });
        const filter = indicator.filters[0];

        setDateValues([currentDate, currentDate]);
        onChangeFiltersValues(filter, [currentDate.format(dateFormat), currentDate.format(dateFormat)], indicator);
      }
    }, [selectedAxisName, displayType]);

    useEffect(() => {
      if (selectedAxisName === 'По дате' && displayType === DisplayType.dynamic) {
        setDateValues([
          moment(itemFiltersDefaultValues?.value_from, dateFormat),
          moment(itemFiltersDefaultValues?.value_to, dateFormat),
        ]);
      }
    }, [selectedAxisName, displayType, itemFiltersDefaultValues?.value_from, itemFiltersDefaultValues?.value_to]);

    return (
      <Indicator>
        {indicator.filters.map((filter: Filters) => {
          const availableVariants = filter.variants?.filter(({ is_available }) => is_available);
          const filterValue = filtersValues.filters?.find(
            (itemFilter) => indicator.key === itemFilter.keyIndicator && itemFilter.key === filter.key
          );
          const isMultiple = filter.rules.includes(RulesFilterTypes.multiple);

          return (
            <div key={filter.key}>
              <h5>{filter.display_name}</h5>

              {filter.item_type === ItemFilterTypes.date && (
                <>
                  {filter.rules.includes(RulesFilterTypes.interval) && (
                    <RangePicker
                      value={dateValues}
                      format={dateFormat}
                      locale={locale === 'ru' ? localeRu : localeEn}
                      onChange={(dateStrings) => {
                        onChangeFiltersValues(filter, dateStrings, indicator);
                      }}
                      minPeriod={axisGroupDate}
                      disabled={canDynamicType && displayType === DisplayType.dynamic}
                    />
                  )}
                </>
              )}

              {filter.item_type === ItemFilterTypes.number && (
                <IndicatorFilterStateWrapper isLoading={isLoading}>
                  {(loadingState) => (
                    <SelectPicker
                      mode={isMultiple ? 'multiple' : undefined}
                      onChange={(value) => onChangeFiltersValues(filter, value, indicator)}
                      value={isMultiple ? filterValue?.values_list : filterValue?.value}
                      {...loadingState}
                    >
                      {availableVariants?.map((variant: VariantsFilter) => (
                        <Select.Option key={variant.key} value={variant.key} disabled={!variant?.is_available}>
                          {variant.display_name}
                        </Select.Option>
                      ))}
                    </SelectPicker>
                  )}
                </IndicatorFilterStateWrapper>
              )}

              {filter.item_type === ItemFilterTypes.string && (
                <IndicatorFilterStateWrapper isLoading={isLoading}>
                  {(loadingState) => (
                    <SelectPicker
                      mode={isMultiple ? 'multiple' : undefined}
                      onChange={(value) => onChangeFiltersValues(filter, value, indicator)}
                      value={isMultiple ? filterValue?.values_list : filterValue?.value}
                      {...loadingState}
                    >
                      {availableVariants?.map((variant: VariantsFilter) => (
                        <Select.Option key={variant.key} value={variant.key} disabled={!variant?.is_available}>
                          {variant.display_name}
                        </Select.Option>
                      ))}
                    </SelectPicker>
                  )}
                </IndicatorFilterStateWrapper>
              )}

              {filter.item_type === ItemFilterTypes.object && (
                <IndicatorFilterStateWrapper isLoading={isLoading}>
                  {(loadingState) => (
                    <SelectPicker
                      mode={isMultiple ? 'multiple' : undefined}
                      onChange={(value) => onChangeFiltersValues(filter, value, indicator)}
                      value={filterValue?.values_list}
                      {...loadingState}
                    >
                      {availableVariants?.map((variant: VariantsFilter) => (
                        <Select.Option key={variant.key} value={variant.key} disabled={!variant?.is_available}>
                          {variant.display_name}
                        </Select.Option>
                      ))}
                    </SelectPicker>
                  )}
                </IndicatorFilterStateWrapper>
              )}
            </div>
          );
        })}
      </Indicator>
    );
  }
);
