import { PickerLocale } from 'antd/es/date-picker/generatePicker';
import { Moment } from 'moment';

export enum RangePeriod {
  day = 'День',
  week = 'Неделя',
  month = 'Месяц',
  quarter = 'Квартал',
  year = 'Год',
  custom = 'Свой',
}

export type RangePickerProps = {
  onChange: (dateStrings: [string, string]) => void;
  value?: [Moment, Moment];
  format?: string | string[];
  locale?: PickerLocale;
  minPeriod?: string | null;
  disabled?: boolean;
};
