import { UserActions, UserActionTypes, UserState } from './types';

const InitialState: UserState = {
  isAuthorized: false,
  isSuperuser: false,
  canOpenReports: false,
};

const userReducer = (state = InitialState, action: UserActions): UserState => {
  switch (action.type) {
    case UserActionTypes.setUserAuth: {
      return {
        ...state,
        isAuthorized: action.payload,
      };
    }
    case UserActionTypes.setUserRole: {
      return {
        ...state,
        isSuperuser: action.payload,
      };
    }
    case UserActionTypes.setUserCanOpenReports: {
      return {
        ...state,
        canOpenReports: action.payload,
      };
    }
    default:
      return state;
  }
};

export default userReducer;
