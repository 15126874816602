import { FC, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { GOHUB_REDIRECT_URL } from 'src/constants';
import { checkUserAuth } from 'src/services/AuthService';
import { getTemplates } from 'src/services/PageService';
import { setTemplatesAction } from 'src/store/pages';
import {
  setUserRoleAction,
  setUserIsAuthorizedAction,
  getIsAuthorizedUser,
  setUserCanOpenReportsAction,
} from 'src/store/user';
import { hasToken } from 'src/utils';

const PrivateWrapper: FC = ({ children }) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(getIsAuthorizedUser);

  useEffect(() => {
    const checkAuth = async () => {
      if (hasToken()) {
        try {
          const { data: user } = await checkUserAuth();
          dispatch(setUserIsAuthorizedAction(true));
          dispatch(setUserRoleAction(user.is_superuser));
          dispatch(setUserCanOpenReportsAction(user.can_open_reports));
          const { data: templates } = await getTemplates();
          dispatch(setTemplatesAction(templates));
        } catch {
          window.location.href = GOHUB_REDIRECT_URL;
        }
      } else {
        window.location.href = GOHUB_REDIRECT_URL;
      }
    };

    checkAuth();
  }, [dispatch]);

  if (!isAuthorized) {
    return null;
  }

  return <>{children}</>;
};

export default PrivateWrapper;
