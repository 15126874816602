import { Modal } from 'antd';
import styled from 'styled-components';

export const ModalWrapper = styled(Modal)`
  .ant-modal-header,
  .ant-modal-content {
    background: ${({ theme }) => theme.modal.background};
    color: ${({ theme }) => theme.modal.text};
    transition: all 0.25s linear;

    .ant-btn-primary {
      color: ${({ theme }) => theme.modal.primaryText};
    }
    .ant-modal-close {
      color: ${({ theme }) => theme.modal.text};
    }
  }
  .ant-modal-title {
    color: ${({ theme }) => theme.modal.text};
  }
  .ant-modal-header,
  .ant-modal-footer {
    border-color: ${({ theme }) => theme.modal.inputBorder};
  }
`;
