import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { createGlobalStyle } from 'styled-components';

import { staticColors } from 'src/styles/theme';

import 'antd/dist/antd.css';

import 'react-reflex/styles.css';

import './fonts.css';

const GlobalStyles = createGlobalStyle`
  body {
    font-family: Roboto;
  }

  .ant-checkbox {
    top: 0px !important;

    &-inner {
      width: 24px !important;
      height: 24px !important;

      &::after {
        left: 35% !important;
      }
    }
  }

  .ant-message {
    &-notice {
      text-align: right;
    }

    &-notice-content {
      color: ${staticColors.white};
    }

    .anticon {
      color: ${staticColors.white};
    }
  }

  .errorMessage {
    .ant-message-notice-content {
      background: ${staticColors.red};
    }
  }

  .successMessage {
    .ant-message-notice-content {
      background: green;
    }
  }

  .ant-drawer-body {
    padding: 20px;
  }

  .reflex-container {
    width: calc(100% + 2px);
    overflow: hidden;
    margin-left: -1px;
  }

  .highcharts-credits {
    display: none;
  }

  .ant-layout-content {
    z-index: 2;
  }

  @media (max-width: 580px) {
    .ant-picker-panel-container .ant-picker-panels {
      flex-direction: column;
    }
    .ant-picker-dropdown-placement-bottomLeft .ant-picker-range-arrow {
      display: none;
    }
  }
`;

export default GlobalStyles;
