import { EllipsisOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Switch } from 'antd';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const MenuContainer = styled.div`
  position: absolute;
  z-index: 2;
  top: 6px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 768px) {
    top: -4px;
  }
`;

export const MenuIcon = styled(EllipsisOutlined)`
  font-size: 24px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text} !important;
  position: relative;

  @media (max-width: 768px) {
    width: 50px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

export const MenuItem = styled.li<{ isDanger?: boolean }>`
  list-style-type: none;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  clear: both;
  margin: 0;
  padding: 0;
  font-weight: normal;
  font-size: 14px;
  line-height: 22px;
  white-space: nowrap;
  cursor: pointer;
  transition: all 0.3s;

  button {
    border: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    padding: 5px 12px;
    width: 100%;
    color: ${({ isDanger }) => (isDanger ? '#ff4d4f' : 'rgba(0, 0, 0, 0.85)')};
    text-align: left;

    &:hover,
    &:focus {
      background-color: #f5f5f5;
    }

    &:disabled {
      color: rgba(0, 0, 0, 0.25) !important;
      background-color: transparent !important;
      cursor: not-allowed;
    }
  }
`;

export const QuestionIcon = styled(QuestionCircleOutlined)`
  font-size: 20px;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.text} !important;
  position: relative;
  margin-right: 8px;
`;

export const TooltipText = styled.p`
  margin: 2px 0;
  font-size: 12px;

  span {
    font-weight: 900;
  }
`;

export const LeftMenu = styled.div`
  display: flex;
  align-items: center;
`;

export const Switcher = styled(Switch)`
  height: 20px;
  min-width: 40px;
  background-color: ${({ theme }) => theme.switch.background};

  & .ant-switch-handle {
    height: 16px;
    width: 16px;
  }

  &.ant-switch-checked {
    background-color: ${({ theme }) => theme.switch.backgroundActive};
    & .ant-switch-handle {
      left: calc(100% - 16px - 2px);
    }
  }
`;
