import { FC, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { GOHUB_REDIRECT_URL, PATHS } from 'src/constants';
import { checkUserAuth } from 'src/services/AuthService';
import { getTemplates } from 'src/services/PageService';
import { setTemplatesAction } from 'src/store/pages';
import { getIsAuthorizedUser, setUserIsAuthorizedAction, setUserRoleAction } from 'src/store/user';
import { hasToken, setToken } from 'src/utils';

const ExternalLogin: FC = () => {
  const { search } = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const isAuthorized = useSelector(getIsAuthorizedUser);

  const token = useMemo(() => {
    const urlParams = new URLSearchParams(search);
    return urlParams.get('token');
  }, [search]);

  useEffect(() => {
    if (token) {
      setToken(token);
    }
  }, [token]);

  useEffect(() => {
    const checkAuth = async () => {
      if (hasToken()) {
        try {
          const { data: user } = await checkUserAuth();
          dispatch(setUserIsAuthorizedAction(true));
          dispatch(setUserRoleAction(user.is_superuser));
          const { data: templates } = await getTemplates();
          dispatch(setTemplatesAction(templates));
        } catch {
          window.location.href = GOHUB_REDIRECT_URL;
        }
      } else {
        window.location.href = GOHUB_REDIRECT_URL;
      }
    };

    checkAuth();
  }, [dispatch]);

  if (isAuthorized) {
    history.push(`${PATHS.pages}/1`);
  }

  return null;
};

export default ExternalLogin;
