import { TabsProps } from 'antd';
import { Children, FC, ReactElement, useEffect, useState, VFC } from 'react';
import { DndProvider, DragSource, DragSourceSpec, DropTarget, DropTargetSpec } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { updatePage } from 'src/services/PageService';
import { PageTypes } from 'src/services/PageService/PageService.types';
import { ViewMode } from 'src/store/pages';

import { PagesTabs } from './DraggableTabs.styled';
import { DraggableTabsProps, NodeElementProps, OrderTab, TabNodeProps } from './DraggableTabs.types';

const TabNode: FC<TabNodeProps> = (props) => {
  const { connectDragSource, connectDropTarget, children } = props;

  return connectDragSource(connectDropTarget(children));
};

const cardTarget: DropTargetSpec<NodeElementProps> = {
  drop(props, monitor) {
    const dragKey = monitor.getItem().index;
    const hoverKey = `${props.index}`;

    if (dragKey === hoverKey) {
      return;
    }

    props.moveTabNode(dragKey, hoverKey);
    monitor.getItem().index = hoverKey;
  },
};

const cardSource: DragSourceSpec<NodeElementProps> = {
  beginDrag(props) {
    return {
      id: props.id,
      index: props.index,
    };
  },
};

const WrapTabNode = DropTarget('DND_NODE', cardTarget, (connect) => ({
  connectDropTarget: connect.dropTarget(),
}))(
  DragSource('DND_NODE', cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging(),
  }))(TabNode)
);

const DraggableTabs: VFC<DraggableTabsProps> = (props) => {
  const { defaultOrder, viewMode, pageType, ...restProps } = props;
  const { children } = restProps;

  const [order, setOrder] = useState<OrderTab[]>(defaultOrder);

  useEffect(() => {
    const localTabIds = order.map(({ id }) => id);
    const addedTabs = defaultOrder.filter(({ id }) => !localTabIds.includes(id));

    if (addedTabs.length) {
      setOrder((tabs) => [...tabs, ...addedTabs.map((tab) => ({ ...tab }))]);
    }
  }, [defaultOrder]);

  const getOrderedTabs = (tabs: ReactElement[], orderTabs: OrderTab[]) => {
    return [...tabs].sort((a, b) => {
      const orderA = orderTabs.find((item) => `${item.id}` === a.key)?.order;
      const orderB = orderTabs.find((item) => `${item.id}` === b.key)?.order;

      if (orderA && orderB) {
        return orderA - orderB;
      }

      return 0;
    });
  };

  const moveTabNode = (dragKey: string, hoverKey: string) => {
    const sortedOrders = [...order].sort((a, b) => a.order - b.order);

    const dragOrder = sortedOrders.find((item) => `${item.id}` === dragKey)?.order;
    const hoverOrder = sortedOrders.find((item) => `${item.id}` === hoverKey)?.order;

    if (dragOrder && hoverOrder) {
      updatePage(Number(dragKey), { order: hoverOrder });

      const dragOrderObj = sortedOrders.find((item) => `${item.id}` === dragKey);
      const hoverOrderObj = sortedOrders.find((item) => `${item.id}` === hoverKey);
      if (dragOrderObj && hoverOrderObj) {
        const dragIndex = sortedOrders.indexOf(dragOrderObj);
        const hoverIndex = sortedOrders.indexOf(hoverOrderObj);

        const newOrders = JSON.parse(JSON.stringify(sortedOrders));
        if (dragIndex < hoverIndex) {
          for (let i = dragIndex + 1; i <= hoverIndex; i++) {
            newOrders[i].order = sortedOrders[i - 1].order;
          }
        } else {
          for (let i = hoverIndex; i < dragIndex; i++) {
            newOrders[i].order = sortedOrders[i + 1].order;
          }
        }

        newOrders[dragIndex].order = hoverOrder;
        setOrder(newOrders);
      }
    }
  };

  const renderTabBar: TabsProps['renderTabBar'] = (props, DefaultTabBar) => (
    <DefaultTabBar {...props}>
      {(node: ReactElement) => (
        <WrapTabNode key={node.key} index={node.key} moveTabNode={moveTabNode}>
          {node}
        </WrapTabNode>
      )}
    </DefaultTabBar>
  );

  const tabs: ReactElement[] = [];
  Children.forEach(children, (c) => {
    tabs.push(c);
  });

  const orderedTabs = getOrderedTabs(tabs, order);

  return (
    <DndProvider backend={HTML5Backend}>
      <PagesTabs
        showTabsNav={!(pageType === PageTypes.dashboard && viewMode === ViewMode.fullscreen)}
        renderTabBar={renderTabBar}
        {...restProps}
      >
        {orderedTabs}
      </PagesTabs>
    </DndProvider>
  );
};

export default DraggableTabs;
