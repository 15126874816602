import { getArrayKey } from 'src/utils';

import { AnyFrontendValue, Frontend, FrontendValue } from './PageService.types';

export const addOrUpdateFrontendValue = <T>(
  newValue: FrontendValue<T>,
  currentValue?: FrontendValue<T>[]
): FrontendValue<T>[] => {
  if (currentValue) {
    const newFrontendValue = [...currentValue];
    const changedFrontendValue = currentValue.find((value) => value.key === newValue.key);

    if (changedFrontendValue) {
      changedFrontendValue.value = newValue.value;
    } else {
      newFrontendValue.push(newValue);
    }
    return newFrontendValue;
  } else {
    return [newValue];
  }
};

export const addOrUpdateFrontend = (
  newFrontendValue: AnyFrontendValue,
  frontend: Frontend | undefined,
  keyFrontendValue: keyof Frontend
): Frontend => {
  let newFrontend: Frontend;

  if (frontend) {
    if (frontend[keyFrontendValue]) {
      newFrontend = { ...frontend };

      const frontendValue: AnyFrontendValue[] = newFrontend[keyFrontendValue] || [];
      const currentFrontendValue = frontendValue?.find((value) => value.key === newFrontendValue.key);

      if (currentFrontendValue) {
        currentFrontendValue.value = newFrontendValue.value;
      } else {
        frontendValue?.push(newFrontendValue);
      }
    } else {
      newFrontend = {
        ...frontend,
        [keyFrontendValue]: [newFrontendValue],
      };
    }
  } else {
    newFrontend = {
      [keyFrontendValue]: [newFrontendValue],
    };
  }
  return newFrontend;
};

export const deleteDisplayType = (frontend: Frontend, keyDisplayType: string): Frontend => {
  const newGraphDisplayTypes = frontend.graph_display_types?.filter((graph_display_type) => {
    return graph_display_type.key !== keyDisplayType;
  });

  frontend.graph_display_types = newGraphDisplayTypes;

  return frontend;
};

export const toggleSeriesVisible = (
  hiddenSeries: FrontendValue<string[]>[],
  chartKey: string,
  seriesKey: string,
  visible?: boolean
): FrontendValue<string[]>[] => {
  const hiddenSeriesItem = hiddenSeries?.find(({ key }) => key === chartKey);

  if (!visible && !hiddenSeriesItem?.value?.includes(seriesKey)) {
    if (!hiddenSeriesItem) {
      return [
        ...hiddenSeries,
        {
          key: chartKey,
          value: [seriesKey],
          hash: getArrayKey([seriesKey]),
        },
      ];
    }

    hiddenSeriesItem.value = [...hiddenSeriesItem.value, seriesKey];
    hiddenSeriesItem.hash = getArrayKey(hiddenSeriesItem.value);

    return hiddenSeries;
  }

  if ((visible || visible === undefined) && hiddenSeriesItem?.value?.includes(seriesKey)) {
    const filteredSeries = hiddenSeriesItem.value.filter((series) => series !== seriesKey);

    hiddenSeriesItem.value = filteredSeries;
    hiddenSeriesItem.hash = getArrayKey(filteredSeries);
  }

  return hiddenSeries;
};
