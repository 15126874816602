export enum SortingBy {
  without = 'without',
  log_vs_advance = 'log_vs_advance',
  npt_hours = 'npt_hours',
}

export type WellData = {
  construction_type: string;
  customer: string;
  date: string;
  depth: number;
  end_date_prediction: string;
  field: string;
  fluid_type: string;
  log_vs_advance: number;
  name: string;
  npt_hours: number;
  number: string;
  pad: string;
  purpose: number;
  spud_time: string;
  stage: number;
  svg_construction: string;
  svg_npt_persent: string;
  well_id: string;
  well_stage_status: number;
  is_gti_absent: boolean;
};

export type WellDetailsValue = {
  description: string;
  value: string;
};

export type WellDetails = Partial<{
  commertial_speed_fact: WellDetailsValue;
  commertial_speed_plan: WellDetailsValue;
  construction_duration_fact: WellDetailsValue;
  construction_duration_plan: WellDetailsValue;
  curator: WellDetailsValue;
  current_section: WellDetailsValue;
  days_to_thousand_fact: WellDetailsValue;
  days_to_thousand_plan: WellDetailsValue;
  drilling_crew: WellDetailsValue;
  drilling_rig_type: WellDetailsValue;
  end_date: WellDetailsValue;
  end_date_prediction: WellDetailsValue;
  exec_summary: WellDetailsValue;
  is_svp_exists: WellDetailsValue;
  lag_vs_advance: WellDetailsValue;
  last_depth_fact: WellDetailsValue;
  last_depth_plan: WellDetailsValue;
  meterage_fact: WellDetailsValue;
  meterage_plan: WellDetailsValue;
  rig_activity_6am: WellDetailsValue;
  supervisor: WellDetailsValue;
  well_block: WellDetailsValue;
  well_condition: WellDetailsValue;
  well_construction: WellDetailsValue;
  well_field: WellDetailsValue;
  well_fluid: WellDetailsValue;
  well_number: WellDetailsValue;
  well_operator_div: WellDetailsValue;
  well_pad: WellDetailsValue;
  well_purpose: WellDetailsValue;
  well_sequence_count: WellDetailsValue;
  well_sequence_number: WellDetailsValue;
  well_spud_time: WellDetailsValue;
  drilling_company: WellDetailsValue;
}>;

export type ReportType = {
  id: string;
  name: string;
};

export enum WellPurpose {
  PROSPECTING = 1,
  EXPLORATION = 2,
  INJECTION = 3,
  OBSERVATION = 4,
  REFERENCE = 5,
  PRODUCTION = 6,
  WATERSUPPLY = 7,
}

export enum Fluid {
  UNKNOWN,
  AIR,
  CONDENSATE,
  DRY,
  GAS,
  GAS_WATER,
  NON_HC_GAS,
  NON_HC_GAS_CO_2,
  OIL,
  OIL_GAS,
  OIL_WATER,
  STREAM,
  WATER,
  WATER_BRINE,
  WATER_FRESH_WATER,
}

export enum WellActivity {
  ASSEMBLING = 5,
  DISMANTLING = 6,
  MOVING = 7,
  DRILLING = 1,
  INITIATION = 8,
  RIG_MAINTENANCE = 3,
  IN_CONSERVATION = 4,
  TESTING = 9,
  DONE = 2,
}

export type ReportFields = {
  from?: string;
  to?: string;
  operatorDivs?: string[];
  blocks?: string[];
  fields?: string[];
  pads?: string[];
  wellIds?: number[];
  purposes?: string[];
  wellTypes?: string[];
  drillingRigs?: string[];
  fluids?: string[];
  wellActivities?: string[];
};

export type ReportTypesResponse = ReportType[];

export type ReportResponse = {
  uid: string;
};

export enum ReportStatus {
  data_loading = 'data_loading',
  xlsx_generation = 'xlsx_generation',
  completed = 'completed',
}

export type ReportStatusResponse = {
  uid: string;
  status: ReportStatus;
  expired_at: string;
  file: string;
};
